import { AxiosInstance } from "axios";
import { useEffect } from "react";
import metaConfig from "../../../meta.json";

export function useRequestInterceptor(api: AxiosInstance, liveUpdateVersion: string | undefined | null) {
    useEffect(() => {
        const interceptorId = api.interceptors.request.use((request) => {
            request.headers = request.headers || {};
            request.headers["X-Bobra-Client"] = `mobile-app/${liveUpdateVersion ? "live": "googlestore"}/${metaConfig.channel}/${metaConfig.version}`;

            return request;
        });
        return () => api.interceptors.request.eject(interceptorId);
    }, [api, liveUpdateVersion]);
}
