import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as React from "react";
import { useHistory } from "react-router-dom";
import Select, { SingleValue } from "react-select";

type Operator = "airtel" | "mtn";
type Option = {
    label: string;
    value: Operator;
};

export const SelectOperatorMessage = () => {
    const dispatch = Chat.useDispatchContext();
    const { api } = Client.useStateContext();
    const history = useHistory();

    const [operator, setOperator] = React.useState<SingleValue<Option> | undefined>(undefined);

    const options: Array<Option> = [
        {
            label: "Airtel",
            value: "airtel",
        },
        {
            label: "MTN",
            value: "mtn",
        },
    ];

    const handleChange = React.useCallback(
        (value: SingleValue<Option>) => {
            setOperator(value);
        },
        [setOperator],
    );

    React.useEffect(() => {
        if (operator) {
            api.user.phoneId
                .provider(operator.value)
                .then((sessionId) => {
                    dispatch(
                        new Chat.ReplaceAction([
                            new Chat.TextMessage(operator.value, "user"),
                            new Chat.TextMessage("Enter the OTP code to verify your phone."),
                            new Chat.PhoneId.ConfirmFormMessage(sessionId),
                        ]),
                    );
                })
                .catch(() => {
                    history.push("/failure");
                });
        }
    }, [operator, dispatch]);

    return (
        <Select
            value={operator}
            options={options}
            onChange={handleChange}
            placeholder="Select..."
            className="form__control-select operator"
            classNamePrefix="select"
            isSearchable={false}
        />
    );
};
