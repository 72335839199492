import { AxiosInstance } from "axios";
import { DecisionType } from "modules/chat/decision";

export enum DecisionResponseType {
    ALREADY_ACCEPTED = 208,
    ACCEPTED = 201,
}

export type DecisionResponse = {
    status: DecisionResponseType;
    message: string;
};

export const Api = (endpoint: AxiosInstance) => {
    return {
        post: async (decision: DecisionType): Promise<DecisionResponse> => {
            const response = await endpoint.post<{
                message: string;
            }>("/v3/agreement", {
                Agreement: {
                    decision,
                },
            }, {
                validateStatus: status => Object.values(DecisionResponseType).includes(status),
            });

            return {
                status: response.status as DecisionResponseType,
                message: response.data.message,
            };
        },
    } as const;
};
