import classNames from "classnames";
import { Markdown } from "modules/components/markdown";
import * as React from "react";
import { ButtonMessage } from "./button-message";

export interface Item<O> {
    type: "primary" | "secondary";
    option: O;
    content: string;
}

export interface ButtonProps<O = any> extends Item<O> {
    onClick: (option: O, content: string) => void;
}

const getClassNameByType = (type: ButtonProps["type"]): string => {
    switch (type) {
        case "primary":
            return "btn_blue";
        case "secondary":
            return "btn_white";
    }
};

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = (
    { content, type, onClick, option },
) => {
    const handleClick = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        onClick(option, content);
    }, [onClick, option, content]);
    return (
        <button
            type="button"
            className={classNames("btn", getClassNameByType(type))}
            onClick={handleClick}>
            <Markdown content={content} />
        </button>
    );
};
Button.displayName = "Chat.Menu.Button";

export interface MessageProps<O = any> {
    items: Array<Item<O>>;
    onSelect: (option: O, content: string) => void;
}

export const Message: React.FC<React.PropsWithChildren<MessageProps>> = ({ items, onSelect }) => {
    const handleClick = React.useCallback((option: Pick<Item<any>, "option">, select: string) => onSelect(option, select), [onSelect]);
    return (
        <ButtonMessage
            source="user"
            className="menu-message">
            {items.map((item, i) => (
                <Button
                    key={i}
                    onClick={handleClick}
                    {...item} />
            ))}
        </ButtonMessage>
    );
};
