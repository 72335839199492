import * as React from "react";
import { Route as BaseRoute } from "react-router-dom";
import { Page } from "./page";

export const Route: () => React.ReactElement = () => {
    return (<BaseRoute
        key="restructuring"
        path="/dashboard/restructuring"
        exact
        component={Page} />);
};
