import * as React from "react";
import { Markdown } from "../../../../modules/components/markdown";
import { Message } from "../message";

export interface LabelMessageProps {
    id: string;
    message: string;
}

export const LabelMessage: React.FC<React.PropsWithChildren<LabelMessageProps>> = ({ id, message }) => {
    return (
        <Message source="bot">
            <label
                className="wrap"
                htmlFor={id}>
                <div className="animated">
                    <Markdown content={message} />
                </div>
            </label>
        </Message>
    );
};
