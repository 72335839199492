import dayjs from "dayjs";
import * as Client from "modules/client";
import * as React from "react";
import { useCallback } from "react";
import * as Chat from "../../../modules/chat";
import * as Base from "../base";
import * as State from "../state";

const getMessagesByMandate = (mandate: Client.Api.DirectDebit.MandateEntity): Array<Chat.Message> => {
    switch (mandate.status) {
        case "successful":
            const messages: Array<Chat.Message> = [
                new Chat.DirectDebit.MandatePendingMessage(
                    mandate.account.number,
                    mandate.account.bank,
                    mandate.createdAt,
                ),
            ];
            const createdAt = dayjs(mandate.createdAt);
            const diffInHours = dayjs().diff(createdAt, "hours");
            if (diffInHours > 24) {
                messages.push(
                    new Chat.DirectDebit.MandatePendingTooLongMessage(),
                    new Chat.DirectDebit.ConfirmMessage(),
                );
            }
            return messages;
        case "approved":
            return [new Chat.DirectDebit.MandateSuccessMessage(
                mandate.account.number,
                mandate.account.bank,
                mandate.createdAt,
            )];
        case "rejected":
            return [
                new Chat.DirectDebit.MandateFailedMessage(
                    mandate.account.number,
                    mandate.account.bank,
                    mandate.createdAt,
                ),
                new Chat.DirectDebit.ConfirmMessage,
            ];
    }
};

export const InitMessage: State.Message<State.MessageProps<Chat.DirectDebit.InitMessage>> =
    (props) => {
        const handleRequest = useCallback(
            (api: Client.Api.Instance,
            ): Promise<Array<Client.Api.DirectDebit.MandateEntity>> => {
                return api.user.directDebit.getMandates();
            }, []);
        const handleFinish = useCallback(
            async (mandates: Array<Client.Api.DirectDebit.MandateEntity>,
            ): Promise<Chat.Action> => {
                if (mandates.length === 0) {
                    return new Chat.FlushAction([
                        new Chat.DirectDebit.WelcomeMessage,
                        new Chat.DirectDebit.ConfirmMessage,
                    ]);
                }
                const lastMandate = mandates[0];
                return new Chat.FlushAction(getMessagesByMandate(lastMandate));
            }, []);

        return (<Base.DelaySubmitMessage
            delayMs={5000}
            value={props.value}
            onRequest={handleRequest}
            onFinish={handleFinish}
        />);
    };
