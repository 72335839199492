import * as Base from "app/chat/base";
import * as State from "app/chat/state";
import * as Chat from "modules/chat";
import * as React from "react";

type ShareContactsNotEnoughProps = State.MessageProps<Chat.ShareContacts.ShareContactsNotEnough>;

export const ShareContactsNotEnough: React.FC<React.PropsWithChildren<ShareContactsNotEnoughProps>> = (value) => {
    const dispatch: Chat.Dispatch = Chat.useDispatchContext();

    const handleRepeat = React.useCallback((): void => {
        dispatch([new Chat.FlushAction([new Chat.ShareContacts.ShareContactsInitMessage()])]);
    }, [dispatch]);

    return (
        <>
            <Base.WrapMessage source="bot">
                <p>You have very few contacts in the phone book, add them and try again</p>
            </Base.WrapMessage>
            <Base.ButtonMessage
                source={"user"}
                className={"confirm-bank"}>
                <Base.Button
                    theme="primary"
                    onClick={handleRepeat}>
                    Repeat
                </Base.Button>
            </Base.ButtonMessage>
        </>
    );
};
