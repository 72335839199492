import * as React from "react";
import * as Chat from "../../../modules/chat";
import * as Base from "../base";
import * as State from "../state";

export const ConfirmNubanMessage: State.Message<State.MessageProps<Chat.BankAccount.ConfirmNubanMessage>> =
    (props) => {
        const dispatch = Chat.useDispatchContext();
        const handleClick = React.useCallback(() => {
            dispatch(new Chat.ReplaceAction([
                new Chat.TextMessage("Confirm", "user"),
                new Chat.BankAccount.ConfirmNubanLoadMessage(props.value.accountNumber, props.value.bankCode),
            ], props.value));
        }, [dispatch, props.value]);

        return (
            <Base.ButtonMessage source={"user"}>
                <Base.Button
                    theme="primary"
                    onClick={handleClick}>
                    Confirm
                </Base.Button>
            </Base.ButtonMessage>
        );
    };
