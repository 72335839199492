import * as State from "app/chat/state";
import axios from "axios";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as React from "react";
import { useHistory } from "react-router-dom";
import * as Base from "../base";

export const LoadMessage: State.Message<State.MessageProps<Chat.BankId.LoadMessage>> = ({ value: { token } }) => {
    const history = useHistory();
    const { api } = Client.useStateContext();

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        api.with({ cancelToken: cancelToken.token })
            .user.mono.auth(token)
            .then(() => {
                history.push("/registration/mono/details");
            })
            .catch((error: Error) => {
                if (!axios.isCancel(error)) {
                    history.push("/failure");
                    return Promise.reject(error);
                }
            });
        return () => cancelToken.cancel();
    }, []);

    return (
        <Base.WrapMessage source="bot">
            <p>You identified. Token: {token}</p>
        </Base.WrapMessage>
    );
};
