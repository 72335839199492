import { InputMessage } from "app/chat/base/form";
import * as Chat from "modules/chat";
import * as React from "react";
import * as State from "../state";

export const ConfirmFormMessage: State.Message<
    State.MessageProps<Chat.PhoneId.ConfirmFormMessage>
> = ({ value }) => {
    const dispatch = Chat.useDispatchContext();
    const [otpToken, setOtpToken] = React.useState("");
    const isSubmitEnable = otpToken.match(/^\d{4,6}$/);
    const handleSubmit = React.useCallback(() => {
        if (!isSubmitEnable) {
            return false;
        }

        dispatch(
            new Chat.ReplaceAction([
                new Chat.PhoneId.ConfirmLoadMessage(
                    value.sessionId,
                    otpToken.replace(/-/g, ""),
                    value.attempt,
                ),
            ]),
        );
    }, [isSubmitEnable, dispatch, value.attempt, value.sessionId]);
    const handleChange = React.useCallback((newValue: string) => {
        const match = newValue.match(/\d{1,6}/);
        setOtpToken(match ? match[0] : "");
    }, [setOtpToken]);

    return (
        <InputMessage
            disabled={!isSubmitEnable}
            onSubmit={handleSubmit}
            onChange={handleChange}
            value={otpToken}
        />
    );
};
