import { AxiosInstance } from "axios";

// TODO вынести в capacitorApi аналогично guestApi и UserApi
export const Api = (endpoint: AxiosInstance) => ({
    postFcmDevice: async (token: string, type: string) => {
        const response = await endpoint.post<any>("/v3/fcm/device", {
            Device: {
                token,
                type,
            },
        });

        return response.data;
    },
});
