import * as Chat from "modules/chat";
import * as State from "../state";
import { CalculatorOnlyMessage } from "./calculator-only-message";
import { EditMenuMessage } from "./edit-menu-message";
import { EditLoadingMessage } from "./edit-message";
import { FormMessage } from "./form-message";
import { FormSubmitMessage } from "./form-submit-message";
import { HeaderMessage } from "./header-message";
import { LoadingMessage } from "./loading-message";

export function MessageComponentFactory(type: Chat.Calculator.Message["type"] | string): State.Message | undefined {
    switch (type) {
        case "calculator:header":
            return HeaderMessage;
        case "calculator:loading":
            return LoadingMessage;
        case "calculator:edit-loading":
            return EditLoadingMessage;
        case "calculator:form":
            return FormMessage;
        case "calculator:only":
            return CalculatorOnlyMessage;
        case "calculator:edit-menu":
            return EditMenuMessage;
        case "calculator:form-submit":
            return FormSubmitMessage;
    }
}
