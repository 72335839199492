import { useStateContext } from "modules/client/state-context";
import * as React from "react";

export type ConditionsItem = {
    min: number;
    max: number;
    value?: number;
    limit?: number;
    step?: number;
};

export type Conditions<I extends ConditionsItem = ConditionsItem> = {
    amount: I;
    term: I;
    interestRate: number;
    isBerserk?: boolean;
    isAllowedNextCredit?: boolean;
    nextRequestMinDate?: string;
};

export const useLoadConditions = () => {
    const [conditions, setConditions] = React.useState<Conditions | null>(null);
    const client = useStateContext();

    React.useEffect(() => {
        if (client.type === "user" || client.type === "guest") {
            client.api.guest.getConditions().then(setConditions);
        }
    }, [setConditions, client.type, (client.type === "user") ? client.request.status : undefined]);

    return conditions;
};

const ConditionsContext = React.createContext<Conditions | null>(null);

type ConditionsProviderProps = {};

export const ConditionsProvider: React.FC<React.PropsWithChildren<ConditionsProviderProps>> = ({ children }) => {
    const conditions = useLoadConditions();

    return <ConditionsContext.Provider value={conditions}>{children}</ConditionsContext.Provider>;
};

export const useConditions = () => {
    return React.useContext(ConditionsContext);
};
