import * as Resources from "app/resources";
import * as Base from "../base";

export const AgreementMessage = () => {
    return (
        <Base.WrapMessage source={"bot"}>
            <p>
                Please read{" "}
                <a
                    href={Resources.List.privacyPolicy}
                    target="_blank"
                    rel="noopener noreferrer">
                    the privacy policy
                </a>{" "}
                before using this service.
            </p>
            <p>Enter the SMS code to confirm your acceptance of the privacy policy.</p>
        </Base.WrapMessage>
    );
};
