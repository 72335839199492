import * as React from "react";
import * as AccountDeletion from "./account-deletion";
import * as Failure from "./failure";
import * as Logout from "./logout";
import * as NotFound from "./not-found";
import * as UpdateLoading from "./update-loading";
import * as UpgradeRequired from "./upgrade-required";

export const routes = (): Array<React.ReactElement> => {
    return [
        Logout.Route(),
        AccountDeletion.Route(),
        Failure.Route(),
        NotFound.Route(),
        UpgradeRequired.Route(),
        UpdateLoading.Route(),
    ];
};
