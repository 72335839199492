import * as Chat from "modules/chat";
import * as State from "../state";
import { InitMessage } from "./init-message";
import { ShareContactsDisabled } from "./share-contacts-disabled-message";
import { ShareContactsLoadMessage } from "./share-contacts-load-message";
import { ShareContactsMessage } from "./share-contacts-message";
import { ShareContactsNotEnough } from "./share-contacts-not-enough-message";

export function MessageComponentFactory(type: Chat.ShareContacts.Message["type"] | string): State.Message | undefined {
    switch (type) {
        case "contacts":
            return ShareContactsMessage;
        case "contacts:load-message":
            return ShareContactsLoadMessage;
        case "contacts:not-enough":
            return ShareContactsNotEnough;
        case "contacts:disabled":
            return ShareContactsDisabled;
        case "contacts:init":
            return InitMessage;
    }
}
