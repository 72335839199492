import * as React from "react";
import Color from "./color-logo.svg";
import White from "./white-logo.svg";

const logo = {
    white: White,
    color: Color,
} as const;

export type LogoProps = {
    type: keyof typeof logo;
};
export const Logo: React.FC<React.PropsWithChildren<LogoProps>> = (props) => {
    return (<img
        src={logo[props.type]}
        alt="LoviLave" />);
};

Logo.displayName = "Logo";
