import { useOpenState } from "@horat1us/react-hooks";
import { IconArrow } from "modules/components/Icons/IconArrow";
import Docs from "./docs.svg";

const docsInfo: Array<{ name: string; link: string; }> = [
    {
        name: "Privacy Policy",
        link: "https://static.kobogo.ng/privacy-policy.html",
    },
    {
        name: "Cookies Policy",
        link: "https://static.kobogo.ng/cookies-policy.html",
    },
    { name: "Loan Terms", link: "https://static.kobogo.ng/loan-terms.html" },
    {
        name: "Certificate of Incorporation",
        link: "https://static.kobogo.ng/Certificate_of_incorporation.pdf",
    },
    { name: "FCCPC", link: "https://static.kobogo.ng/FCCPC.pdf" },
    {
        name: "Money Lenders License",
        link: "https://static.kobogo.ng/Money_lenders_license.pdf",
    },
];

export const Layout = () => {
    const [isOpen, handleOpenChange] = useOpenState();
    return (
        <article>
            <h1>Service documentation</h1>
            {isOpen
                ? (
                    <div className="service-docs">
                        <h5>Select a document to view</h5>
                        <ul>
                            {docsInfo.map(({ name, link }) => (
                                <li key={name}>
                                    <a
                                        href={link}
                                        className="btn btn_white full-sized"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <span>{name}</span>
                                        <IconArrow />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                )
                : (
                    <>
                        <div className="jumbotron">
                            <img
                                src={Docs}
                                width="104"
                                height="103"
                                alt="Docs" />
                            <p>
                                We guarantee the security of your personal data and
                                are responsible for this. Only authorized managers
                                have access to it, the data is not disclosed to
                                third parties and cannot be intercepted by
                                fraudsters from the site. We use the SSL protocol
                                for information security.
                            </p>
                        </div>
                        <button
                            type="button"
                            className="btn btn_white full-sized"
                            onClick={handleOpenChange}
                        >
                            <span>View all documents</span>
                            <IconArrow />
                        </button>
                    </>
                )}
        </article>
    );
};

export default Layout;
