import * as Client from "modules/client";
import { numericFilter } from "modules/utils/numeric-filter";
import * as React from "react";
import { ControlProps } from "./control";

export type NumberControlProps = ControlProps<Client.Api.JSONSchemaNumber>;

export const NumberControl: React.FC<React.PropsWithChildren<NumberControlProps>> = (props) => {
    const handleChange = React.useCallback(
        ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange(numericFilter(value));
        },
        [props.onChange],
    );
    const childProps: React.HTMLProps<HTMLInputElement> = {
        type: "tel",
    };
    if ("object" === typeof navigator && navigator.userAgent.includes("Chrome")) {
        childProps.type = "text";
        childProps.inputMode = "tel";
    }
    if (props.schema.maximum) {
        childProps.maxLength = props.schema.maximum.toString(10).length;
    }
    return (
        <input
            id={props.id}
            type="text"
            inputMode="numeric"
            className="form__control"
            name={props.attribute}
            placeholder={props.schema.title}
            title={props.schema.title}
            value={props.value}
            onChange={handleChange}
            autoComplete="on"
            autoCorrect="on"
            autoFocus
            required
            {...childProps}
        />
    );
};
