import { useLang } from "app/locale/hooks/use-lang";
import { languageShortName } from "modules/utils/language-short-name";
import * as React from "react";
import { useLocale } from "../hooks";

export const LocalizedProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { currentLanguage, routeMatch, changeLanguage } = useLocale();

    useLang(currentLanguage);

    React.useEffect(() => {
        changeLanguage(languageShortName(routeMatch));
    }, [routeMatch]);

    return <>{children}</>;
};
