import { LocalizedRoute } from "app/locale";
import * as React from "react";
import { Page } from "./page";

export const Route: () => React.ReactElement = () => {
    return (<LocalizedRoute
        key="payment-succeed"
        path="/dashboard/payment-succeed"
        component={Page} />);
};
