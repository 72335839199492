import axios, { AxiosError } from "axios";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as React from "react";
import { useHistory } from "react-router-dom";
import * as Base from "../base";

export const InitMessage: React.FC<React.PropsWithChildren> = () => {
    const { api } = Client.useStateContext();
    const dispatch = Chat.useDispatchContext();
    const history = useHistory();

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        api.with({ cancelToken: cancelToken.token })
            .user.smileId.getJob()
            .then((jobResponse) => {
                const nextMessages: Array<Chat.SmileId.Message> = [];
                if ("object" === typeof jobResponse.job) {
                    switch (jobResponse.job.status) {
                        case "Approved":
                            dispatch([new Chat.ReplaceAction([new Chat.RefreshClientStateMessage()])]);
                            return;
                        case "Pending":
                            dispatch([new Chat.ReplaceAction([new Chat.SmileId.WaitingMessage(jobResponse.job.id)])]);
                            return;
                    }
                    nextMessages.push(new Chat.SmileId.InfoMessage(jobResponse.job.status));
                } else {
                    nextMessages.push(new Chat.SmileId.InfoMessage());
                }
                if (jobResponse.isSandbox) {
                    nextMessages.push(new Chat.SmileId.MockButtonMessage());
                }
                nextMessages.push(new Chat.SmileId.ButtonMessage());
                dispatch([new Chat.ReplaceAction(nextMessages)]);
            })
            .catch((error: Error | AxiosError) => {
                if ("response" in error && error.response && error.response.status === 403) {
                    console.warn("Invalid SmileID state");
                    location.reload();
                    return;
                }
                if (!axios.isCancel(error)) {
                    history.push("/failure");
                    return Promise.reject(error);
                }
            });
    }, []);

    return <Base.LoadMessage />;
};
