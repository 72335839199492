import * as Chat from "modules/chat";
import * as Client from "modules/client";
import { ButtonRestructuring } from "modules/components/button-restructuring";
import React from "react";
import { WrapMessage } from "../base";
import * as State from "../state";

export const OpenMessage =
    Client.withState<State.MessageProps<Chat.Restructuring.RestructuringOpenMessage>, { applicationId: number; }>(
        (
            { applicationId },
        ) => {
            const at = React.useMemo(() => new Date().toISOString(), []);
            return (
                <WrapMessage
                    source={"bot"}
                    at={at}
                    className="lower">
                    <ButtonRestructuring id={applicationId} />
                </WrapMessage>
            );
        },
        state =>
            "user" === state.type && {
                applicationId: state.request.applicationId,
            },
    );
OpenMessage.displayName = "Restructuring.OpenMessage";
