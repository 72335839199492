import * as Agreement from "modules/chat/agreement";
import * as State from "../state";
import { CompleteMessage } from "./complete-message";
import { ConfirmEmailFormMessage } from "./confirm-email-form-message";
import { ConfirmEmailSubmitMessage } from "./confirm-email-submit-message";
import { ConfirmFormMessage } from "./confirm-form-message";
import { ConfirmRequestMessage } from "./confirm-request-message";
import { ConfirmSubmitMessage } from "./confirm-submit-message";
import { EmailLoadMessage } from "./email-load-message";
import { ExitMessage } from "./exit-message";
import { InfoMessage } from "./info-message";
import { OpenMessage } from "./open-message";
import { RefuseFormMessage } from "./refuse-form-message";
import { RefuseInfoMessage } from "./refuse-info-message";
import { RefuseSendMessage } from "./refuse-send-message";
import { ResendMessage } from "./resend-message";
import { SendMessage } from "./send-message";
import { WelcomeMessage } from "./welcome-message";

export function MessageComponentFactory(type: Agreement.Message["type"] | string): State.Message | undefined {
    switch (type) {
        case "agreement:welcome":
            return WelcomeMessage;
        case "agreement:info":
            return InfoMessage;
        case "agreement:open":
            return OpenMessage;
        case "agreement:email-load":
            return EmailLoadMessage;
        case "agreement:confirm-email-form":
            return ConfirmEmailFormMessage;
        case "agreement:confirm-email-submit":
            return ConfirmEmailSubmitMessage;
        case "agreement:confirm-request":
            return ConfirmRequestMessage;
        case "agreement:confirm-form":
            return ConfirmFormMessage;
        case "agreement:confirm-submit":
            return ConfirmSubmitMessage;
        case "agreement:refuse-info":
            return RefuseInfoMessage;
        case "agreement:refuse-form":
            return RefuseFormMessage;
        case "agreement:resend":
            return ResendMessage;
        case "agreement:complete":
            return CompleteMessage;
        case "agreement:send":
            return SendMessage;
        case "agreement:refuse-send":
            return RefuseSendMessage;
        case "agreement:exit":
            return ExitMessage;
    }
    return undefined;
}
