import { Card } from "../client/api";

export class InitMessage {
    public readonly type = "card:init-message";
}

export class ConfirmMessage {
    public readonly type = "card:confirm-message";
}

export class LoadRedirectMessage {
    public readonly type = "card:load-redirect-message";
}

export class RedirectMessage {
    public readonly type = "card:redirect-message";

    public constructor(public readonly action: string) {}
}

export class LoadCompleteMessage {
    public readonly type = "card:load-complete-message";
}

export class CompleteMessage {
    public readonly type = "card:complete-message";
    public constructor(public readonly card: Card) {}
}

export class CompleteButtonMessage {
    public readonly type = "card:complete-button-message";
}

export class FailureMessage {
    public readonly type = "card:failure-message";
}

export type Message =
    InitMessage
    | ConfirmMessage
    | LoadRedirectMessage
    | RedirectMessage
    | LoadCompleteMessage
    | CompleteMessage
    | CompleteButtonMessage
    | FailureMessage;
