import * as Chat from "modules/chat";
import * as React from "react";
import { OpenInCapacitorBrowser } from "../../../modules/components/open-in-capacitor-browser";
import { DeepLinkCloseBrowserSmileId } from "../../app-url-listener/AppUrlListener";
import { LocalizedRedirect } from "../../locale";
import * as State from "../state";

type OpenBrowserMessageProps = State.MessageProps<Chat.SmileId.OpenBrowserMessage>;

export const OpenBrowserMessage: React.FC<
    React.PropsWithChildren<OpenBrowserMessageProps>
> = ({ value: { url, jobId } }) => {
    const dispatch = Chat.useDispatchContext();

    if (!url || !jobId) {
        return <LocalizedRedirect to="/dashboard" />;
    }

    const onAppUrlOpen = React.useCallback((targetUrl: string) => {
        const _url = targetUrl.slice(DeepLinkCloseBrowserSmileId.length);

        switch (_url) {
            case "success":
                dispatch(new Chat.FlushAction([new Chat.SmileId.WaitingMessage(jobId)]));
                break;
            case "error":
                dispatch(new Chat.FlushAction([new Chat.SmileId.ErrorMessage()]));
                break;
            default:
                dispatch(new Chat.FlushAction([new Chat.SmileId.InitMessage()]));
        }
    }, []);

    const onBrowserFinished = React.useCallback(() => {
        dispatch(new Chat.FlushAction([new Chat.SmileId.InitMessage()]));
    }, [dispatch]);

    return (<OpenInCapacitorBrowser
        url={url}
        onBrowserFinished={onBrowserFinished}
        onAppUrlOpen={onAppUrlOpen} />);
};
