export type DecisionType = "accept" | "reject";

export class LoadMessage {
    public readonly type = "decision:load-message";

    constructor(public readonly decision: DecisionType) {}
}

export class ButtonMessage {
    public readonly type = "decision:button-message";
}

export type Message = LoadMessage | ButtonMessage;
