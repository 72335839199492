export const IconCross = () => (
    <svg
        width="20"
        height="20"
        className="icon icon_cross"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.33183 0.902272C1.93764 0.508083 1.2934 0.492636 0.893019 0.893019C0.492636 1.2934 0.508083 1.93764 0.902272 2.33183L8.57007 9.99963L0.902274 17.6674C0.508084 18.0616 0.492638 18.7058 0.893021 19.1062C1.2934 19.5066 1.93764 19.4912 2.33183 19.097L9.99962 11.4292L17.6674 19.097C18.0616 19.4912 18.7058 19.5066 19.1062 19.1062C19.5066 18.7058 19.4912 18.0616 19.097 17.6674L11.4292 9.99963L19.097 2.33183C19.4912 1.93764 19.5066 1.2934 19.1062 0.893019C18.7058 0.492636 18.0616 0.508083 17.6674 0.902272L9.99962 8.57007L2.33183 0.902272Z"
            fill="white"
        />
    </svg>
);
