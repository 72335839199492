import * as Chat from "modules/chat";
import { memo } from "react";
import * as State from "../state";
import { CalculatorMessage } from "./calculator-message";

export const EditLoadingMessage: State.Message = memo(({ value }) => {
    return (<CalculatorMessage
        value={value}
        submitMessage={new Chat.Calculator.EditMenuMessage()} />);
});

EditLoadingMessage.displayName = "Calculator.EditLoadingMessage";
