import * as React from "react";
import { getCurrentConfig, MAX_DOUBLE_CLICKS_TO_ACTION, setCurrentEnv, TIME_TO_ACTIVATE_ANOTHER_ENV_MODE } from "../../../app/config";
import { LocalizedLink } from "../../../app/locale";

export const LogoLink: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [doubleClickCount, increaseDoubleClickCount] = React.useReducer<React.Reducer<number, any | "reset">>((state, action): number => {
        if (action === "reset") {
            return 0;
        }
        return state >= MAX_DOUBLE_CLICKS_TO_ACTION ? 0 : state + 1;
    }, 0);

    React.useEffect(() => {
        if (doubleClickCount === 0) {
            return;
        }

        if (doubleClickCount < MAX_DOUBLE_CLICKS_TO_ACTION) {
            const tId = setTimeout(() => {
                increaseDoubleClickCount("reset");
            }, TIME_TO_ACTIVATE_ANOTHER_ENV_MODE);
            return () => clearTimeout(tId);
        }
        switch (getCurrentConfig().environment) {
            case "test":
                setCurrentEnv("production");
                break;
            case "production":
                setCurrentEnv("test");
                break;
            default:
                return;
        }
        location.reload();
    }, [doubleClickCount, increaseDoubleClickCount]);

    return (
        <LocalizedLink
            to="/chat"
            className={`logo logo_env-${getCurrentConfig().environment}`}
            onDoubleClick={increaseDoubleClickCount}>
            {children}
        </LocalizedLink>
    );
};
