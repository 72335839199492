import { RestructuringScheduleItem } from "../client/api/credit";

export const activeRestructuringPayment = (schedule: RestructuringScheduleItem[]): RestructuringScheduleItem => {
    for (const item of schedule) {
        if (item.debt > 0.01) {
            return item;
        }
    }
    return schedule[schedule.length - 1];
};
