import { InputMessage } from "app/chat/base/form";
import * as Chat from "modules/chat";
import * as React from "react";
import { useState } from "react";
import * as State from "../state";

export const ConfirmFormMessage: State.Message<State.MessageProps<Chat.SignUp.ConfirmFormMessage>> = (
    { value: { phone } },
) => {
    const dispatch = Chat.useDispatchContext();
    const [value, setValue] = useState("LL");
    const isSubmitEnable = value.match(/^LL\d{6}$/);
    const handleSubmit = React.useCallback(() => {
        if (!isSubmitEnable) {
            return false;
        }
        dispatch(new Chat.ReplaceAction([new Chat.SignUp.ConfirmMessage(phone, value.replace(/-/g, ""))]));
    }, [dispatch, isSubmitEnable, phone, value]);
    const handleChange = React.useCallback((newValue: string) => {
        const match = newValue.match(/LL\d{1,6}/);
        setValue(match ? match[0] : "LL");
    }, []);

    return (<InputMessage
        disabled={!isSubmitEnable}
        onSubmit={handleSubmit}
        onChange={handleChange}
        value={value} />);
};
