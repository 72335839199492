import * as React from "react";
import * as Chat from "./chat";
import * as Dashboard from "./dashboard";
import * as Info from "./info";
import * as Welcome from "./info/welcome";
import * as Internal from "./internal";
import { LocalizedRedirect } from "./locale";
import * as Nav from "./navigation";
import * as Registration from "./registration";

export const routes = (): Array<React.ReactNode> => [
    Welcome.Route(),
    Chat.Route(),
    Nav.Route(),
    ...Internal.routes(),
    ...Info.routes(),
    ...Registration.routes(),
    Dashboard.Route(),
    <LocalizedRedirect
        key="redirect"
        to="/not-found" />,
];
