import * as Chat from "modules/chat";
import { DecisionType } from "modules/chat/decision";
import * as React from "react";
import * as Base from "../base";

export const ButtonMessage: React.FC<{}> = () => {
    const dispatch = Chat.useDispatchContext();

    const handleClick = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            const type: DecisionType =
                e.currentTarget.dataset.id === "accept-button"
                    ? "accept"
                    : "reject";

            dispatch([
                new Chat.FlushAction([new Chat.Decision.LoadMessage(type)]),
            ]);
        },
        [dispatch],
    );

    return (
        <Base.ButtonMessage
            source={"user"}
            className={"mock-button"}>
            <div className="form-group">
                <Base.Button
                    data-id="reject-button"
                    type="button"
                    theme={"primary"}
                    onClick={handleClick}
                >
                    Reject
                </Base.Button>
                <Base.Button
                    data-id="accept-button"
                    type="button"
                    theme={"secondary"}
                    onClick={handleClick}
                >
                    Accept
                </Base.Button>
            </div>
        </Base.ButtonMessage>
    );
};
