import { AFInit, AppsFlyer } from "appsflyer-capacitor-plugin";
import { getCurrentConfig } from "../../app/config";

export async function initAppsFlyer(): Promise<void> {
    const devKey: string | undefined = getCurrentConfig().config.appsFlyerKey;

    if (!devKey) {
        return;
    }

    const afConfig: AFInit = {
        appID: "", // replace with your app ID (IOS).
        devKey,
        isDebug: process.env.NODE_ENV?.toLowerCase() !== "production",
        minTimeBetweenSessions: 6, // default 5 sec
        registerOnDeepLink: false,
        registerConversionListener: true,
        registerOnAppOpenAttribution: false,
        deepLinkTimeout: 4000, // default 3000 ms
    };

    AppsFlyer.initSDK(afConfig).catch((err) => {
        // 403 error when limit is reached, ignore
        console.warn("AppsFlyer.initSDK catch ", err);
    });
}
