import { useLocale } from "app/locale";
import * as Chat from "modules/chat";
import { ReplaceAction } from "modules/chat";
import * as React from "react";
import * as Base from "../base";

export const RefuseInfoMessage = () => {
    const dispatch = Chat.useDispatchContext();
    const { t } = useLocale("registration");

    const handleCanceled = React.useCallback(() => {
        dispatch(new ReplaceAction([new Chat.Restructuring.RestructuringRefuseSendMessage()]));
    }, []);

    const handleBack = React.useCallback(() => {
        dispatch(new ReplaceAction([new Chat.TextMessage(t("back"), "user"), new Chat.TextMessage(t("restructuring-back-confirmed"), "bot"), new Chat.Restructuring.RestructuringRefuseFormMessage()]));
    }, []);

    return (
        <Base.ButtonMessage source={"user"}>
            <Base.Button
                theme={"primary"}
                onClick={handleCanceled}>
                {t("change-my-mind")}
            </Base.Button>
            <Base.Button
                theme="secondary"
                onClick={handleBack}>
                {t("back")}
            </Base.Button>
        </Base.ButtonMessage>
    );
};
