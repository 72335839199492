import * as Chat from "modules/chat";
import { TextMessage } from "modules/chat";
import { Markdown } from "modules/components/markdown";
import * as React from "react";
import { CalculatorStorage } from "./calculator/storage";

export interface ButtonProps {
    id?: string;
    className: string;
    messages: Array<Chat.Message | undefined>;
    replace?: Chat.Message | undefined;
    text: string;
    promocode?: {
        value: string;
        discount: number;
    };
}

export const Button: React.FC<
    React.PropsWithChildren<ButtonProps>
> = ({ className, messages, text, replace, id, promocode, children }) => {
    const dispatch = Chat.useDispatchContext();
    const handleClick = React.useCallback(() => {
        if (promocode) {
            try {
                CalculatorStorage.setPromoCode(promocode.value, promocode.discount);
            } catch (error) {
                console.error("Unable to store promocode.");
            }
        }

        return dispatch(new Chat.ReplaceAction([replace, new TextMessage(text, "user"), ...messages].filter((m): m is Chat.Message => !!m)));
    }, [dispatch, messages, replace, text, promocode]);

    return (
        <button
            className={className}
            type="button"
            onClick={handleClick}
            id={id}>
            {children}
            <Markdown content={text} />
        </button>
    );
};
