import { AxiosInstance, AxiosResponse } from "axios";
import { Details } from "modules/chat/bank-id";

type ResponseWithConfig<S extends string> = {
    status: S;
    config: {
        publicKey: string;
        data?: object;
    };
};

type ResponseAuth = ResponseWithConfig<"AUTH">;
type ResponseFailure = ResponseWithConfig<"FAILED"> & ResponseReauth;
type ResponseData = { status: "PROCESSING" | "AVAILABLE"; details: Details; isReauthRequired?: boolean; };
export type Response = ResponseAuth | ResponseData | ResponseFailure;

export type ResponseReauth = {
    config: {
        publicKey: string;
        token: string;
        data?: object;
    };
};

export const Api = (endpoint: AxiosInstance) => {
    return {
        auth: (code: string): Promise<any> => {
            return endpoint.post<{ url: string; }>("/v3/mono/auth", {
                Auth: { code },
            });
        },
        get: async (): Promise<Response> => {
            const { data } = await endpoint.get<Response, AxiosResponse<Response>>("/v3/mono/auth");
            return data;
        },
        reauth: async (): Promise<ResponseReauth | false> => {
            return endpoint
                .post<ResponseReauth | {}>(
                    "/v3/mono/reauth",
                    {},
                    {
                        validateStatus: (status: number) => [200, 409].includes(status),
                    },
                )
                .then((response: AxiosResponse<ResponseReauth | {}>): ResponseReauth | false => {
                    if (response.status !== 409 && "config" in response.data) {
                        return response.data as any as ResponseReauth;
                    }
                    return false;
                });
        },
    } as const;
};
