import { useLocale } from "app/locale";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import { useCallback } from "react";
import * as Base from "../base";
import * as State from "../state";

export const SendMessage: State.Message<State.MessageProps<Chat.Restructuring.RestructuringSendMessage>> = (
    { value },
) => {
    const { t } = useLocale("registration");

    const handleRequest = useCallback((api: Client.Api.Instance) => {
        return api.user.restructuring.send();
    }, []);

    const handleResponse = useCallback(
        async (response: Promise<void>) => {
            await response;
            return new Chat.ReplaceAction(
                [
                    new Chat.TextMessage(t("sign-a-agreement"), "user"),
                    new Chat.TextMessage([t("sms-sent")], "bot", false, ""),
                    new Chat.TimeoutMessage(180, new Chat.Restructuring.RestructuringResendMessage()),
                    new Chat.Restructuring.RestructuringConfirmFormMessage(),
                ],
                value,
            );
        },
        [value],
    );

    return (<Base.DelaySubmitMessage
        value={undefined}
        onRequest={handleRequest}
        onFinish={handleResponse} />);
};
