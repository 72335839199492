// TODO simplify this code
const keyboardLayoutMap: { [k: string]: string; } = [
    [")", "!", "@", "#", "$", "%", "^", "&", "*", "("],
    [")", "!", "\"", "№", ";", "%", ":", "?", "*", "("],
].reduce(
    (map, layout) => Object.assign(map, Object.fromEntries(layout.map((symbol, _number) => [symbol, _number]))), {},
);

export const numericFilter = (value: string): string => {
    const result = value.trim().replace(/\D/g, (char: string): string => (char in keyboardLayoutMap ? keyboardLayoutMap[char] : ""));
    if (result.length === 0) {
        return result;
    }
    return parseInt(value, 10).toString(10);
};
