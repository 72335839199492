import * as React from "react";
import { SvgCircleArc } from "./svg-circle-arc";

import "./styles.scss";

export interface CircleChartProps {
    progress: number;
    border: number;
    color: string;
    color2?: string;
    details?: string;
    reverse?: boolean;
}

export type CircleChartSVGProps = CircleChartProps & React.SVGProps<SVGSVGElement>;

export const CircleChart = React.forwardRef<SVGSVGElement, CircleChartSVGProps>(
    (
        { color, color2, progress, border, details, reverse, children, ...childProps },
        ref,
    ) => {
        const angle = Math.round((progress * 360) / 100);
        const radius = 50 - border / 2;
        return (
            <div className="chart-overlay">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="-50 -50 100 100"
                    className="circular-chart"
                    {...childProps}
                    ref={ref}>
                    <defs>
                        <linearGradient
                            id="gradRed"
                            gradientTransform="rotate(-10)">
                            <stop
                                offset="0"
                                stopColor="white" />
                            {progress > 10 && (
                                <>
                                    <stop
                                        offset="20%"
                                        stopColor="white" />
                                    <stop
                                        offset="100%"
                                        stopColor="#FF437A" />
                                </>
                            )}
                        </linearGradient>
                        <linearGradient
                            id="gradGreen"
                            gradientTransform="rotate(-10)">
                            <stop
                                offset="0"
                                stopColor="white" />
                            {progress > 10 && (
                                <>
                                    <stop
                                        offset="20%"
                                        stopColor="white" />
                                    <stop
                                        offset="100%"
                                        stopColor="#73E61F" />
                                </>
                            )}
                        </linearGradient>
                    </defs>
                    <g>
                        {color2 && <circle
                            className="circular-bg"
                            stroke={color2}
                            cx="0"
                            cy="0"
                            r={radius}
                            strokeWidth={border} />}
                        <SvgCircleArc
                            r={radius}
                            angle={Math.max(Math.min(180, angle), 1)}
                            fill="none"
                            strokeWidth={border}
                            stroke={color}
                            transform={"rotate(-90)"}
                            strokeLinecap="round" />
                        {angle > 180 && <SvgCircleArc
                            r={radius}
                            angle={angle - 180}
                            fill="none"
                            strokeWidth={border}
                            stroke="white"
                            transform={"rotate(90)"}
                            strokeLinecap="round" />}
                    </g>
                </svg>
                <div className="details" />
                {children}
            </div>
        );
    });

CircleChart.displayName = "CircleChart";
