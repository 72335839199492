import * as State from "app/chat/state";
import axios from "axios";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as React from "react";
import { useHistory } from "react-router-dom";
import * as Base from "../base";

export const InitMessage: State.Message<State.MessageProps<Chat.BankId.InitMessage>> = () => {
    const { api } = Client.useStateContext();
    const dispatch = Chat.useDispatchContext();
    const history = useHistory();

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        api.with({ cancelToken: cancelToken.token })
            .user.mono.get()
            .then((response) => {
                if (!response) {
                    return history.push("/forbidden");
                }

                switch (response.status) {
                    case "PROCESSING":
                        history.push("/registration/mono/processing");
                        return;
                    case "AVAILABLE":
                        history.push("/registration/mono/details");
                        return;
                    case "AUTH":
                        dispatch([
                            new Chat.ReplaceAction([
                                new Chat.BankId.WidgetMessage(
                                    response.config.publicKey,
                                    response.config.data ?? {},
                                ),
                            ]),
                        ]);
                        return;
                    case "FAILED":
                        dispatch([
                            new Chat.ReplaceAction([
                                new Chat.BankId.ReauthInfoMessage,
                                new Chat.BankId.WidgetMessage(
                                    response.config.publicKey,
                                    response.config.data ?? {},
                                ),
                            ]),
                        ]);
                        return;
                    default:
                        console.error(`[InitMessage] Unknown status for Mono Account: ${(response as any).status}`);
                }
            });
        return () => cancelToken.cancel();
    }, []);

    return <Base.LoadMessage />;
};
