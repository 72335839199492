import * as React from "react";
import { Route, RouteProps } from "react-router-dom";
import { useLocale } from "../hooks";
import { LOCALE_ROUTE_PATH } from "../i18n";

export type LocalizedRouteProps = RouteProps;

export const LocalizedRoute: React.FC<React.PropsWithChildren<LocalizedRouteProps>> = ({ children, ...props }) => {
    const { localizeRoutePath } = useLocale();

    const nextPath = localizeRoutePath(props.path, item => `${LOCALE_ROUTE_PATH}${item}`);

    return (
        <Route
            {...props}
            path={nextPath}>
            {children}
        </Route>
    );
};
