import { AccordionDataType } from "types";

export const accordionData: Array<AccordionDataType> = [
    {
        title: "What is KoboGo?",
        content:
            "KoboGo is a payday loan service. We make it easy for you to apply for a loan and receive your loan to your card in minutes 24/7.",
    },
    {
        title: "How can I get an instant loan?",
        content:
            "You need an Android phone or iPhone and a BVN. That's all! Download the KoboGo app from the Google Play or App Store. Sign up with the phone number linked to your BVN, answer a few questions and confirm your identity to receive a loan offer. If you accept it, you will receive your loan instantly!",
    },
    {
        title: "How much can I borrow and for how long?",
        content:
            "KoboGo offers loans from loans of up to ₦ 100 000 for a period of up to 30 days. The better your lending history and positive behavior, the more you can access and the longer the period!",
    },
    {
        title: "What makes KoboGo different from other lenders?",
        content:
            "You can get a loan instantly in 1 minute, from anywhere in Nigeria!",
    },
    {
        title: "Do I need documents or a collateral?",
        content:
            "No document, no collateral! You will only need to share your BVN details during the loan application and connect your card or bank account to authorize us to debit your account for the repayment.",
    },
    {
        title: "I have a complaint or feedback, how do I contact you?",
        content: (
            <p>
                Please send an email to <a href="mailto:info@kobogo.ng">info@kobogo.ng</a> or reach out through our social media platforms.
            </p>
        ),
    },
    {
        title: "Do I need to submit any document?",
        content: (
            <>
                <p>
                    No. To get a KoboGo loan, you do not need to submit any
                    document. We will only need you to provide phone number
                    linked to your BVN, accurately answer a few questions and
                    confirm your identity.
                </p>
                <p>
                    *NOTE: Please ensure you provide accurately answers on the
                    required fields. You would be barred from accessing KoboGo
                    loans if any detail is falsified.
                </p>
            </>
        ),
    },
    {
        title: "How do I return the loan?",
        content:
            "You can return the money using a bank card through your personal account. You can pay in part or in full, the main thing is to settle the debt before the contract expires.!",
    },
];
