export class InitMessage {
    public readonly type = "direct-debit:init";
}

export class WelcomeMessage {
    public readonly type = "direct-debit:welcome";
}

export abstract class MandateMessage {
    constructor(
        public readonly accountNumber: string,
        public readonly bankName: string,
        public readonly createdAt: string,
    ) {
    }
}

export class MandatePendingMessage extends MandateMessage {
    public readonly type = "direct-debit:mandate-pending";
}

export class MandatePendingTooLongMessage {
    public readonly type = "direct-debit:mandate-pending:long";
}

export class MandateFailedMessage extends MandateMessage {
    public readonly type = "direct-debit:mandate-failed";
}

export class MandateSuccessMessage extends MandateMessage{
    public readonly type = "direct-debit:mandate-success";
}

export class ConfirmMessage {
    public readonly type = "direct-debit:confirm";
}

export class LoadMessage {
    public readonly type = "direct-debit:load";
}

export class WidgetMessage {
    public readonly type = "direct-debit:widget";

    constructor(public readonly redirectUrl: string) {
    }
}

export type Message =
    InitMessage |
    WelcomeMessage |
    ConfirmMessage |
    LoadMessage |
    WidgetMessage |
    MandateSuccessMessage |
    MandateFailedMessage |
    MandatePendingMessage |
    MandatePendingTooLongMessage;
