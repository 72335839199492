import * as Chat from "modules/chat";
import { CalculatorMessageType } from "modules/hooks/use-calc";
import * as React from "react";
import { InputRange } from "./input-range";

export type FormGroupProps = {
    message: CalculatorMessageType;
    notice: string;
    label: string;
    field: "amount" | "term";
    messageRender: (conditions: any) => Chat.Message;
};

export const FormGroup: React.FC<React.PropsWithChildren<FormGroupProps>> = React.memo(
    ({ message, notice, label, field, messageRender }) => {
        const {
            min,
            max,
            limit = max,
            step = 1,
            value,
        } = message.conditions[field];

        const dispatch = Chat.useDispatchContext();
        const useConditions = (newValue: number) => ({
            ...message.conditions,
            [field]: {
                ...message.conditions[field],
                value: Math.round(newValue / step) * step,
            },
        });

        const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = Number(e.currentTarget.value);
            if (newValue > limit || newValue < min) {
                return;
            }

            dispatch(
                new Chat.ReplaceAction(
                    [messageRender(useConditions(newValue))],
                    message,
                ),
            );
        }, [dispatch, message, messageRender, useConditions, limit, min]);

        return (
            <div
                className="calc-group"
                data-field={field}>
                <div className="calc-group__data">
                    <div>
                        <span className="calc-group__label">{label}</span>
                        <span className="calc-group__notice">{notice}</span>
                    </div>
                    <span className="calc-group__value with-currency">
                        {value}
                    </span>
                </div>

                <div className="slider-wrapper">
                    <span className="dot dot_left">{min}</span>
                    <div className="input-wrap">
                        <InputRange
                            min={min}
                            max={limit}
                            step={step}
                            value={value}
                            onChange={handleChange}
                        />
                    </div>
                    <span className="dot dot_right">{max}</span>
                </div>
            </div>
        );
    },
);
