import * as Chat from "modules/chat";
import * as State from "../state";
import { ButtonMessage } from "./button-message";
import { InfoMessage } from "./info-message";
import { InitMessage } from "./init-message";
import { LoadMessage } from "./load-message";
import { MockButtonMessage } from "./mock-button-message";
import { MockLoadMessage } from "./mock-load-message";
import { OpenBrowserMessage } from "./open-browser-message";
import { SuccessMessage } from "./success-message";
import { WaitingMessage } from "./waiting-message";
import { WidgetMessage } from "./widget-message";

export function MessageComponentFactory(type: Chat.SmileId.Message["type"]): State.Message | undefined {
    switch (type) {
        case "face-id:info-message":
            return InfoMessage;
        case "face-id:button-message":
            return ButtonMessage;
        case "face-id:load-message":
            return LoadMessage;
        case "face-id:widget-message":
            return WidgetMessage;
        case "face-id:success-message":
            return SuccessMessage;
        case "face-id:mock-button-message":
            return MockButtonMessage;
        case "face-id:mock-load-message":
            return MockLoadMessage;
        case "face-id:waiting-message":
            return WaitingMessage;
        case "face-id:init-message":
            return InitMessage;
        case "face-id:open-browser-message":
            return OpenBrowserMessage;
    }
}
