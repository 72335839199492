import * as Client from "modules/client";

import avatar from "./icon-male.svg";

export const Image = Client.withState<{}, { photo: string | undefined; }>(
    ({ photo }) => {
        return (<img
            id="avatar-img"
            src={photo ?? avatar}
            alt="Avatar" />);
    },
    (state) => {
        if ("user" !== state.type) {
            return false;
        }
        return {
            photo: state.user.photo,
        };
    },
);
