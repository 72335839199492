import * as Client from "modules/client";
import * as React from "react";
import Select from "react-select";
import { ControlProps } from "./control";

export type SelectControlProps = ControlProps<Client.Api.JSONSchemaEnum>;
const DropdownIndicator = () => <i className="icon icon_select-arrow" />;
export const SelectControl: React.FC<React.PropsWithChildren<SelectControlProps>> = ({ schema, onChange }) => {
    const options = schema.oneOf.map(({ const: value, title: label }) => ({ label, value }));
    const handleChange = React.useCallback(({ value }: any) => {
        onChange(value);
    }, [onChange]);
    return (<Select
        options={options}
        components={{ DropdownIndicator }}
        placeholder={"Выберите из списка"}
        onChange={handleChange}
        className="form__control-select"
        classNamePrefix="select" />);
};
