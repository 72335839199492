import { Browser } from "@capacitor/browser";
import classNames from "classnames";
import * as React from "react";
import * as ReactDOM from "react-dom";

import "./styles.scss";
import { CapacitorOnBrowserOpenError } from "../../modules/capacitor/browser";
import * as Client from "../../modules/client";
import { ReviewApiType } from "../../modules/client/api/review";
import { IframeLoader } from "../../modules/components/iframe-loader";
import { ReviewButton } from "./review-buttons/review-button";

enum ModalSteps {
    likeMobileApplication = "likeMobileApplication",
    likeMobileApplicationYES = "likeMobileApplicationYES",
    likeMobileApplicationNO = "likeMobileApplicationNO",
    readyToRateUs = "readyToRateUs",
    readyToRateUsYES = "readyToRateUsYES",
    readyToRateUsNO = "readyToRateUsNO",
    readyToTellUsWhy = "readyToTellUsWhy",
    readyToTellUsWhyYES = "readyToTellUsWhyYES",
    readyToTellUsWhyNO = "readyToTellUsWhyNO",
    tellUsWhy = "tellUsWhy",
    loader = "loader",
}
export type DeepLinkModalProps = { onClose?: () => void; };

export const FeedbackModal: React.FC<React.PropsWithChildren<DeepLinkModalProps>> = ({ ...props }) => {
    const clientState = Client.useStateContext();
    const [isShow, setIsShow] = React.useState<boolean>(true);
    const [currentStep, setCurrentStep] = React.useState<ModalSteps>(ModalSteps.likeMobileApplication);
    const { onClose } = props;

    const closeModal = React.useCallback((): void => {
        setIsShow(false);

        if (typeof props.onClose === "function") {
            setTimeout(() => onClose && onClose(), 500);
        }
    }, []);

    const stopPropagation = React.useCallback((e: React.MouseEvent<HTMLElement>): void => {
        e.stopPropagation();
    }, []);

    const sendMark = React.useCallback((type: ReviewApiType, event: React.MouseEvent) => {
        event.stopPropagation();
        setCurrentStep(ModalSteps.loader);
        clientState.api.guest.review
            .sendMark(type)
            .then(() => {
                alert("Thank you for review!");
                closeModal();
            })
            .catch(() => {
                alert("Something went wrong, please try again later");
                setCurrentStep(ModalSteps.readyToRateUs);
            });
    }, []);

    const HANDLE = React.useCallback((step: ModalSteps, event: React.MouseEvent<HTMLButtonElement>) => {
        switch (step) {
            case ModalSteps.likeMobileApplicationYES: {
                event.preventDefault();
                setCurrentStep(ModalSteps.readyToRateUs);
                return;
            }
            case ModalSteps.likeMobileApplicationNO: {
                event.preventDefault();
                setCurrentStep(ModalSteps.readyToTellUsWhy);
                return;
            }
            case ModalSteps.readyToRateUsYES: {
                event.preventDefault();
                const url: string = "https://play.google.com/store/apps/details?id=ng.kobogo.mobile&showReviewDialog=true";
                closeModal();
                Browser.open({ url })
                    .then()
                    .catch(msg => CapacitorOnBrowserOpenError({ url, msg }));
                return;
            }
            case ModalSteps.readyToRateUsNO: {
                event.preventDefault();
                closeModal();
                return;
            }
            case ModalSteps.readyToTellUsWhyYES: {
                event.preventDefault();
                setCurrentStep(ModalSteps.tellUsWhy);
                return;
            }
            case ModalSteps.readyToTellUsWhyNO: {
                closeModal();
                return;
            }
        }
    }, []);

    return ReactDOM.createPortal(
        <div
            className={classNames("feedback-modal__container", !isShow && "feedback-modal__container_hidden", currentStep === ModalSteps.loader && "feedback-modal__container_no-events")}
            onClick={closeModal}
        >
            <div
                className={classNames("feedback-modal__form", currentStep === ModalSteps.loader && "feedback-modal__form_hidden")}
                onClick={stopPropagation}>
                {currentStep === ModalSteps.likeMobileApplication && (
                    <BaseModalContent
                        accept={React.useCallback(
                            (
                                e: React.MouseEvent<HTMLButtonElement>,
                            ) => HANDLE(
                                ModalSteps.likeMobileApplicationYES, e,
                            ), [HANDLE])}
                        reject={React.useCallback(
                            (
                                e: React.MouseEvent<HTMLButtonElement>,
                            ) => HANDLE(
                                ModalSteps.likeMobileApplicationNO, e,
                            ), [HANDLE])}
                        text={"Enjoying the app?"}
                    />
                )}
                {currentStep === ModalSteps.readyToRateUs && (
                    <BaseModalContent
                        accept={React.useCallback(
                            (
                                e: React.MouseEvent<HTMLButtonElement>,
                            ) => HANDLE(
                                ModalSteps.readyToRateUsYES, e,
                            ), [HANDLE])}
                        reject={React.useCallback(
                            (
                                e: React.MouseEvent<HTMLButtonElement>,
                            ) => HANDLE(
                                ModalSteps.readyToRateUsNO, e,
                            ), [HANDLE])}
                        text={"Awesome! Ready to rate us on Google Play?"}
                    />
                )}
                {currentStep === ModalSteps.readyToTellUsWhy && (
                    <BaseModalContent
                        accept={React.useCallback(
                            (
                                e: React.MouseEvent<HTMLButtonElement>,
                            ) => HANDLE(
                                ModalSteps.readyToTellUsWhyYES, e,
                            ), [HANDLE])}
                        reject={React.useCallback(
                            (
                                e: React.MouseEvent<HTMLButtonElement>,
                            ) => HANDLE(
                                ModalSteps.readyToTellUsWhyNO, e,
                            ), [HANDLE])}
                        text={"Are you ready to tell us why?"}
                    />
                )}
                {currentStep === ModalSteps.tellUsWhy && <ReviewButton onButtonClick={sendMark} />}
            </div>
            {currentStep === ModalSteps.loader && <IframeLoader />}
        </div>,
        document.body,
    );
};

type BaseModalContentProps = {
    text: string;
    accept: (e: React.MouseEvent<HTMLButtonElement>) => void;
    reject: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const BaseModalContent: React.FC<React.PropsWithChildren<BaseModalContentProps>> = ({ text, accept, reject }) => {
    return (
        <>
            <h3>{text}</h3>

            <div className="feedback-modal__actions">
                <button
                    className="btn btn_blue"
                    onClick={accept}>
                    Yes
                </button>
                <button
                    className="btn btn_white"
                    onClick={reject}>
                    No
                </button>
            </div>
        </>
    );
};

export default FeedbackModal;
