import { Capacitor } from "@capacitor/core";
import * as Sentry from "@sentry/capacitor";
import * as React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { CapacitorLiveUpdate } from "../../../modules/capacitor/live-update";
import { StateWithFrom } from "../../locale";
import * as Info from "../Layout";
import { Layout as ArticleLoader } from "./Layout";

export const Page: React.FC<React.PropsWithChildren> = () => {
    const history = useHistory();
    const location = useLocation<StateWithFrom>();
    const update = location.state?.update;

    React.useEffect(() => {
        if (typeof update !== "object") {
            if (Capacitor.isNativePlatform()) {
                Sentry.captureException("'/update-loading' page: loaded without update state");
            }
            history.push("/failure");
            return;
        }

        const timer: ReturnType<typeof setTimeout> | undefined = setTimeout(() => {
            Sentry.captureException("update-loading page: update timeout, 120s reached");
            history.push("/failure");
        }, 120 * 1000);

        CapacitorLiveUpdate.downloadBundleAndInstall(update.bundle.bundle.url, update.bundle.bundle.version)
            .then((reloadFn) => {
                if (typeof reloadFn === "function") {
                    return reloadFn();
                }
            })
            .catch((e) => {
                Sentry.captureException("CapacitorLiveUpdate.downloadBundleAndInstall error: " + e);
                history.push("/failure");
            });

        return () => {
            clearTimeout(timer);
        };
    }, [update]);

    return (
        <Info.Layout>
            <ArticleLoader />
        </Info.Layout>
    );
};

export default Page;
