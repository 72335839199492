import classNames from "classnames";
import * as Chat from "modules/chat";
import * as React from "react";
import { useState } from "react";

// TODO duplicated code with src/app/chat/agreement/confirm-form-message.tsx
export const ConfirmFormMessage = React.memo(() => {
    const dispatch = Chat.useDispatchContext();
    const [value, setValue] = useState("LL");
    const isSubmitEnable = value.match(/^LL\d{6}$/);
    const handleSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isSubmitEnable) {
            return false;
        }
        const serverValue = value.replace(/-/g, "");
        dispatch([new Chat.DeleteAction(), new Chat.ReplaceAction([new Chat.TextMessage(serverValue, "user"), new Chat.Restructuring.RestructuringConfirmSubmitMessage(serverValue)])]);
    }, [value, isSubmitEnable]);
    const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const match = e.target.value.match(/LL\d{1,6}/);
        setValue(match ? match[0] : "LL");
    }, []);
    return (
        <form onSubmit={handleSubmit}>
            <div className="message user-message editing">
                <div className="form-group">
                    <input
                        onChange={handleChange}
                        value={value}
                        id="sign-token"
                        className="form__control"
                        inputMode="tel"
                        autoFocus />
                    <div className="btn-container v-align">
                        <button
                            type="submit"
                            className={classNames("btn btn_action", isSubmitEnable ? "enabled" : "disabled")}
                            id="agreement-sms">
                            <i className="icon icon_arrow" />
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
});
