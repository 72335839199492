import axios from "axios";
import * as Client from "modules/client";
import { useStateContext } from "modules/client";
import React from "react";
import { useDownloadState } from "./use-download-state";

export function useDocumentDownload(
    onRequest: (api: Client.Api.Instance) => Promise<string>,
): ReturnType<typeof useDownloadState> {
    const [url, setUrl] = useDownloadState();
    const state = useStateContext();

    React.useEffect(() => {
        if (url !== "loading") {
            return;
        }
        const cancelToken = axios.CancelToken.source();
        onRequest(state.api.with({ cancelToken: cancelToken.token }))
            .then((_url) => {
                setUrl(_url);
            })
            .catch((err) => {
                setUrl("failed");
                return Promise.reject(err);
            });
    }, [setUrl, url, onRequest]);

    return [url, setUrl];
}
