import { AxiosInstance } from "axios";

export const Api = (endpoint: AxiosInstance) => ({
    signRequest: () => {
        return endpoint.put("/v2/prolongation/sign-request");
    },
    signConfirm: (token: string) => {
        return endpoint.post("/v2/prolongation/sign-confirm", {
            Prolongation: {
                token,
            },
        });
    },
    agreement: async (requestId: number) => {
        const response = await endpoint.get<{ url: string; }>("v2/prolongation/agreement", {
            params: { requestId },
        });

        return response.data.url;
    },
});
