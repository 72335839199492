import * as Chat from "modules/chat";
import * as Client from "modules/client";
import { useEffect } from "react";
import * as Base from "../base";
import * as State from "../state";

export const CompleteMessage: State.Message<State.MessageProps<Chat.SignUp.CompleteMessage>> = (
    { value: { access, refresh } },
) => {
    const dispatch = Client.useDispatchContext();
    useEffect(() => {
        dispatch(new Client.Auth.LoginAction({ access, refresh }));
    }, [access]);
    return <Base.LoadMessage />;
};
