import { currency } from "app/locale/currency";
import axios, { CancelTokenSource } from "axios";
import * as Chat from "modules/chat";
import { Details } from "modules/chat/bank-id";
import * as Client from "modules/client";
import { formattedPercentages } from "modules/utils/formatted-percentages";
import { plural } from "modules/utils/pluralNumber";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { WrapMessage } from "../base";
import * as State from "../state";

export interface InfoMessageProps {
    amount: number;
    term: number;
    initDebtSum: number;
    interest: number;
    penalty: number;
}

export const InfoMessage = Client.withState<
    State.MessageProps<Chat.Agreement.AgreementInfoMessage>,
    InfoMessageProps
>(
    ({ amount, term, initDebtSum, interest, penalty }) => {
        const { api } = Client.useStateContext();
        const state = Client.useStateContext();
        const history = useHistory();
        const [monoData, setMonoData] = useState<Details | undefined>(
            undefined,
        );
        const isUser = state.type !== "guest";

        useEffect(() => {
            if (!isUser) {
                history.push("/welcome");
            }
            const cancelToken: CancelTokenSource | undefined =
                axios.CancelToken.source();
            api.with({ cancelToken: cancelToken.token })
                .user.mono.get()
                .then((response) => {
                    if (!response) {
                        return history.push("/forbidden");
                    }

                    if (response.status !== "AVAILABLE") {
                        history.push("/failure");
                    }

                    if (response.status === "AVAILABLE") {
                        setMonoData(response.details);
                    }
                });
            return () => cancelToken && cancelToken.cancel();
        }, [setMonoData, isUser]);

        return (
            <WrapMessage
                source={"bot"}
                className={"pdf"}>
                <h4>The main points of the contract</h4>
                <p>Account number: {monoData?.accountNumber}</p>
                <p>Name of the bank: {monoData?.institution.name}</p>
                <p>
                    Amount: {currency} {amount}
                </p>
                <p>Term of the discount period: {plural(term, "days")}</p>
                <p>
                    Frequency of interest payments - every{" "}
                    {plural(term, "days")}
                </p>
                <p>Discount interest rate: {formattedPercentages(interest)}</p>
                <p>Standard interest rate: {formattedPercentages(penalty)}</p>
                <p>
                    The total cost of the loan and interest at the end of the
                    discount period is as follows: {currency} {initDebtSum}
                </p>
            </WrapMessage>
        );
    },
    state =>
        "user" === state.type && {
            amount: state.request.amount,
            term: state.request.term,
            initDebtSum: state.request.initDebtSum,
            interest: state.request.rate.interest,
            penalty: state.request.rate.penalty,
        },
);
InfoMessage.displayName = "Agreement.InfoMessage";
