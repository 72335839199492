import { Capacitor } from "@capacitor/core";
import * as State from "app/chat/state";
import axios from "axios";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as React from "react";
import { useHistory } from "react-router-dom";
import * as Base from "../base";

export const LoadRedirectMessage: State.Message<State.MessageProps<Chat.Card.LoadRedirectMessage>> = () => {
    const history = useHistory();
    const { api } = Client.useStateContext();
    const dispatch = Chat.useDispatchContext();
    const goodUrl = Capacitor.isNativePlatform() ? "kobogo://closeBrowser/chat/card-success" : window.location.origin + "/chat/card-success";

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        api.with({ cancelToken: cancelToken.token })
            .user.payment.order({ type: "card", goodUrl })
            .then((response) => {
                dispatch(new Chat.FlushAction([new Chat.Card.RedirectMessage(response.action)]));
            })
            .catch((error: Error) => {
                if (!axios.isCancel(error)) {
                    history.push("/failure");
                    return Promise.reject(error);
                }
            });
        return () => cancelToken.cancel();
    }, []);

    return <Base.LoadMessage />;
};
