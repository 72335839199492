import { Browser } from "@capacitor/browser";
import * as Sentry from "@sentry/capacitor";

type onBrowserOpenError = {
    url: string;
    [prop: string]: string;
};
export const CapacitorOnBrowserOpenError = (extra: onBrowserOpenError) => {
    Sentry.captureMessage(`Browser.open({url: ${extra.url}, windowName: \'_blank\'}) - failed`, {
        level: "error",
        extra: { ...extra },
    });
};

export function initCapacitorBrowser() {
    // @ts-ignore
    window.open = async url => Browser.open({ url });

    // Рекурсивно ищем ссылку у родителя пока они есть, если нет то null
    function findLink(el: HTMLElement | EventTarget | null): string | null {
        if ((el as HTMLLinkElement)?.tagName === "A" && (el as HTMLLinkElement)?.href) {
            return (el as HTMLLinkElement).href;
        } else if ((el as HTMLElement).parentElement) {
            return findLink((el as HTMLElement).parentElement);
        } else {
            return null;
        }
    }

    async function ClickCallback(e: MouseEvent): Promise<void> {
        try {
            const link = findLink(e.target);

            if (!link) {
                return;
            }

            const url = new URL(link);

            if (!url) {
                return;
            }

            // TODO Костыль, другого решения не нашел, чтобы не обходить весь проект и переделывать ссылки.
            // если это внешняя ссылка то открываем ее в браузере, если внутренняя то игорируем, произойдет обычный роутинг.
            if (
                url.href.indexOf("http://192.") === -1
                && url.href.indexOf("http://localhost") === -1
                && url.href.indexOf("https://localhost") === -1
                && url.href.indexOf("mailto:") === -1
                && url.href.indexOf("tel:") === -1
            ) {
                e.preventDefault();
                await Browser.open({ url: link, windowName: "_blank" });
            }
        } catch (error) { /* empty */ }
    }

    document.addEventListener("click", ClickCallback, false);
}
