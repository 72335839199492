import * as Chat from "modules/chat";
import * as State from "../state";
import { ConfirmMessage } from "./confirm-message";
import { InfoMessage } from "./info-message";
import { InitMessage } from "./init-message";
import { LoadMessage } from "./load-message";
import { ReauthButtonMessage } from "./reauth-button-message";
import { ReauthInfoMessage } from "./reauth-info-message";
import { ReauthLoadMessage } from "./reauth-load-message";
import { WidgetMessage } from "./widget-message";

export function MessageComponentFactory(type: Chat.BankId.Message["type"]): State.Message | undefined {
    switch (type) {
        case "bank-id:widget-message":
            return WidgetMessage;
        case "bank-id:load":
            return LoadMessage;
        case "bank-id:confirm":
            return ConfirmMessage;
        case "bank-id:info":
            return InfoMessage;
        case "bank-id:init-message":
            return InitMessage;
        case "bank-id:reauth-button-message":
            return ReauthButtonMessage;
        case "bank-id:reauth-info-message":
            return ReauthInfoMessage;
        case "bank-id:reauth-load-message":
            return ReauthLoadMessage;
    }
}
