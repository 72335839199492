import * as Client from "modules/client";
import * as React from "react";
import * as Base from "../base";

interface CongratulationsMessageProps {
    amount: number;
}

const CongratulationsMessage: React.ComponentType<
    React.PropsWithChildren
> = Client.withState<{}, CongratulationsMessageProps>(
    (props) => {
        return (
            <p>
                Congratulations!
                You have been approved for an online loan from KoboGo in the amount of <b>NGN {props.amount}</b>
            </p>
        );
    },
    (state) => {
        if (state.type !== "user") {
            return false;
        }
        return {
            amount: state.request.amount,
        };
    },
);

export const WelcomeMessage: React.FC<React.PropsWithChildren> = () => {
    return (
        <Base.WrapMessage source="bot">
            <CongratulationsMessage />
            <p>
                There's just one step left: set up DirectPay to Auto-debit your loan repayments for convenience and
                peace of mind.
            </p>
        </Base.WrapMessage>
    );
};
