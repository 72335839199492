export class ChangeAction {
    public readonly type = "promocode:change";

    constructor(public readonly promocode: string) {}
}

export class ValidAction {
    public readonly type = "promocode:valid";

    constructor(public readonly discount: number) {}
}

export class InvalidAction {
    public readonly type = "promocode:invalid";
}

export type Action = ChangeAction | ValidAction | InvalidAction;
