import * as Chat from "modules/chat";
import { ReplaceAction } from "modules/chat";
import { IconCard } from "modules/components/Icons/IconCard";
import { useCallback, useEffect, useState } from "react";
import { DebtInfo } from "./debt-info";

export const EditMenuMessage = () => {
    const dispatch = Chat.useDispatchContext();
    const [currentAmount, setCurrentAmount] = useState<number>(0);
    const calcData = JSON.parse(localStorage.getItem("ll.calc") || "{}");

    useEffect(() => {
        setCurrentAmount(calcData[1]);
    }, [setCurrentAmount, calcData]);

    const handleClick = useCallback(() => {
        localStorage.setItem("firstCalculator.done", "1");
        dispatch(new ReplaceAction([new Chat.Calculator.FormSubmitMessage()]));
    }, [dispatch]);

    return (
        <div className="calc-submit">
            <button
                type="button"
                className="btn calculator-submit"
                id="calculator-submit"
                onClick={handleClick}
                disabled={currentAmount <= 0}>
                <IconCard />
                <span>Get cash!</span>
            </button>
            <DebtInfo />
        </div>
    );
};
