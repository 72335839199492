export const Envelop = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon_envelop"
    >
        <mask
            id="mask0_173_23429"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <rect
                width="24"
                height="24"
                fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_173_23429)">
            <path
                d="M4.3 19.5001C3.8 19.5001 3.375 19.3251 3.025 18.9751C2.675 18.6251 2.5 18.2001 2.5 17.7001V7.50002C2.5 7.00002 2.675 6.65017 3.025 6.30017C3.375 5.95017 3.8 6.00002 4.3 6.00002H19.7C20.2 6.00002 20.5 6.00012 20.975 6.30007C21.325 6.65007 21.5 7.00007 21.5 7.50007V17.7001C21.5 18.2001 21.325 18.6251 20.975 18.9751C20.625 19.3251 20.2 19.5001 19.7 19.5001H4.3ZM12 14.0001L4 9.50012V17.7001C4 17.7834 4.02933 17.8544 4.088 17.9131C4.146 17.9711 4.21667 18.0001 4.3 18.0001H19.7C19.7833 18.0001 19.8543 17.9711 19.913 17.9131C19.971 17.8544 20 17.7834 20 17.7001V9.50012L12 14.0001ZM12 12.0001L20 7.50007H4L12 12.0001ZM4 9.50012V7.50007V17.7001C4 17.7834 4.02933 17.8544 4.088 17.9131C4.146 17.9711 4.21667 18.0001 4.3 18.0001H4V17.7001V9.50012Z"
                fill="white"
                className="icon-fill"
            />
        </g>
    </svg>
);
