import { LocalizedRoute } from "app/locale";
import * as React from "react";
import { Page } from "./Page";

export const Route: () => React.ReactElement = () => {
    return (<LocalizedRoute
        key="contacts"
        path="/contacts"
        exact
        component={Page} />);
};
