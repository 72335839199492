import { FacebookIcon } from "modules/components/Icons/FacebookIcon";
import { InstagramIcon } from "modules/components/Icons/InstagramIcon";
import { TelegramIcon } from "modules/components/Icons/TelegramIcon";

import "./styles.scss";

export const Layout = () => {
    return (
        <div className="social-list">
            <a
                href="https://www.instagram.com/lovilave.ukraine/?hl=ru"
                className="button button_instagram"
                target="_blank">
                <InstagramIcon />
                <span />
            </a>
            <a
                href="https://www.facebook.com/LoviLave-106735147784134/?modal=admin_todo_tour"
                className="button button_facebook"
                target="_blank">
                <FacebookIcon />
                <span />
            </a>
            <a
                href="https://t.me/lovilaveua"
                className="button button_telegram"
                target="_blank">
                <TelegramIcon />
                <span />
            </a>
        </div>
    );
};

Layout.displayName = "Social.Layout";
