import dayjs from "dayjs";
import * as Chat from "modules/chat";
import { DeleteAction, ReplaceAction } from "modules/chat";
import * as Client from "modules/client";
import { IconPencil } from "modules/components/Icons/IconPencil";
import * as React from "react";
import "./summary-message.scss";

export interface ItemData {
    name: string;
    value: string;
    label: string;
}

export interface ItemProps extends ItemData {
    onChange?: (key: string) => void;
}

export const Item: React.FC<React.PropsWithChildren<ItemProps>> = (
    { name, value, label, onChange },
) => {
    if (["passport_date_issue", "id_card_issue_date"].includes(name)) {
        value = dayjs(value).format("DD.MM.YYYY");
    }

    const id = name === "last_name" ? "last-name-checkpoint" : undefined;

    return (
        <div
            className="form-group"
            aria-label={name}>
            <span className="label">{label}</span>
            <div className="input-group">
                <span
                    className="value"
                    id={id}>
                    {value}
                </span>
                {onChange && <ButtonEdit
                    name={name}
                    onChange={onChange} />}
            </div>
        </div>
    );
};

export type ButtonEditProps = Pick<Required<ItemProps>, "name" | "onChange">;
export const ButtonEdit: React.FC<React.PropsWithChildren<ButtonEditProps>> = ({ name, onChange }) => {
    return (
        <button
            type="button"
            className="btn btn_edit"
            onClick={React.useCallback(() => onChange(name), [])}>
            <IconPencil />
        </button>
    );
};

export interface MessageProps {
    title: string;
    items: Array<ItemData>;
    onChange?: (name: string) => void;
}

export const Message = Client.withState<
    MessageProps,
    { amount: number; term: number; totalAmount: number; }
>(
    ({ title, items, onChange, amount, term, totalAmount }) => {
        const dispatch = Chat.useDispatchContext();

        const handleChange = React.useCallback(() => {
            dispatch([
                new DeleteAction({
                    types: ["calculator:form", "reg:update-term"],
                }),
                new DeleteAction(),
                new ReplaceAction([
                    new Chat.TextMessage("Change amount and term", "bot"),
                    new Chat.Calculator.EditLoadingMessage(),
                ]),
            ]);
        }, [dispatch]);

        return (
            <div className="message bot-message user-data total full-sized-mobile">
                <div className="wrap">
                    <div className="animated">
                        <form className="data-panel">
                            <div className="panel-heading">
                                <h4>{title}</h4>
                            </div>
                            <div className="panel-body">
                                <div className="half">
                                    <span className="transparent">
                                        Personal data
                                    </span>
                                    {items.map(item => (
                                        <Item
                                            {...item}
                                            key={item.name}
                                            onChange={onChange}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="panel-footer">
                                <Item
                                    name="amount"
                                    label="Loan amount"
                                    value={amount.toString()}
                                    onChange={handleChange}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    },
    state =>
        state.type === "user" && {
            term: state.request.term,
            amount: state.request.amount,
            totalAmount: state.request.initDebtSum,
        },
);
