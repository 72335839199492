/* eslint-disable no-return-await */
import { AxiosInstance } from "axios";

type NotificationTypes = "info" | "warning" | "danger";

export type NotificationResponse = {
    title: string;
    content: string;
    type: NotificationTypes;
};

export const Api = (endpoint: AxiosInstance) => {
    return {
        get: async (): Promise<NotificationResponse[]> => {
            return await endpoint
                .get("/v3/mobile/alert/list")
                .then(({ data }): NotificationResponse[] => {
                    return data.list;
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        return Promise.resolve([]);
                    }
                    return Promise.reject(error);
                });
        },
    } as const;
};
