import { LocalizedLink } from "app/locale";
import * as Footer from "modules/components/footer";
import * as Header from "modules/components/header";
import { Logo } from "modules/components/header/Logo";
import { IconHamburger } from "modules/components/Icons/IconHamburger";
import * as Main from "modules/components/main";
import * as React from "react";
import { useHistory } from "react-router-dom";
import "./styles.scss";

type LayoutContainerProps = {
    isInternal?: boolean;
};

export const LayoutContainer: React.FC<React.PropsWithChildren<LayoutContainerProps>> = ({ isInternal, children }) => {
    const history = useHistory();
    const handleClick= React.useCallback(() => {
        history.push("/nav");
    }, [history]);
    return (
        <>
            <Header.Layout logo={<Logo type={"color"} />}>
                {isInternal
                    ? (
                        <i
                            className="icon icon_arrow-back"
                            onClick={handleClick} />
                    )
                    : (
                        <LocalizedLink
                            to={"/nav"}
                            className={"menu-hamburger"}>
                            <IconHamburger />
                        </LocalizedLink>
                    )}
            </Header.Layout>
            <Main.Layout>{children}</Main.Layout>
        </>
    );
};
LayoutContainer.displayName = "LayoutContainer";

export default LayoutContainer;
