/* eslint-disable max-lines */
import "./styles.scss";

export const Layout = () => {
    const eyeRightBasic =
        "M276.500000,63.782000C275.287000,66.974000,270.884000,70.344000,265.790000,71.407000C260.696000,72.470000,252.096000,70.538000,247.403000,64.941000C242.710000,59.344000,244.473000,52.414000,246.212000,52.388000C247.951000,52.362000,254.888000,65.232000,276.500000,63.782000Z";
    const eyeRightBlink =
        "M276.500000,63.782000C275.287000,66.974000,272.600997,68.303990,267.300000,68.746000C262.412997,68.981013,254.400130,67.580671,251.363103,65.088621C248.740069,63.631553,247.482343,62.258520,246.737383,61.197537C248.890377,61.171538,261.511894,69.371933,276.500000,63.782000Z";
    const eyeLeftBasic =
        "M244.393000,63.782000C245.606000,66.974000,250.009000,70.344000,255.103000,71.407000C260.197000,72.470000,268.797000,70.538000,273.490000,64.941000C278.183000,59.344000,276.420000,52.414000,274.681000,52.388000C272.942000,52.362000,266.007000,65.232000,244.393000,63.782000Z";
    const eyeLeftBlink =
        "M245.144214,63.782000C245.736224,66.974000,248.760000,67.683000,253.854000,68.746000C258.948000,69.809000,270.452974,67.019057,273.490000,64.941000C277.148016,63.483932,277.772243,61.026003,278.103210,61.207000C276.364210,61.181000,257.650361,69.992924,245.144214,63.782000Z";
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="15 5 220 220"
            className="bot bot_sad">
            <defs>
                <radialGradient
                    id="a"
                    cx="0.5"
                    cy="0.5"
                    r="0.5"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#fff" />
                    <stop
                        offset="0.695"
                        stopColor="#fff" />
                    <stop
                        offset="1"
                        stopColor="#f5f5f5" />
                </radialGradient>
                <filter
                    id="b"
                    x="13.602"
                    y="10.175"
                    width="221.181"
                    height="221.181"
                    filterUnits="userSpaceOnUse">
                    <feOffset dy="4" />
                    <feGaussianBlur
                        stdDeviation="15"
                        result="c" />
                    <feFlood
                        floodColor="#0081e0"
                        floodOpacity="0.302" />
                    <feComposite
                        operator="in"
                        in2="c" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <linearGradient
                    id="d"
                    x1="0.548"
                    y1="0.871"
                    x2="0.1"
                    y2="0.036"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#0b5b90" />
                    <stop
                        offset="0.211"
                        stopColor="#65c4ff" />
                    <stop
                        offset="0.755"
                        stopColor="#fff" />
                    <stop
                        offset="1"
                        stopColor="#fff" />
                </linearGradient>
                <filter
                    id="e"
                    x="0"
                    y="1.493"
                    width="152.044"
                    height="158.615"
                    filterUnits="userSpaceOnUse">
                    <feOffset dy="3" />
                    <feGaussianBlur
                        stdDeviation="10"
                        result="f" />
                    <feFlood
                        floodColor="#37a6f7"
                        floodOpacity="0.243" />
                    <feComposite
                        operator="in"
                        in2="f" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <radialGradient
                    id="g"
                    cx="0.36"
                    cy="0.591"
                    r="0.402"
                    gradientTransform="matrix(-0.457, -0.889, 2.349, -1.208, -1.185, 1.707)"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#0166ba" />
                    <stop
                        offset="0.3"
                        stopColor="#2795f4" />
                    <stop
                        offset="1"
                        stopColor="#93d5ff" />
                </radialGradient>
                <linearGradient
                    id="h"
                    x1="0.548"
                    y1="0.129"
                    x2="0.1"
                    y2="0.964"
                    xlinkHref="#d" />
                <filter
                    id="i"
                    x="97.421"
                    y="1.493"
                    width="152.043"
                    height="158.615"
                    filterUnits="userSpaceOnUse">
                    <feOffset dy="3" />
                    <feGaussianBlur
                        stdDeviation="10"
                        result="j" />
                    <feFlood
                        floodColor="#37a6f7"
                        floodOpacity="0.243" />
                    <feComposite
                        operator="in"
                        in2="j" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <radialGradient
                    id="k"
                    cx="0.36"
                    cy="0.409"
                    r="0.402"
                    gradientTransform="matrix(-0.457, 0.889, 2.349, 1.208, -0.757, -0.487)"
                    xlinkHref="#g" />
                <radialGradient
                    id="l"
                    cx="0.5"
                    cy="0.533"
                    r="0.5"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#fff" />
                    <stop
                        offset="0.72"
                        stopColor="#fff" />
                    <stop
                        offset="1"
                        stopColor="#bce7ff" />
                </radialGradient>
                <filter
                    id="m"
                    x="4.602"
                    y="0"
                    width="239.181"
                    height="239.355"
                    filterUnits="userSpaceOnUse">
                    <feOffset dy="3" />
                    <feGaussianBlur
                        stdDeviation="18"
                        result="n" />
                    <feFlood
                        floodColor="#1098ff"
                        floodOpacity="0.098" />
                    <feComposite
                        operator="in"
                        in2="n" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <radialGradient
                    id="o"
                    cx="0.5"
                    cy="0.624"
                    r="0.506"
                    gradientTransform="translate(0.081 -0.069) rotate(8.622)"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#fff" />
                    <stop
                        offset="0.578"
                        stopColor="#fff"
                        stopOpacity="0" />
                    <stop
                        offset="1"
                        stopColor="#fff"
                        stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="p"
                    x1="0.485"
                    y1="0.266"
                    x2="0.809"
                    y2="1.019"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#f5fcff" />
                    <stop
                        offset="1"
                        stopColor="#ccefff" />
                </linearGradient>
                <filter
                    id="q"
                    x="41.851"
                    y="110.194"
                    width="48.526"
                    height="69.238"
                    filterUnits="userSpaceOnUse">
                    <feOffset
                        dx="2"
                        dy="2" />
                    <feGaussianBlur
                        stdDeviation="2"
                        result="r" />
                    <feFlood
                        floodColor="#1e91f4"
                        floodOpacity="0.447" />
                    <feComposite
                        operator="in"
                        in2="r" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <radialGradient
                    id="s"
                    cx="0.411"
                    cy="0.229"
                    r="0.714"
                    gradientTransform="matrix(-0.784, 0.621, -0.22, -0.278, 0.784, 0.037)"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#72d9ff" />
                    <stop
                        offset="0.587"
                        stopColor="#009dff" />
                    <stop
                        offset="0.852"
                        stopColor="#0079dc" />
                    <stop
                        offset="1"
                        stopColor="#006cc9" />
                </radialGradient>
                <linearGradient
                    id="t"
                    x1="0.904"
                    y1="0.006"
                    x2="0.135"
                    y2="1.003"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#fff" />
                    <stop
                        offset="0.32"
                        stopColor="#f8fcff" />
                    <stop
                        offset="1"
                        stopColor="#006e9f" />
                </linearGradient>
                <filter
                    id="v"
                    x="157.603"
                    y="110.195"
                    width="48.526"
                    height="69.238"
                    filterUnits="userSpaceOnUse">
                    <feOffset
                        dx="-2"
                        dy="2" />
                    <feGaussianBlur
                        stdDeviation="2"
                        result="w" />
                    <feFlood
                        floodColor="#1e91f4"
                        floodOpacity="0.447" />
                    <feComposite
                        operator="in"
                        in2="w" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <radialGradient
                    id="x"
                    cx="0.411"
                    cy="0.771"
                    r="0.714"
                    gradientTransform="matrix(-0.784, -0.621, -0.22, 0.278, 0.903, 0.812)"
                    xlinkHref="#s" />
                <linearGradient
                    id="y"
                    x1="0.904"
                    y1="0.994"
                    x2="0.135"
                    y2="-0.003"
                    xlinkHref="#t" />
                <linearGradient
                    id="z"
                    x1="0.5"
                    x2="0.5"
                    y2="1"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#9dd2ff" />
                    <stop
                        offset="1"
                        stopColor="#00a8ff" />
                </linearGradient>
                <linearGradient
                    id="aa"
                    x1="0.5"
                    x2="0.5"
                    y2="0.918"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#f2f9ff" />
                    <stop
                        offset="0.155"
                        stopColor="#71c1ff" />
                    <stop
                        offset="0.649"
                        stopColor="#6cc6ff" />
                    <stop
                        offset="1"
                        stopColor="#d8f1ff" />
                </linearGradient>
                <radialGradient
                    id="ab"
                    cx="0.5"
                    cy="0.5"
                    r="0.986"
                    gradientTransform="translate(0.182 0.17) scale(0.637 0.66)"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#21bbf4" />
                    <stop
                        offset="0.842"
                        stopColor="#58b2ff" />
                    <stop
                        offset="1"
                        stopColor="#0065bc" />
                </radialGradient>
                <linearGradient
                    id="ae"
                    x1="0.5"
                    x2="0.5"
                    y2="1"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#d5f0ff"
                        stopOpacity="0.867" />
                    <stop
                        offset="1"
                        stopColor="#739eff"
                        stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="af"
                    cx="0.423"
                    cy="0.304"
                    r="0.577"
                    gradientTransform="matrix(1, 0, 0, 1.206, 0, -0.063)"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#fff" />
                    <stop
                        offset="0.72"
                        stopColor="#fff"
                        stopOpacity="0" />
                    <stop
                        offset="1"
                        stopColor="#fff"
                        stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="ag"
                    x1="0.235"
                    y1="0.977"
                    x2="0.645"
                    y2="0.09"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#fff"
                        stopOpacity="0" />
                    <stop
                        offset="0.256"
                        stopColor="#fff"
                        stopOpacity="0.725" />
                    <stop
                        offset="1"
                        stopColor="#fff"
                        stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="ah"
                    x1="0.42"
                    x2="0.413"
                    y2="1"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#33c0ff" />
                    <stop
                        offset="0.695"
                        stopColor="#0d69af" />
                    <stop
                        offset="1"
                        stopColor="#004c85" />
                </linearGradient>
                <filter
                    id="ai"
                    x="110.767"
                    y="134.203"
                    width="26.792"
                    height="16.862"
                    filterUnits="userSpaceOnUse">
                    <feOffset />
                    <feGaussianBlur
                        stdDeviation="1"
                        result="aj" />
                    <feFlood
                        floodColor="#02a7ff"
                        floodOpacity="0.776" />
                    <feComposite
                        operator="in"
                        in2="aj" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter
                    id="ak"
                    x="74.051"
                    y="97.637"
                    width="44.109"
                    height="31.299"
                    filterUnits="userSpaceOnUse">
                    <feOffset />
                    <feGaussianBlur
                        stdDeviation="2"
                        result="al" />
                    <feFlood floodColor="#fff" />
                    <feComposite
                        operator="in"
                        in2="al" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter
                    id="am"
                    x="130.051"
                    y="97.637"
                    width="44.109"
                    height="31.299"
                    filterUnits="userSpaceOnUse">
                    <feOffset />
                    <feGaussianBlur
                        stdDeviation="2"
                        result="an" />
                    <feFlood floodColor="#fff" />
                    <feComposite
                        operator="in"
                        in2="an" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <radialGradient
                    id="gradient"
                    cx="0.5141600166559219"
                    cy="0.7136760442256926"
                    r="0.518"
                    spreadMethod="pad"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="rgb(3,75,129)" />
                    <stop
                        offset="0.99"
                        stopColor="rgb(3,75,129)" />
                </radialGradient>
                <radialGradient
                    id="gradient2"
                    cx="0.5141600166559219"
                    cy="0.7136760442256926"
                    r="0.518"
                    spreadMethod="pad"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="rgb(181,235,255)" />
                    <stop
                        offset="0.55"
                        stopColor="rgb(0,157,255)" />
                    <stop
                        offset="0.79"
                        stopColor="rgba(17,95,154,0.87)" />
                    <stop
                        offset="1"
                        stopColor="rgba(3,75,129,0)" />
                </radialGradient>
            </defs>
            <g transform="translate(28.097 31.2)">
                <g transform="translate(-1.3 0)">
                    <g
                        transform="matrix(1, 0, 0, 1, -26.8, -31.2)"
                        filter="url(#b)">
                        <circle
                            cx="65.59"
                            cy="65.59"
                            r="65.59"
                            transform="translate(58.6 51.17)"
                            fill="url(#a)" />
                    </g>
                    <g transform="translate(-0.195)">
                        <g
                            id="ear-left"
                            transform="matrix(0.90632817873245 -0.42262777054462 0.42262777054462 0.90632817873245 -24.99363846739429 38.70325666771588)">
                            <g
                                transform="matrix(0.9, 0.44, -0.44, 0.9, 29.79, -65.37)"
                                filter="url(#e)">
                                <path
                                    d="M29.242,7.414c-6.255,2.492-14.283,17.42-14.283,17.42s-4.507,23.632-1.122,34.345c5.908,18.7,81.113-.526,77.7-4.388C52.522,24.366,32.087,6.2,29.242,7.414Z"
                                    transform="translate(17.22 103.26) rotate(-59)"
                                    fill="url(#d)"
                                />
                            </g>
                            <path
                                d="M27.193,7.736C18.9,12.044,4.326,33.243,15.124,62.01c.422-.405-2.785-7.2.3-20.528S36.143,3.515,27.193,7.736Z"
                                transform="matrix(0.839, -0.545, 0.545, 0.839, 0.491, 34.727)"
                                fill="url(#g)"
                            />
                            <path
                                d="M4.71-.345c5.807,6.124-1.839,28.474,16,52.386"
                                transform="translate(24.887 25.488)"
                                fill="none"
                                stroke="#d8edff"
                                strokeLinecap="round"
                                strokeWidth="1" />
                        </g>
                        <g
                            id="ear-right"
                            transform="matrix(-0.90632817873245 -0.42262777054462 0.42262777054462 -0.90632817873245 172.95815764689314 109.27374032720712)">
                            <g
                                transform="matrix(-0.9, 0.44, -0.44, -0.9, 240.1, 40.53)"
                                filter="url(#i)">
                                <path
                                    d="M16.665,59.811c-6.255-2.492-14.283-17.42-14.283-17.42S-2.125,18.759,1.26,8.046c5.908-18.7,81.112.526,77.7,4.388C39.946,42.859,19.51,61.028,16.665,59.811Z"
                                    transform="translate(168.15 127.11) rotate(-121)"
                                    fill="url(#h)"
                                />
                            </g>
                            <path
                                d="M15.905,54.274C7.615,49.966-6.962,28.767,3.836,0c.422.405-2.785,7.2.3,20.528S24.855,58.5,15.905,54.274Z"
                                transform="matrix(0.839, 0.545, -0.545, 0.839, 29.821, 3.94)"
                                fill="url(#k)"
                            />
                            <path
                                d="M0,52.386C5.807,46.261-1.839,23.911,16,0"
                                transform="translate(15.688 6.997)"
                                fill="none"
                                stroke="#d8edff"
                                strokeLinecap="round"
                                strokeWidth="1" />
                        </g>
                    </g>
                    <g
                        transform="matrix(1, 0, 0, 1, -26.8, -31.2)"
                        filter="url(#m)">
                        <path
                            d="M65.762-.174A65.677,65.677,0,1,1,0,65.59,65.593,65.593,0,0,1,65.762-.174Z"
                            transform="translate(58.6 51.17)"
                            fill="url(#l)" />
                    </g>
                    <path
                        d="M18.528,64.262c2.593-2.551-5.22-6.334-2.093-8.348,3.269-1.848,9.552,12.506,36.811,12.8"
                        transform="translate(44.191 76.738)"
                        fill="none"
                        stroke="#2fb6ef"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                        opacity="0.647"
                    />
                    <path
                        d="M50.974,64.262c-2.593-2.551,5.22-6.334,2.093-8.348-3.269-1.849-9.552,12.507-36.811,12.8"
                        transform="translate(81.191 76.738)"
                        fill="none"
                        stroke="#2fb6ef"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                        opacity="0.647"
                    />
                    <path
                        d="M46,6.409c13.961-.105,40.227-8.8,40.227-3S69.071,19.053,46,19.337,2.173,9.21,2.173,3.411,32.039,6.514,46,6.409Z"
                        transform="translate(53.805 133.302)"
                        opacity="0.996"
                        fill="url(#o)"
                    />
                </g>
                <g transform="translate(-0.301 0)">
                    <g transform="translate(17.358 80.884)">
                        <g
                            transform="matrix(1, 0, 0, 1, -45.15, -112.08)"
                            filter="url(#q)">
                            <path
                                d="M13.585,2.386C10.368,6.912,7.348,24.392,7.251,33.252a66.132,66.132,0,0,0,5,24.836c-.081.182-.1,1.884-1.589-.082C5.817,51.64-.848,34.24-.245,19.942S11.489,1.431,13.585,2.386Z"
                                transform="translate(45.15 117.89) rotate(-25)"
                                fill="url(#p)"
                            />
                        </g>
                        <path
                            d="M10.36-5.553C11.792-4.232,6.012,6.214,4.641,15.1a58.454,58.454,0,0,0-.678,14.34c.045.124.179,1.081-.83-.111C-.282,24.523-1.766,10.716.458,1.872,2.692-7.017,8.927-6.874,10.36-5.553Z"
                            transform="translate(3.347 13.558) rotate(-25)"
                            fill="url(#s)"
                        />
                        <path
                            d="M8.5-5.264C10.2-4.372,3.215-3.17,1.839,5.4S.482,23.711,3.174,28.953C-1.9,19.857.686,3.938.741,3.68,2.517-4.77,6.809-6.156,8.5-5.264Z"
                            transform="translate(3.347 13.557) rotate(-25)"
                            opacity="0.485"
                            fill="url(#t)"
                        />
                    </g>
                    <g transform="translate(137.807 82.995)">
                        <g
                            transform="matrix(1, 0, 0, 1, -165.6, -114.19)"
                            filter="url(#v)">
                            <path
                                d="M13.868,56.56c-3.217-4.526-6.236-22.006-6.334-30.866a66.133,66.133,0,0,1,5-24.836c-.081-.182-.1-1.884-1.589.082C6.1,7.305-.565,24.706.038,39S11.773,57.515,13.868,56.56Z"
                                transform="translate(178.17 171.43) rotate(-155)"
                                fill="url(#p)"
                            />
                        </g>
                        <path
                            d="M10.956,35.479c1.433-1.321-4.348-11.767-5.719-20.657A58.455,58.455,0,0,1,4.56.481C4.6.357,4.739-.6,3.73.592.314,5.4-1.169,19.21,1.054,28.054,3.288,36.943,9.524,36.8,10.956,35.479Z"
                            transform="translate(21.769 38.822) rotate(-155)"
                            fill="url(#x)"
                        />
                        <path
                            d="M8.471,34.217c1.7-.892-5.29-2.093-6.666-10.667S.448,5.242,3.14,0C-1.934,9.1.652,25.015.707,25.273,2.484,33.723,6.775,35.109,8.471,34.217Z"
                            transform="translate(21.609 37.673) rotate(-155)"
                            opacity="0.485"
                            fill="url(#y)"
                        />
                    </g>
                </g>
                <g
                    transform="translate(27.825 7.566)"
                    opacity="0.997">
                    <g
                        transform="translate(0 15.021)"
                        opacity="0.518">
                        <path
                            d="M15.507,73c0-16.077,15.023-60.538,64.73-60.538S146.75,57.65,146.75,73s-4.478,26.032-22.618,26.032-30.19-11.555-43.016-11.2-23.672,11.2-42.862,11.2S15.507,89.078,15.507,73Z"
                            transform="translate(-12.795 -13.17)"
                            opacity="0.395"
                            fill="url(#z)"
                        />
                        <path
                            d="M15.612,71.844c0-15.764,15.418-59.271,65.5-59.271s65.447,44.751,65.447,59.8-5.291,25.979-23.431,25.979-29.19-11.33-42.016-10.983S59.444,98.355,40.253,98.355,15.612,87.609,15.612,71.844Z"
                            transform="translate(-12.795 -13.17)"
                            fill="url(#aa)"
                        />
                    </g>
                    <g transform="translate(5.238 15.426)">
                        <path
                            d="M14.475,70.633c0-15.431,13.425-56.051,62.316-56.051S139.108,55.9,139.108,70.633s-7,25.949-23.961,25.949-24.879-9.772-38.355-9.772-19.331,9.772-37.276,9.772S14.475,86.064,14.475,70.633Z"
                            transform="translate(-13.614 -13.575)"
                            fill="url(#ab)"
                        />
                        <path
                            d="M14.475000,69.335925C14.475000,55.241859,31.104000,14.582000,74.475000,14.582000C117.846000,14.582000,134.475000,55.211440,134.475000,69.335925C134.475000,83.460410,124.966000,92.595189,110.066000,95.270020C95.166000,97.944851,87.458000,85.139530,74.475000,85.139530C61.492000,85.139530,57.409000,96.155208,42.692000,95.270020C27.975000,94.384832,14.475000,83.435061,14.475000,69.335925Z"
                            transform="matrix(1 0 0 1 -11.31400000000000 -13.57400000000000)"
                            fill="url(#gradient)"
                        />
                        <path
                            id="forhead"
                            d="M14.475000,67.309390C14.475000,53.736969,31.104000,14.582000,74.475000,14.582000C117.846000,14.582000,134.475000,53.707676,134.475000,67.309390C134.475000,80.911103,124.966000,89.707790,110.066000,92.283620C95.166000,94.859451,87.458000,82.528077,74.475000,82.528077C61.492000,82.528077,57.409000,93.136046,42.692000,92.283620C27.975000,91.431194,14.475000,80.886693,14.475000,67.309390Z"
                            transform="matrix(1 0 0 1 -11.31300000000000 -10.57400000000001)"
                            fill="url(#gradient2)"
                        />
                    </g>
                    <path
                        d="M39.459.039c22.962,0,37.029,10.921,40.676,18.525,3.7,8.826-15.095,16.249-40.676,16.406-22.044.136-41.6-8.021-39.923-16.642C1.675,10.215,18.38.039,39.459.039Z"
                        transform="translate(28.941 19.462)"
                        fill="url(#ae)"
                    />
                    <path
                        d="M38.051,12.861A15.562,15.562,0,0,1,40,20.5c0,11.322-8.054,20.016-19.1,20.016-7.566,0-15.052-3.822-18.589-10.44.19.069,6.753-7.343,16.276-11.343A104.37,104.37,0,0,1,38.051,12.861Z"
                        transform="translate(23.451 -0.223)"
                        opacity="0.782"
                        fill="url(#af)"
                    />
                    <path
                        d="M27.294,79.751c-9.2-5.539-8.144-30.779,6.442-51.644,4.456-6.069,18.351-14.552,18.329-13.313C42.788,33.181,42.026,54.666,50.4,85.1,42.237,88.034,29.211,81.507,27.294,79.751Z"
                        transform="translate(-6.339 9.348)"
                        opacity="0.546"
                        fill="url(#ag)"
                    />
                </g>
                <g transform="translate(55.505 62.334)">
                    <g
                        id="mouth"
                        transform="matrix(-1 0 -0 -1 51.90400000000000 60.91500000000000)">
                        <g
                            transform="matrix(-1, 0, 0, -1, 135.51, 154.45)"
                            filter="url(#ai)">
                            <path
                                d="M156.433,118.083c-3.509-2.834-3.615,1.364-10.195,1.364-6.975,0-6.684-4.155-10.232-1.643-1.506.932,4.778,10.055,10.232,10.055S157.548,118.9,156.433,118.083Z"
                                transform="translate(270.34 265.06) rotate(180)"
                                fill="url(#ah)"
                            />
                        </g>
                        <path
                            d="M139.481,133.461a10.04,10.04,0,0,0,6.759,2.869,10.33,10.33,0,0,0,6.941-2.869C149.195,135.579,144.462,136.877,139.481,133.461Z"
                            transform="translate(-134.831 -126.724)"
                            fill="#8dd6ff"
                            opacity="0.683"
                        />
                    </g>
                    <g transform="translate(-1 4)">
                        <g
                            transform="matrix(1, 0, 0, 1, -82.6, -97.53)"
                            filter="url(#ak)">
                            <path
                                id="eye-left"
                                d="M244.393000,63.782000C245.606000,66.974000,250.009000,70.344000,255.103000,71.407000C260.197000,72.470000,268.797000,70.538000,273.490000,64.941000C278.183000,59.344000,276.420000,52.414000,274.681000,52.388000C272.942000,52.362000,266.007000,65.232000,244.393000,63.782000Z"
                                transform="matrix(1 0 0 1 -164.34000000000003 51.46245368636365)"
                                opacity="1"
                                fill="#fff"
                            />
                        </g>
                        <g
                            transform="matrix(1, 0, 0, 1, -82.6, -97.53)"
                            filter="url(#am)">
                            <path
                                id="eye-right"
                                d="M276.500000,63.782000C275.287000,66.974000,270.884000,70.344000,265.790000,71.407000C260.696000,72.470000,252.096000,70.538000,247.403000,64.941000C242.710000,59.344000,244.473000,52.414000,246.212000,52.388000C247.951000,52.362000,254.888000,65.232000,276.500000,63.782000Z"
                                transform="matrix(1 0 0 1 -108.34000000000003 51.91661874545454)"
                                opacity="1"
                                fill="#fff"
                            />
                        </g>
                    </g>
                </g>
            </g>
            <animate
                xlinkHref="#eye-right"
                attributeName="d"
                dur="2.5s"
                repeatCount="indefinite"
                keyTimes="0; .05; .5; .7; 1"
                values={`${eyeRightBasic}; ${eyeRightBasic}; ${eyeRightBlink}; ${eyeRightBasic}; ${eyeRightBasic}`}
            />
            <animate
                xlinkHref="#eye-left"
                attributeName="d"
                dur="2.5s"
                repeatCount="indefinite"
                keyTimes="0; .05; .5; .7; 1"
                values={`${eyeLeftBasic}; ${eyeLeftBasic}; ${eyeLeftBlink}; ${eyeLeftBasic}; ${eyeLeftBasic}`}
            />
        </svg>
    );
};

Layout.displayName = "BotSad.Layout";
