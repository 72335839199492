export const IconDiscount = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon_discount">
        <mask
            id="mask0_1333_451"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24">
            <rect
                width="24"
                height="24"
                fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1333_451)">
            <path
                d="M8.14995 21.7504L6.69995 19.3004L3.94995 18.7004C3.69995 18.6504 3.49995 18.5212 3.34995 18.3129C3.19995 18.1045 3.14162 17.8754 3.17495 17.6254L3.44995 14.8004L1.57495 12.6504C1.40828 12.467 1.32495 12.2504 1.32495 12.0004C1.32495 11.7504 1.40828 11.5337 1.57495 11.3504L3.44995 9.20036L3.17495 6.37536C3.14162 6.12536 3.19995 5.8962 3.34995 5.68786C3.49995 5.47953 3.69995 5.35036 3.94995 5.30036L6.69995 4.70036L8.14995 2.25036C8.28328 2.0337 8.46662 1.88786 8.69995 1.81286C8.93328 1.73786 9.16662 1.75036 9.39995 1.85036L12 2.95036L14.6 1.85036C14.8333 1.75036 15.0666 1.73786 15.3 1.81286C15.5333 1.88786 15.7166 2.0337 15.85 2.25036L17.3 4.70036L20.05 5.30036C20.3 5.35036 20.5 5.47953 20.65 5.68786C20.8 5.8962 20.8583 6.12536 20.825 6.37536L20.55 9.20036L22.425 11.3504C22.5916 11.5337 22.675 11.7504 22.675 12.0004C22.675 12.2504 22.5916 12.467 22.425 12.6504L20.55 14.8004L20.825 17.6254C20.8583 17.8754 20.8 18.1045 20.65 18.3129C20.5 18.5212 20.3 18.6504 20.05 18.7004L17.3 19.3004L15.85 21.7504C15.7166 21.967 15.5333 22.1129 15.3 22.1879C15.0666 22.2629 14.8333 22.2504 14.6 22.1504L12 21.0504L9.39995 22.1504C9.16662 22.2504 8.93328 22.2629 8.69995 22.1879C8.46662 22.1129 8.28328 21.967 8.14995 21.7504ZM9.44995 19.9504L12 18.8504L14.6 19.9504L16 17.5504L18.75 16.9004L18.5 14.1004L20.35 12.0004L18.5 9.85036L18.75 7.05036L16 6.45036L14.55 4.05036L12 5.15036L9.39995 4.05036L7.99995 6.45036L5.24995 7.05036L5.49995 9.85036L3.64995 12.0004L5.49995 14.1004L5.24995 16.9504L7.99995 17.5504L9.44995 19.9504Z"
                fill="url(#paint0_linear_1333_451)"
            />
            <path
                d="M8.14995 21.7504L6.69995 19.3004L3.94995 18.7004C3.69995 18.6504 3.49995 18.5212 3.34995 18.3129C3.19995 18.1045 3.14162 17.8754 3.17495 17.6254L3.44995 14.8004L1.57495 12.6504C1.40828 12.467 1.32495 12.2504 1.32495 12.0004C1.32495 11.7504 1.40828 11.5337 1.57495 11.3504L3.44995 9.20036L3.17495 6.37536C3.14162 6.12536 3.19995 5.8962 3.34995 5.68786C3.49995 5.47953 3.69995 5.35036 3.94995 5.30036L6.69995 4.70036L8.14995 2.25036C8.28328 2.0337 8.46662 1.88786 8.69995 1.81286C8.93328 1.73786 9.16662 1.75036 9.39995 1.85036L12 2.95036L14.6 1.85036C14.8333 1.75036 15.0666 1.73786 15.3 1.81286C15.5333 1.88786 15.7166 2.0337 15.85 2.25036L17.3 4.70036L20.05 5.30036C20.3 5.35036 20.5 5.47953 20.65 5.68786C20.8 5.8962 20.8583 6.12536 20.825 6.37536L20.55 9.20036L22.425 11.3504C22.5916 11.5337 22.675 11.7504 22.675 12.0004C22.675 12.2504 22.5916 12.467 22.425 12.6504L20.55 14.8004L20.825 17.6254C20.8583 17.8754 20.8 18.1045 20.65 18.3129C20.5 18.5212 20.3 18.6504 20.05 18.7004L17.3 19.3004L15.85 21.7504C15.7166 21.967 15.5333 22.1129 15.3 22.1879C15.0666 22.2629 14.8333 22.2504 14.6 22.1504L12 21.0504L9.39995 22.1504C9.16662 22.2504 8.93328 22.2629 8.69995 22.1879C8.46662 22.1129 8.28328 21.967 8.14995 21.7504ZM9.44995 19.9504L12 18.8504L14.6 19.9504L16 17.5504L18.75 16.9004L18.5 14.1004L20.35 12.0004L18.5 9.85036L18.75 7.05036L16 6.45036L14.55 4.05036L12 5.15036L9.39995 4.05036L7.99995 6.45036L5.24995 7.05036L5.49995 9.85036L3.64995 12.0004L5.49995 14.1004L5.24995 16.9504L7.99995 17.5504L9.44995 19.9504Z"
                fill="url(#paint1_radial_1333_451)"
            />
            <path
                d="M8.4645 8.46477C8.26415 8.66512 8.16398 8.90082 8.16398 9.17188C8.16398 9.44293 8.26415 9.67863 8.4645 9.87898C8.66484 10.0793 8.90054 10.1795 9.1716 10.1795C9.44266 10.1795 9.67836 10.0793 9.87871 9.87898C10.0791 9.67863 10.1792 9.44293 10.1792 9.17188C10.1792 8.90082 10.0791 8.66512 9.87871 8.46477C9.67836 8.26442 9.44266 8.16425 9.1716 8.16425C8.90054 8.16425 8.66484 8.26442 8.4645 8.46477Z"
                fill="url(#paint2_linear_1333_451)"
            />
            <path
                d="M8.4645 8.46477C8.26415 8.66512 8.16398 8.90082 8.16398 9.17188C8.16398 9.44293 8.26415 9.67863 8.4645 9.87898C8.66484 10.0793 8.90054 10.1795 9.1716 10.1795C9.44266 10.1795 9.67836 10.0793 9.87871 9.87898C10.0791 9.67863 10.1792 9.44293 10.1792 9.17188C10.1792 8.90082 10.0791 8.66512 9.87871 8.46477C9.67836 8.26442 9.44266 8.16425 9.1716 8.16425C8.90054 8.16425 8.66484 8.26442 8.4645 8.46477Z"
                fill="url(#paint3_radial_1333_451)"
            />
            <path
                d="M15.5355 15.5362C15.7359 15.3359 15.836 15.1002 15.836 14.8291C15.836 14.558 15.7359 14.3223 15.5355 14.122C15.3352 13.9216 15.0995 13.8215 14.8284 13.8215C14.5573 13.8215 14.3216 13.9216 14.1213 14.122C13.9209 14.3223 13.8208 14.558 13.8208 14.8291C13.8208 15.1002 13.9209 15.3359 14.1213 15.5362C14.3216 15.7366 14.5573 15.8367 14.8284 15.8367C15.0995 15.8367 15.3352 15.7366 15.5355 15.5362Z"
                fill="url(#paint4_linear_1333_451)"
            />
            <path
                d="M15.5355 15.5362C15.7359 15.3359 15.836 15.1002 15.836 14.8291C15.836 14.558 15.7359 14.3223 15.5355 14.122C15.3352 13.9216 15.0995 13.8215 14.8284 13.8215C14.5573 13.8215 14.3216 13.9216 14.1213 14.122C13.9209 14.3223 13.8208 14.558 13.8208 14.8291C13.8208 15.1002 13.9209 15.3359 14.1213 15.5362C14.3216 15.7366 14.5573 15.8367 14.8284 15.8367C15.0995 15.8367 15.3352 15.7366 15.5355 15.5362Z"
                fill="url(#paint5_radial_1333_451)"
            />
            <path
                d="M8.48253 14.1037L11.2752 11.3111C11.281 11.305 11.2869 11.299 11.2929 11.293L14.1213 8.46458C14.3217 8.26424 14.5574 8.16406 14.8284 8.16406C15.0995 8.16406 15.3352 8.26424 15.5355 8.46458C15.7359 8.66493 15.8361 8.90063 15.8361 9.17169C15.8361 9.18128 15.8359 9.19083 15.8357 9.20033C15.8289 9.45954 15.7289 9.68582 15.5355 9.87917C15.5295 9.88515 15.5235 9.89105 15.5175 9.89685L12.7248 12.6895C12.719 12.6956 12.7131 12.7016 12.7071 12.7076L9.87867 15.536C9.67833 15.7364 9.44262 15.8365 9.17157 15.8365C8.90051 15.8365 8.66481 15.7364 8.46446 15.536C8.26411 15.3357 8.16394 15.1 8.16394 14.8289C8.16394 14.8196 8.16406 14.8103 8.1643 14.801C8.17088 14.5415 8.27094 14.315 8.46447 14.1214C8.47046 14.1154 8.47648 14.1096 8.48253 14.1037Z"
                fill="url(#paint6_linear_1333_451)"
            />
            <path
                d="M8.48253 14.1037L11.2752 11.3111C11.281 11.305 11.2869 11.299 11.2929 11.293L14.1213 8.46458C14.3217 8.26424 14.5574 8.16406 14.8284 8.16406C15.0995 8.16406 15.3352 8.26424 15.5355 8.46458C15.7359 8.66493 15.8361 8.90063 15.8361 9.17169C15.8361 9.18128 15.8359 9.19083 15.8357 9.20033C15.8289 9.45954 15.7289 9.68582 15.5355 9.87917C15.5295 9.88515 15.5235 9.89105 15.5175 9.89685L12.7248 12.6895C12.719 12.6956 12.7131 12.7016 12.7071 12.7076L9.87867 15.536C9.67833 15.7364 9.44262 15.8365 9.17157 15.8365C8.90051 15.8365 8.66481 15.7364 8.46446 15.536C8.26411 15.3357 8.16394 15.1 8.16394 14.8289C8.16394 14.8196 8.16406 14.8103 8.1643 14.801C8.17088 14.5415 8.27094 14.315 8.46447 14.1214C8.47046 14.1154 8.47648 14.1096 8.48253 14.1037Z"
                fill="url(#paint7_radial_1333_451)"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_1333_451"
                x1="12"
                y1="1.76465"
                x2="17.76"
                y2="20.4615"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#38B2FF" />
                <stop
                    offset="1"
                    stopColor="#0F85FF" />
            </linearGradient>
            <radialGradient
                id="paint1_radial_1333_451"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(5.97723 1.76465) rotate(88.338) scale(24.8686 9.84511)">
                <stop stopColor="#72D9FF" />
                <stop
                    offset="1"
                    stopColor="#65C4FF"
                    stopOpacity="0" />
            </radialGradient>
            <linearGradient
                id="paint2_linear_1333_451"
                x1="9.87871"
                y1="9.87898"
                x2="8.18496"
                y2="9.00896"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#38B2FF" />
                <stop
                    offset="1"
                    stopColor="#0F85FF" />
            </linearGradient>
            <radialGradient
                id="paint3_radial_1333_451"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(10.2777 9.48004) rotate(-136.594) scale(2.42951 0.92229)">
                <stop stopColor="#72D9FF" />
                <stop
                    offset="1"
                    stopColor="#65C4FF"
                    stopOpacity="0" />
            </radialGradient>
            <linearGradient
                id="paint4_linear_1333_451"
                x1="14.1213"
                y1="14.122"
                x2="15.815"
                y2="14.992"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#38B2FF" />
                <stop
                    offset="1"
                    stopColor="#0F85FF" />
            </linearGradient>
            <radialGradient
                id="paint5_radial_1333_451"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(13.7223 14.5209) rotate(43.4063) scale(2.42951 0.92229)">
                <stop stopColor="#72D9FF" />
                <stop
                    offset="1"
                    stopColor="#65C4FF"
                    stopOpacity="0" />
            </radialGradient>
            <linearGradient
                id="paint6_linear_1333_451"
                x1="12"
                y1="8.16406"
                x2="14.2346"
                y2="15.1186"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#38B2FF" />
                <stop
                    offset="1"
                    stopColor="#0F85FF" />
            </linearGradient>
            <radialGradient
                id="paint7_radial_1333_451"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(9.83574 8.16406) rotate(88.4064) scale(9.32019 3.53796)">
                <stop stopColor="#72D9FF" />
                <stop
                    offset="1"
                    stopColor="#65C4FF"
                    stopOpacity="0" />
            </radialGradient>
        </defs>
    </svg>
);
