import classNames from "classnames";
import * as React from "react";
import { HeaderBack } from "../Icons/HeaderBack";
import "./styles.scss";
import { LogoLink } from "./LogoLink";

export interface LayoutProps {
    onDoubleClick?: () => void;
    className?: classNames.Value;
    logo?: React.ReactElement;
    lang?: React.ReactNode;
    isNavOpen?: boolean;
}

export const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({ children, className, logo }) => {
    return (
        <header className={classNames("header", className)}>
            <HeaderBack />
            <div className="container">
                <LogoLink>{logo}</LogoLink>
                <div className="header-controls">{children}</div>
            </div>
        </header>
    );
};

Layout.displayName = "Header.Layout";
