import { IconClock } from "modules/components/Icons/IconClock";
import * as React from "react";
import { MessageProps } from "./message";
import { WrapMessage } from "./wrap-message";

export interface TimeoutMessageProps extends Exclude<MessageProps, "source"> {
    value: number;
}

const Counter: React.FC<React.PropsWithChildren<{ value: number; }>> = ({ value }) => {
    return (
        <span
            className="counter"
            aria-valuenow={value}>
            {value}
        </span>
    );
};

export const TimeoutMessage: React.FC<
    React.PropsWithChildren<TimeoutMessageProps>
> = ({ value, children, ...props }) => {
    return (
        <WrapMessage
            {...props}
            source="bot"
            className={"timeout"}>
            <IconClock />
            {Counter({ value })}
        </WrapMessage>
    );
};
