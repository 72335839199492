export const Decor = () => (
    <svg
        preserveAspectRatio="none"
        width="100%"
        height="298"
        viewBox="0 0 360 298"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="main__decor">
        <path
            d="M0 298H360V0C286.22 100.532 153.987 170.873 0 182.006V298Z"
            fill="url(#paint0_linear_173_23291)" />
        <defs>
            <linearGradient
                id="paint0_linear_173_23291"
                x1="180"
                y1="298"
                x2="180"
                y2="3.31804e-06"
                gradientUnits="userSpaceOnUse">
                <stop
                    stopColor="white"
                    stopOpacity="0" />
                <stop
                    offset="1"
                    stopColor="white" />
            </linearGradient>
        </defs>
    </svg>
);
