import { formatPhoneNumber } from "modules/utils/format-phone-number";
import * as React from "react";

export const PhoneLink: React.FC<React.PropsWithChildren<{ phoneNumber: string; }>> = ({ phoneNumber, children }) => {
    return (
        <a
            href={`tel:${phoneNumber}`}
            className="phone-link">
            {children}
            &nbsp;
            <span>{formatPhoneNumber(phoneNumber)}</span>
        </a>
    );
};
PhoneLink.displayName = "Contacts.PhoneLink";
