import classNames from "classnames";
import { IconDownload } from "modules/components/Icons/IconDownload";
import * as React from "react";

import "./styles.scss";

interface BtnPdfProps {
    link: string;
    title: string;
    date?: string;
    id?: string;
    className?: string;
}

export const Layout: React.FC<React.PropsWithChildren<BtnPdfProps>> = (props) => {
    return (
        <a
            href={props.link}
            id={props.id}
            className={classNames("btn btn_pdf", props.className)}
            target="_blank"
            rel="noopener">
            <p>
                <span>{props.title}</span>
                <IconDownload />
            </p>
            {props.date && <span>{props.date}</span>}
        </a>
    );
};

Layout.displayName = "BtnPdf.Layout";
