import * as React from "react";
import * as Info from "../Layout";

const Article = React.lazy(
    () => import(/* webpackChunkName: "account-deletion" */ "./Layout"),
);
export const Page: React.FC<React.PropsWithChildren> = () => {
    return (
        <Info.Layout className="with-scroll">
            <Article />
        </Info.Layout>
    );
};
