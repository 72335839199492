import { AxiosInstance, AxiosResponse } from "axios";

export type Method = {
    name: string;
    title: string;
};

export type MethodsResponse = {
    methods: Array<Method>;
};

export type Bank = {
    name: string;
    code: string;
};
export type BanksReponse = {
    banks: Array<Bank>;
};

export type BankAccountSource = "nuban"; // for future expansions
export type BankAccount = {
    accountNumber: string;
    bankCode: string; // deprecated
    bank: {
        code: string;
        name: string;
    };
    source: BankAccountSource;
    isActive: boolean;
    createdAt: string; // Y-m-d H:i:s
};
export type CreateBankAccountResponse = {
    message: string;
    account?: BankAccount;
};
export type BankAccountList = Array<BankAccount>;
export type ListBankAccountsResponse = {
    list: BankAccountList;
    isModifyAllowed: boolean;
};


export const Api = (endpoint: AxiosInstance) => {
    return {
        getMethods: async (): Promise<Array<Method>> => {
            const { data } = await endpoint.get<MethodsResponse, AxiosResponse<MethodsResponse>>(
                "/v3/bank-account/methods",
            );
            return data.methods;
        },
        getBanks: async (): Promise<Array<Bank>> => {
            const { data } = await endpoint.get<BanksReponse, AxiosResponse<BanksReponse>>(
                "/v3/bank-account/banks",
            );
            return data.banks;
        },
        createAccount: async (bankCode: string, accountNumber: string): Promise<CreateBankAccountResponse> => {
            const { data } = await endpoint.post<BanksReponse, AxiosResponse<CreateBankAccountResponse>>(
                "/v3/bank-account/create",
                {
                    BankAccount: {
                        bankCode,
                        accountNumber,
                    },
                },
                {
                    validateStatus: status => [201, 400].includes(status),
                },
            );
            return data;
        },
        activateAccount: async (accountNumber: string): Promise<CreateBankAccountResponse> => {
            const { data } = await endpoint.post<BanksReponse, AxiosResponse<CreateBankAccountResponse>>(
                "/v3/bank-account/activate",
                {
                    BankAccount: {
                        accountNumber,
                    },
                },
                {
                    validateStatus: status => [200, 400].includes(status),
                },
            );
            return data;
        },
        list: async (): Promise<ListBankAccountsResponse> => {
            const { data } = await endpoint.get<ListBankAccountsResponse, AxiosResponse<ListBankAccountsResponse>>(
                "/v3/bank-account/list",
            );
            return data;
        },
    } as const;
};
