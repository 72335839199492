import * as Base from "app/chat/base";
import * as Chat from "modules/chat";
import * as React from "react";
import * as State from "../state";

type PendingMessageProps = State.MessageProps<Chat.DirectDebit.MandateFailedMessage>;

export const MandatePendingMessage: React.FC<React.PropsWithChildren<PendingMessageProps>> = (props) => {
    const dispatch = Chat.useDispatchContext();
    React.useEffect(() => {
        const intervalId = setInterval(
            () => {
                dispatch(new Chat.PushAction([
                    new Chat.DirectDebit.InitMessage(),
                ]));
            },
            5000,
        );
        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <Base.WrapMessage source="bot">
                <p>
                    The DirectDebit mandate was successfully created for account <b>{props.value.accountNumber}</b>.
                </p>
                <p>
                    We are waiting for confirmation from <b>{props.value.bankName}</b>, it may take up to 24 hours.
                </p>
            </Base.WrapMessage>
        </>
    );
};
