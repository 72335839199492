export class WidgetMessage {
    public readonly type = "bank-id:widget-message";

    constructor(
        public readonly publicKey: string,
        public readonly data: object,
        public readonly reauthToken?: string,
    ) {}
}

export class InitMessage {
    public readonly type = "bank-id:init-message";
}

export interface Details {
    institution: {
        name: string;
        bankCode: string;
        type: string;
    };
    name: string;
    accountNumber: string;
    type: string;
    balance: number;
    currency: string;
    bvn: string;
    dob?: string;
    email?: string;
    phone?: string;
    gender?: string;
}

export class LoadMessage {
    public readonly type = "bank-id:load";

    constructor(public readonly token: string) {}
}

export class LinkMessage {
    public readonly type = "bank-id:link";

    public constructor(public readonly url: string | undefined) {}
}

export class ReauthInfoMessage {
    public readonly type = "bank-id:reauth-info-message";
}

export class ReauthButtonMessage {
    public readonly type = "bank-id:reauth-button-message";
}

export class ReauthLoadMessage {
    public readonly type = "bank-id:reauth-load-message";
}

export interface Bank {
    id: string;
    loginUrl: string;
    logoUrl: string;
    memberId: string;
    name: string;
    order: number;
    workable: boolean;
}

export class ConfirmMessage {
    public readonly type = "bank-id:confirm";

    constructor(public readonly bank?: Bank) {}
}

export class InfoMessage {
    public readonly type = "bank-id:info";
}

export type Message =
    | LoadMessage
    | LinkMessage
    | ConfirmMessage
    | InfoMessage
    | WidgetMessage
    | InitMessage
    | ReauthLoadMessage
    | ReauthButtonMessage
    | ReauthInfoMessage;
