import { JSONSchemaEnum } from "modules/client/api";
import * as React from "react";
import * as Base from "../button";
import { ControlProps } from "./control";

export const ButtonControl: React.FC<React.PropsWithChildren<ControlProps<JSONSchemaEnum>>> = (props) => {
    return (
        <div className="btn-container">
            {props.schema.oneOf.map(({ const: value, title }, i) => (
                <Base.Button
                    type="submit"
                    key={i}
                    theme={i === 0 ? "primary" : "secondary"}
                    onClick={React.useCallback(() => props.onChange(value), [])}>
                    {title}
                </Base.Button>
            ))}
        </div>
    );
};
