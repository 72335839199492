import { LocalizedLink } from "app/locale";
import { BotOverlay } from "modules/components/bot/overlay";
import * as Header from "modules/components/header";
import { Logo } from "modules/components/header/Logo";
import { IconCross } from "modules/components/Icons/IconCross";
import { useTheme } from "modules/hooks/use-theme";
import { LinkList } from "./LinkList";
import "./styles.scss";

export const Layout = () => {
    useTheme("nav");
    return (
        <div className="nav-overlay">
            <Header.Layout logo={<Logo type={"white"} />}>
                <LocalizedLink to="/chat">
                    <IconCross />
                </LocalizedLink>
            </Header.Layout>
            <BotOverlay />
            <LinkList />
        </div>
    );
};

Layout.displayName = "Navigation";

export default Layout;
