import * as React from "react";
import * as Info from "../Layout";

const Article = React.lazy(() => import(/* webpackChunkName: "failure" */ "./Layout"));
export const Page: React.FC<React.PropsWithChildren> = () => {
    return (
        <Info.Layout>
            <Article />
        </Info.Layout>
    );
};
