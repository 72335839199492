import * as Client from "modules/client";
import { isRequestOverdueCollection } from "modules/utils/is-request-overdue-collection";

export function getEmailByState(state: Client.State): string {
    if (isRequestOverdueCollection(state)) {
        return "collection@kobogo.ng";
    }
    return "info@kobogo.ng";
}

export type EmailProps = {
    email: string;
};

export const withEmail = (componentType: React.ComponentType<React.PropsWithChildren<EmailProps>>) => {
    return Client.withState<{}, EmailProps>(componentType, state => ({
        email: getEmailByState(state),
    }));
};
