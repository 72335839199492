import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as React from "react";
import { useCallback } from "react";
import * as Base from "../base";
import * as State from "../state";

export const InitDashboardMessage: State.Message<State.MessageProps<Chat.BankAccount.InitDashboardMessage>> =
    (props) => {
        const handleRequest = useCallback(
            async (
                api: Client.Api.Instance,
            ): Promise<boolean> => {
                // eslint-disable-next-line no-return-await
                return await api.user.bankAccount.list()
                    .then(response => response.isModifyAllowed);
            },
            [],
        );

        const handleFinish = useCallback(async (isModifyAllowed: boolean): Promise<Chat.Action | void> => {
            return new Chat.ReplaceAction(
                [
                    isModifyAllowed
                        ? new Chat.BankAccount.InitMessage()
                        : new Chat.RedirectMessage("/dashboard"),
                ],
                props.value,
            );
        }, []);

        return (<Base.DelaySubmitMessage
            delayMs={500}
            value={props.value}
            onRequest={handleRequest}
            onFinish={handleFinish}
        />);
    };
