import * as Chat from "modules/chat";
import * as Client from "modules/client";
import { useCallback } from "react";
import * as Base from "../base";
import * as State from "../state";

type ConfirmEmailSubmitMessageProps = State.MessageProps<Chat.Agreement.AgreementConfirmEmailSubmitMessage>;

export const ConfirmEmailSubmitMessage: State.Message<ConfirmEmailSubmitMessageProps> =
    ({ value }) => {
        const handleRequest = useCallback((api: Client.Api.Instance, email: string) => {
            return api.user.data.patch({ email });
        }, []);
        const handleResponse = useCallback(
            async (response: Promise<string | undefined>) => {
                const failure = await response;
                const action = Array.isArray(failure) && failure.length > 0 && failure[0].text
                    ? [new Chat.Agreement.AgreementConfirmEmailFormMessage(value.record, failure[0].text)]
                    : [new Chat.Agreement.AgreementConfirmRequestMessage()];
                return new Chat.ReplaceAction(action);
            },
            [value],
        );
        return (<Base.DelaySubmitMessage
            value={value.record.value}
            onRequest={handleRequest}
            onFinish={handleResponse} />);
    };
