import escape from "lodash/escape";
import * as React from "react";
import snarkdown from "snarkdown";

export const markdown = (content: string): string => {
    const html = snarkdown(escape(content));
    return html.replace(/:([a-z]+):/, name => `<span class="emoji emoji_${name.slice(1, name.length - 1)}"/>`);
};

export type MarkdownContentType = string | string[];
export interface MarkdownProps {
    content: MarkdownContentType;
}

export const Markdown: React.FC<React.PropsWithChildren<MarkdownProps>> = ({ content }) => {
    if (Array.isArray(content)) {
        return (
            <>
                {content.map((item, i) => (
                    <Markdown
                        content={item}
                        key={i} />
                ))}
            </>
        );
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const __html = React.useMemo<string>(markdown.bind(undefined, content), [content]);
    return <p dangerouslySetInnerHTML={{ __html }} />;
};
