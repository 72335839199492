import { useLocale } from "app/locale";
import axios, { AxiosError } from "axios";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Base from "../base";
import * as State from "../state";

export const ConfirmMessage = Client.withApi<State.MessageProps<Chat.SignUp.ConfirmMessage>>(({ value, api }) => {
    const dispatch = Chat.useDispatchContext();
    const history = useHistory();
    const { t } = useLocale("registration");
    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        api.with({ cancelToken: cancelToken.token })
            .guest.sign.confirm(value.phone, value.token)
            .then((data) => {
                dispatch(new Chat.FlushAction([new Chat.SignUp.CompleteMessage(data.access, data.refresh), new Chat.RedirectMessage("/chat")]));
            })
            .catch((error: AxiosError | any) => {
                if (!error.response) {
                    return Promise.reject(error);
                }
                if (error.response.status === 400) {
                    dispatch(
                        new Chat.ReplaceAction([
                            new Chat.TextMessage(error.response.data.errors.map(({ details }: { details: string; }) => details).join(", ")),
                            new Chat.SignUp.ConfirmFormMessage(value.phone),
                        ]),
                    );
                } else if (error.response.status === 423) {
                    dispatch(
                        new Chat.ReplaceAction([
                            new Chat.TextMessage("The OTP you entered has expired. Please request a new one-time password and try again."),
                            new Chat.TextMessage("One-time passwords are only valid for a limited time to protect your account."),
                            new Chat.SignUp.RequestFormMessage(value.phone),
                        ]),
                    );
                } else {
                    return Promise.reject(error);
                }
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    history.push("/failure");
                    return Promise.reject(error);
                }
            });
        return () => cancelToken.cancel();
    }, [value.phone]);

    return <Base.LoadMessage />;
});
