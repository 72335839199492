import * as React from "react";
import * as Base from "../base";

export const InfoMessage: React.FC<React.PropsWithChildren> = () => {
    return (
        <Base.WrapMessage source="bot">
            <p>
                Hello! I am a robot of Volli!I will issue money in 1 minute
                without documents and calls.
            </p>
            <p style={{ marginTop: 20 }}>
                You only need to answer 7 questions, are you ready?
            </p>
        </Base.WrapMessage>
    );
};
