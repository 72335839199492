import * as State from "app/chat/state";
import * as Chat from "modules/chat";
import * as React from "react";
import * as Base from "../base";

export const CompleteMessage: State.Message<
    State.MessageProps<Chat.Card.CompleteMessage>
> = ({ value }) => {
    return (<Base.WrapMessage source="bot">
        <p>
            Congratulations! Your card {value.card.mask} has been successfully linked to your account. You're now ready
            to take a loan and automated repayments will be made from this card.
        </p>
    </Base.WrapMessage>);
};
