import * as Base from "app/chat/base";
import { useLocale } from "app/locale";
import * as Client from "modules/client";
import * as React from "react";

export const CompleteMessage: React.FC<React.PropsWithChildren> = () => {
    const dispatch = Client.useDispatchContext();
    const { t } = useLocale("registration");

    React.useEffect(() => {
        const id = setTimeout(() => {
            dispatch(new Client.Auth.RefreshAction());
        }, 5000);
        return () => clearTimeout(id);
    }, [dispatch]);
    return <Base.Message source="bot">{t("restructuring-complete")}</Base.Message>;
};
