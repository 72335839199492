import { useMutationObserver, useWindowSize } from "@horat1us/react-hooks";
import { isMobile } from "modules/hooks/is-mobile";

export function useScrollEffect() {
    const [width, height] = useWindowSize();
    const [ref, setRef] = useMutationObserver(() => {
        const bodyHeight = document.documentElement.scrollHeight;
        if (height === undefined || document.body.classList.contains("show-footer")) {
            return;
        }
        if (isMobile(width)) {
            const handleId: ReturnType<typeof setTimeout> | undefined = setTimeout(() => {
                window.scrollTo({
                    top: bodyHeight,
                    behavior: "smooth",
                });
            }, 200);
            return () => clearTimeout(handleId);
        }
        window.scrollTo(0, bodyHeight - (height + (bodyHeight - (ref?.scrollHeight || 0))));
    });
    return setRef;
}
