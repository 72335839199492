import * as Client from "modules/client";
import * as Base from "../base";
import { MessageComponent } from "./message-component";

export const WelcomeMessage: MessageComponent = Client.withApi(({ api }) => {
    return (
        <Base.WrapMessage source="bot">
            <p>Let's continue. To receive money, fill out an application.</p>
        </Base.WrapMessage>
    );
});

WelcomeMessage.displayName = "Registration.WelcomeMessage";
