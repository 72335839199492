/* eslint-disable @stylistic/max-len */

import { Benefits } from "./Benefits";

export const Layout = () => {
    return (
        <article>
            <h1>About KoboGo</h1>
            <p className="under-title">
                Get up to ₦ 100,000 loan in just three steps
            </p>
            <h2>Why KoboGo?</h2>
            <p className="description">
                KoboGo is a online loan service, where you can apply for a loan in the amount of 5 000 to ₦ 100 000 in 1 minute with a minimum package of documents. Our unique KoboGo service allows
                you to receive money to your account number instantly! To do this, you need to download the application on your phone and verify your identity in order to receive a loan offer.
            </p>
            <Benefits />
        </article>
    );
};

export default Layout;
