import axios from "axios";
import * as Client from "modules/client";
import * as ProcessingFace from "modules/components/bot/scoring";
import { IconMono } from "modules/components/Icons/IconMono";
import * as React from "react";
import { useHistory } from "react-router-dom";

export const Layout = () => {
    const { api } = Client.useStateContext();
    const history = useHistory();

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        const interval = setInterval(() => {
            api.with({ cancelToken: cancelToken.token })
                .user.mono.get()
                .then((response) => {
                    switch (response.status) {
                        case "AUTH":
                        case "FAILED":
                            history.push("/chat");
                            return;
                        case "AVAILABLE":
                            history.push("/registration/mono/details");
                            return;
                        case "PROCESSING":
                            return;
                        default:
                            console.error(`[Registration\\Mono\\Details] Unknown status for Mono Account: ${(response as any).status}`);
                    }
                });
        }, 10000);

        return () => {
            cancelToken.cancel();
            clearInterval(interval);
        };
    }, []);

    return (
        <>
            <h1>Please wait</h1>
            <p className="under-title">Bank connection processing...</p>
            <div className="animation-container">
                <ProcessingFace.Layout />
            </div>
        </>
    );
};

export default Layout;
