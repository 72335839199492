import { LayoutContainer } from "modules/components/layout-container";
import { useTheme } from "modules/hooks/use-theme";

export const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
    useTheme("internal");
    return (
        <LayoutContainer isInternal>
            <div className="page">
                <div className="page-content">{children}</div>
            </div>
        </LayoutContainer>
    );
};
