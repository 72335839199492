import * as Base from "app/chat/base";
import * as Chat from "modules/chat";
import * as React from "react";
import * as State from "../state";

type FailedMessageProps = State.MessageProps<Chat.DirectDebit.MandateFailedMessage>;

export const MandateFailedMessage: React.FC<React.PropsWithChildren<FailedMessageProps>> = (props) => {
    return (
        <>
            <Base.WrapMessage source="bot">
                <p>
                    Unfortunately the creation of a mandate for your account
                    <b>{props.value.accountNumber}</b> has been rejected by {props.value.bankName}.
                </p>
                <p>
                    Try using a different bank account or contact your bank's support team.
                </p>
            </Base.WrapMessage>
        </>
    );
};
