import {
    format,
    InputMask,
    InputMaskProps,
    TrackingData,
} from "@react-input/mask";
import * as React from "react";

const track = (trackingData: TrackingData) => {
    if (trackingData.inputType !== "insert") {
        return trackingData.data;
    }
    const changeValue = trackingData.data.replace(/\D/g, "");
    if (
        changeValue.startsWith("234")
    && (changeValue.length === 13 || trackingData.selectionStart < 3)
    ) {
        return changeValue.substring(3);
    }

    return trackingData.data;
};

export type PhoneInputDefaultPropsType = Required<
    Pick<
        InputMaskProps,
    "mask" | "inputMode" | "replacement" | "showMask" | "track"
    >
>;

export type PhoneInputDefaultFormatPropsType = Required<
    Pick<
        InputMaskProps,
        "mask" | "replacement"
    >
>;

export const PhoneInputDefaultProps: PhoneInputDefaultPropsType = Object.freeze(
    {
        replacement: { _: /\d/ },
        mask: "+234 ___ ___ ____",
        inputMode: "tel",
        showMask: false,
        track,
    },
);
export const PhoneInputDefaultFormatProps: PhoneInputDefaultFormatPropsType= Object.freeze(
    {
        replacement: { _: /\d/ },
        mask: "+234 ___ ___ ____",
    },
);

export type PhoneInputProps = Omit<
    InputMaskProps,
    keyof PhoneInputDefaultPropsType
>;

export const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>(
    (props, ref) => {
        return (
            <InputMask
                ref={ref}
                {...props}
                {...PhoneInputDefaultProps}
            />
        );
    },
);
