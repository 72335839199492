import * as React from "react";

export const TelegramIcon = () => {
    return (
        <svg
            viewBox="0 0 69.2 69.2"
            y="0px"
            x="0px"
            className="icon icon_telegram">
            <g id="Layer_3">
                <path
                    id="path5"
                    d="M14.4,34.3c0,0,17.3-7.1,23.3-9.6c2.3-1,10.1-4.2,10.1-4.2s3.6-1.4,3.3,2c-0.1,1.4-0.9,6.3-1.7,11.6               c-1.2,7.5-2.5,15.7-2.5,15.7s-0.2,2.3-1.9,2.7c-1.7,0.4-4.5-1.4-5-1.8c-0.4-0.3-7.5-4.8-10.1-7c-0.7-0.6-1.5-1.8,0.1-3.2               c3.6-3.3,7.9-7.4,10.5-10c1.2-1.2,2.4-4-2.6-0.6c-7.1,4.9-14.1,9.5-14.1,9.5s-1.6,1-4.6,0.1c-3-0.9-6.5-2.1-6.5-2.1               S10.3,35.9,14.4,34.3z"
                    fill="#FFFFFF"
                    className="icon-fill"
                />
            </g>
        </svg>
    );
};
