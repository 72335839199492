import * as State from "app/chat/state";
import * as Chat from "modules/chat";
import * as Mono from "modules/mono.co";
import * as React from "react";
import { useHistory } from "react-router-dom";
import * as Base from "../base";

export const WidgetMessage: State.Message<State.MessageProps<Chat.BankId.WidgetMessage>> = ({ value }) => {
    const dispatch = Chat.useDispatchContext();
    const history = useHistory();
    console.log(`Loading Mono widget with public key: ${value.publicKey}`);

    const monoConnect = React.useMemo(() => {
        if (!value.publicKey) {
            return;
        }
        const monoInstance = new Mono.Connect({
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClose: () => {},
            onLoad: () => console.log("Widget loaded successfully"),
            onSuccess: ({ code }: { code: string; }) => {
                if (value.reauthToken) {
                    history.push("/registration/mono/details");
                }
                dispatch(new Chat.ReplaceAction([new Chat.BankId.LoadMessage(code)]));
                monoInstance.close();
            },
            onEvent: (...args) => console.warn({ args }),
            key: value.publicKey,
            data: value.data,
        });

        monoInstance.setup(
            value.reauthToken
                ? {
                    reauth_token: value.reauthToken,
                }
                : {},
        );

        return monoInstance;
    }, [value.publicKey, value.reauthToken]);

    return (
        <Base.ButtonMessage source="user">
            <Base.Button
                theme={"secondary"}
                onClick={monoConnect?.open}>
                Link account with Mono
            </Base.Button>
        </Base.ButtonMessage>
    );
};
