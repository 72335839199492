import "@horat1us/react-hooks/styles/printable.min.css";
import dayjs from "dayjs";
import * as Client from "modules/client";
import { Instance } from "modules/client/api";
import { IframeLoader } from "modules/components/iframe-loader";
import { activeRestructuringPayment } from "modules/utils/active-restructuring-payment";
import { useState, useRef, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";

interface InvoiceProps {
    additionalPayment: number | undefined;
    totalDebt: number;
    fullName: string;
    applicationId: number;
    datePaid: string;
    api: Instance;
}

interface Params {
    amount: string;
    fullName: string;
    applicationId: string;
    datePaid: string;
}

export const Invoice = Client.withState<{}, InvoiceProps>(
    ({ additionalPayment, totalDebt, fullName, applicationId, datePaid, api }) => {
        const [inn, setInn] = useState<number | undefined>(undefined);
        const ref = useRef<HTMLIFrameElement>(null);
        const { state: stateFrom } = useLocation<{
            type: "prolongation" | "repayment" | "restructuring";
        }>();

        useEffect(() => {
            const cancel = api.cancellable();

            const promise = new Promise(resolve => setTimeout(resolve, 1500));
            api.user.data.attribute("inn").then(async (data) => {
                await promise;
                setInn(data.value);
            });

            return cancel;
        }, []);

        const amount = (stateFrom.type === "prolongation" || stateFrom.type === "restructuring") && additionalPayment ? additionalPayment : totalDebt;
        const params: Params = {
            amount: amount.toString(),
            fullName,
            applicationId: applicationId.toString(),
            datePaid,
        };

        const handlePrint = useCallback(() => {
            if (!ref.current?.contentWindow) {
                return;
            }

            const event = new Event("print");
            ref.current.contentWindow.dispatchEvent(event);
        }, [ref.current]);

        // todo проверить работу в аппе
        const origin = window.location.origin;

        return (
            <>
                <div className="panel-invoice">{inn
                    ? <iframe
                        ref={ref}
                        name="payment-receipt"
                        className="payment-receipt"
                        src={getIframeSrc(params, inn, origin)} />
                    : <IframeLoader />}</div>
                <div className="jumbotron">
                    <div className="jumbotron__main">
                        <span>Увага! Гроші зараховуються до 3-х днів. За переказ в касі банк стягує комісію.</span>
                    </div>
                    <div className="jumbotron__aside">
                        <button
                            type="button"
                            className="btn btn_white"
                            onClick={handlePrint}>
                            Завантажити квитанцію
                        </button>
                    </div>
                </div>
            </>
        );
    },
    (state) => {
        if ("user" !== state.type) {
            return false;
        }
        return {
            totalDebt: state.request.debt?.total ?? 0,
            fullName: `${state.user.lastName ?? ""} ${state.user.firstName ?? ""} ${state.user.middleName ?? ""}`,
            applicationId: state.request.applicationId,
            datePaid: dayjs(state.request.datePaid).format("DD.MM.YYYY") ?? dayjs().format("DD.MM.YYYY"),
            additionalPayment: state.request.restructuring
                ? activeRestructuringPayment(state.request.restructuring.schedule).debt
                : Boolean(state.request.debt?.prolongation)
                    ? state.request.debt?.prolongation
                    : undefined,
            api: state.api,
        };
    },
);

function getIframeSrc(params: Params, inn: number, origin: string): string {
    const searchParams = new URLSearchParams({ ...params, inn: inn.toString() });

    return origin + "/proxy-frame.html?" + searchParams.toString();
}

Invoice.displayName = "CurrentCredit.Invoice";
