import * as React from "react";
import { Route as BaseRoute } from "react-router-dom";
import { Page } from "./page";

export const Route: () => React.ReactElement = () => {
    return (<BaseRoute
        key="request-sold"
        path="/dashboard/sold"
        exact
        component={Page} />);
};
