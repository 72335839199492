import * as Chat from "modules/chat";
import * as Client from "modules/client";
import { ButtonAgreement } from "modules/components/button-agreement";
import React from "react";
import { WrapMessage } from "../base";
import * as State from "../state";

export const OpenMessage =
    Client.withState<State.MessageProps<Chat.Agreement.AgreementOpenMessage>, { applicationId: number; } >(
        ({ applicationId }) => {
            const at = React.useMemo(() => new Date().toISOString(), []);
            return (
                <WrapMessage
                    source={"bot"}
                    at={at}
                    className="lower">
                    <ButtonAgreement id={applicationId} />
                </WrapMessage>
            );
        },
        state => ("user" === state.type)
        && {
            applicationId: state.request.applicationId,
        },
    );
OpenMessage.displayName = "Agreement.OpenMessage";
