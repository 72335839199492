export const IconMono = () => (
    <svg
        viewBox="0 0 25 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon_mono">
        <path
            className="icon-fill"
            d="M17.1501 0.0932012C17.0789 0.0403537 16.9949 0.00862182 16.9071 0.001523C16.8193 -0.00557581 16.7314 0.0122359 16.653 0.0529833C16.5746 0.093731 16.5088 0.155822 16.4629 0.232373C16.417 0.308925 16.3927 0.396946 16.3929 0.486678V5.6566L8.95362 0.0932012C8.88249 0.0403537 8.79835 0.00862182 8.71067 0.001523C8.62291 -0.00557581 8.535 0.0122359 8.45655 0.0529833C8.37819 0.093731 8.31237 0.155822 8.26641 0.232373C8.22053 0.308925 8.19628 0.396946 8.19643 0.486678V5.6566L0.75716 0.0932012C0.686034 0.0403534 0.601945 0.00862143 0.514214 0.00152286C0.426483 -0.0055757 0.338537 0.0122365 0.260128 0.0529847C0.181717 0.0937326 0.115907 0.155825 0.0699923 0.232377C0.0240773 0.30893 -0.000148533 0.396951 6.85188e-07 0.486684V7.79162C0.000274638 7.86804 0.018175 7.94339 0.0522563 8.01144C0.0863376 8.07949 0.135644 8.13844 0.196194 8.18357L7.8491 13.9067C7.92023 13.9596 8.00429 13.9913 8.09205 13.9984C8.17973 14.0055 8.26772 13.9877 8.34609 13.9469C8.42453 13.9062 8.49035 13.8441 8.53623 13.7676C8.58219 13.691 8.60636 13.603 8.6062 13.5133V8.3433L16.0455 13.9067C16.1167 13.9596 16.2007 13.9913 16.2884 13.9985C16.3762 14.0056 16.4641 13.9877 16.5425 13.947C16.6209 13.9063 16.6867 13.8442 16.7326 13.7676C16.7785 13.691 16.8026 13.603 16.8024 13.5133V8.34306L24.2432 13.9067C24.3143 13.9596 24.3983 13.9913 24.4861 13.9985C24.5738 14.0056 24.6617 13.9877 24.7401 13.947C24.8186 13.9063 24.8843 13.8442 24.9302 13.7676C24.9761 13.691 25.0002 13.603 25 13.5133V6.20925C24.9998 6.13258 24.9818 6.05706 24.9475 5.98881C24.9132 5.92055 24.8637 5.86148 24.8029 5.81638L17.1501 0.0932012Z"
            fill="#1A4C6C"
        />
    </svg>
);
