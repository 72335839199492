import { AxiosInstance } from "axios";

export type PromoPageInfo = {
    promocode: string;
    discount: number;
    titleRu: string;
    titleUa: string;
    subtitleRu: string;
    subtitleUa: string;
    welcomeRu: string;
    welcomeUa: string;
};

export const Api = (endpoint: AxiosInstance) => {
    return {
        validate: async (promoShortUrl: string): Promise<PromoPageInfo> => {
            return endpoint
                .get<PromoPageInfo>("/v2/promo-page", {
                    params: { promoShortUrl },
                })
                .then((
                    {
                        data: {
                            promocode,
                            discount,
                            titleRu,
                            titleUa,
                            subtitleRu,
                            subtitleUa,
                            welcomeRu,
                            welcomeUa,
                        },
                    }) => ({
                    promocode,
                    discount,
                    titleRu,
                    titleUa,
                    subtitleRu,
                    subtitleUa,
                    welcomeRu,
                    welcomeUa,
                }));
        },
    } as const;
};
