import * as Chat from "modules/chat";
import { IconArrow } from "modules/components/Icons/IconArrow";
import React, { useEffect } from "react";
import * as Base from "../base";

export const CompleteFormMessage = () => {
    const dispatch = Chat.useDispatchContext();

    useEffect(() => {
        return () => localStorage.removeItem("firstCalculator.done");
    }, []);

    const completeText = "Done";
    const handleConfirm = React.useCallback(() => {
        dispatch(new Chat.ReplaceAction(
            [
                new Chat.TextMessage(completeText, "user"), new Chat.Registration.CompleteSubmitMessage(),
            ]),
        );
    }, [dispatch]);
    return (
        <Base.ButtonMessage source={"user"}>
            <Base.Button
                id={"complete-registration-submit"}
                theme="secondary"
                onClick={handleConfirm}>
                <span>{completeText}</span>
                <IconArrow />
            </Base.Button>
        </Base.ButtonMessage>
    );
};
