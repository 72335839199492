import * as Chat from "modules/chat";
import * as React from "react";
import * as Base from "../base";
import * as State from "../state";

export const FormMessage: State.Message<
    State.MessageProps<Chat.Registration.FormMessage>
> = (props) => {
    const handleSubmit = React.useCallback((attribute: string, value: string): Chat.Action => {
        return new Chat.PushAction([
            new Chat.Registration.FormSubmitMessage(
                attribute, value, props.value.text, props.value.name, props.value.schema,
            ),
        ]);
    }, [props.value]);

    return (
        <Base.Form.Message
            attribute={props.value}
            label={props.value.text}
            onSubmit={handleSubmit}
        />
    );
};
