import { ConfigurationValue } from "modules/client/api/configuration";
import * as React from "react";

export const DefaultConfigurationValue: ConfigurationValue = {
    phones: {
        collection: [],
        support: [],
    },
};

export const Context = React.createContext<ConfigurationValue>(DefaultConfigurationValue);
