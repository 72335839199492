import { LocalizedRoute } from "app/locale";
import * as React from "react";
import { Page } from "./page";

export const Route: () => React.ReactNode = () => {
    return (<LocalizedRoute
        key="invoice"
        path="/dashboard/invoice"
        exact
        component={Page} />);
};
