import * as Accordion from "modules/accordion";
import { accordionData } from "./accordionData";
import "./styles.scss";

export const Layout = () => {
    return (
        <article>
            <h1>Help</h1>
            <div className="under-title">FAQ</div>
            <div className="accordion">
                <Accordion.Controller>
                    {accordionData.map(({ title, content }) => (
                        <Accordion.Item
                            id={title}
                            title={title}
                            key={title}>
                            {content}
                        </Accordion.Item>
                    ))}
                </Accordion.Controller>
            </div>
        </article>
    );
};

export default Layout;
