import { Capacitor } from "@capacitor/core";
import * as State from "app/chat/state";
import * as Chat from "modules/chat";
import * as React from "react";
import { OpenInCapacitorBrowser } from "../../../modules/components/open-in-capacitor-browser";
import * as Base from "../base";

export const RedirectMessage: State.Message<State.MessageProps<Chat.Card.RedirectMessage>> = ({ value }) => {
    const dispatch = Chat.useDispatchContext();

    const onBrowserFinished = React.useCallback((): void => {
        dispatch(new Chat.FlushAction([new Chat.Card.InitMessage(), new Chat.Card.ConfirmMessage()]));
    }, [dispatch]);

    const onAppUrlOpen = React.useCallback((url: string) => {
        const successUrl = "kobogo://closeBrowser/chat/card-success";

        if (url.includes(successUrl)) {
            dispatch(new Chat.FlushAction([new Chat.Card.LoadCompleteMessage()]));
        }
    }, [dispatch]);

    React.useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            return;
        }

        const timeoutId = setTimeout(() => {
            window.location.href = value.action;
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, [value]);

    if (Capacitor.isNativePlatform()) {
        return (<OpenInCapacitorBrowser
            url={value.action}
            onBrowserFinished={onBrowserFinished}
            onAppUrlOpen={onAppUrlOpen} />);
    } else {
        return <Base.LoadMessage />;
    }
};
