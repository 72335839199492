import { LocalizedLink } from "app/locale";
import * as SadFace from "modules/components/bot/sad";
import { IconArrow } from "modules/components/Icons/IconArrow";
import { LogoutButton } from "modules/components/logout-button";
import "./styles.scss";

export const Layout = () => {
    return (
        <>
            <h1>Log out</h1>
            <p className="under-title">Do you want to leave your account?</p>
            <div className="animation-container">
                <SadFace.Layout />
            </div>
            <div className="btn-container">
                <p>You can return at any convenient time, after re-verification by your phone number</p>
                <LogoutButton className="btn btn_white">
                    <span>Leave account</span>
                    <i className="icon icon_logout" />
                </LogoutButton>
                <LocalizedLink
                    to={"/nav"}
                    className="btn btn_blue">
                    <span>Stay</span>
                    <IconArrow />
                </LocalizedLink>
            </div>
        </>
    );
};

export default Layout;
