import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as React from "react";
import * as Base from "../base";
import * as State from "../state";

type ChannelButtonProps = {
    onClick: (method: Client.Api.Sign.RequestChannel) => void;
    method: Client.Api.Sign.RequestChannel;
    theme: "primary" | "secondary";
};

export const ChannelButton: React.FC<
    React.PropsWithChildren<ChannelButtonProps>
> = React.memo((props: ChannelButtonProps) => {
    const handleClick = React.useCallback(() => {
        props.onClick(props.method);
    }, [props.onClick, props.method]);

    return (
        <Base.Button
            theme={props.theme}
            onClick={handleClick}>
            {props.method.title}
        </Base.Button>
    );
});

const getButtonTheme = (channel: Client.Api.Sign.RequestChannel): ChannelButtonProps["theme"] => {
    switch (channel.name) {
        case "sms":
            return "primary";
        case "whatsapp":
        default:
            return "secondary";
    }
};

export const ResendOptionsMessage: State.Message<State.MessageProps<Chat.SignUp.ResendOptionsMessage>> =
    (props) => {
        const dispatch = Chat.useDispatchContext();

        const handleClick: ChannelButtonProps["onClick"] = React.useCallback((channel: Client.Api.BankAccount.Method) => {
            dispatch(new Chat.ReplaceAction([
                new Chat.SignUp.RequestMessage(props.value.phone, channel.name),
            ], props.value));
        }, [dispatch]);

        return (
            <Base.ButtonMessage source={"user"}>
                {props.value.channels.map((channel) => {
                    return (<ChannelButton
                        onClick={handleClick}
                        method={channel}
                        theme={getButtonTheme(channel)}
                        key={channel.name}
                    />);
                })}
            </Base.ButtonMessage>
        );
    };
