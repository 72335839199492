import toLower from "lodash/toLower";
import { keyboardLayoutFormat } from "./keyboard-layout-format";

const upperFirst = (str: string) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
};

export const nameFilter = (value: string): string => {
    return value.split("-")
        .map(toLower)
        .map(v => keyboardLayoutFormat(v))
        .map(upperFirst)
        .join("-");
};
