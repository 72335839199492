import { Capacitor } from "@capacitor/core";
import * as Calculator from "app/chat/calculator";
import { useLocale } from "app/locale";
import * as Chat from "modules/chat";
import { useCalc } from "modules/hooks/use-calc";
import { useCallback } from "react";
import * as React from "react";
import { Button } from "../button";
import * as State from "../state";
import "app/chat/calculator/calc.scss";

export const FormMessage: State.Message<
    State.MessageProps<Chat.PromoPage.FormMessage>
> = (props) => {
    const { t } = useLocale("main");
    const conditions = useCalc(props.value);

    const promo = props.value.promo;

    if (!promo) {
        return null;
    }

    return (
        <>
            <div className="message bot-message calc-message full-sized">
                <div className={ Capacitor.isNativePlatform() ? "calc calc_capacitor" : "calc"}>
                    <div className="h-align">
                        <Calculator.FormGroup
                            message={props.value}
                            notice={t("calc-message.max-sum")}
                            label={t("calc-message.sum")}
                            field="amount"
                            messageRender={useCallback(newConditions =>
                                new Chat.PromoPage.FormMessage(newConditions), [],
                            )}
                        />
                        <Calculator.FormGroup
                            message={props.value}
                            notice={`До ${conditions.returnDate.format(
                                "DD.MM.YYYY",
                            )} ${conditions.days[conditions.returnDate.day()]}`}
                            label={Capacitor.isNativePlatform() ? t("calc-message.term-capacitor") : t("calc-message.term")}
                            field="term"
                            messageRender={useCallback(newConditions =>
                                new Chat.PromoPage.FormMessage(newConditions), [],
                            )}
                        />
                    </div>
                    <div className="calc-actions">
                        <div className="v-align">
                            <div className="calc-total">
                                <div className="part">
                                    <p className="label">
                                        {t("calc-message.total")}
                                    </p>
                                    <p className="initial-amount">
                                        <span>{conditions.returnAmount}</span>
                                        <span>грн</span>
                                    </p>
                                </div>
                                <div className="part">
                                    <p className="label">
                                        {t("calc-message.with-promo")}
                                    </p>
                                    <p className="to-return">
                                        <span>
                                            {Math.round(
                                                conditions.returnAmount
                                                - conditions.interest
                                                + conditions.interest
                                                * (1 - promo.discount),
                                            )}
                                        </span>
                                        <span>грн</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="message bot-message calculator-menu promocode full-sized">
                <div className="wrap inline">
                    <div className="discount">
                        <span>Бонусный промокод:</span>
                        <span className="increased">
                            {promo?.discount * 100}
                            <span>%</span>
                        </span>
                    </div>
                    <div className="code">
                        <input
                            type="text"
                            value={promo.code}
                            readOnly />
                    </div>
                    <Button
                        className="btn btn_blue calculator-submit"
                        messages={[
                            new Chat.DelayMessage(
                                [
                                    new Chat.PromoPage.SetPromocodeMessage({
                                        code: promo.code,
                                        discount: promo.discount,
                                    }),
                                    new Chat.SignUp.InfoMessage(),
                                    new Chat.TextMessage(
                                        "Enter your mobile phone number:",
                                    ),
                                    new Chat.SignUp.RequestFormMessage(),
                                ],
                                1337,
                            ),
                        ]}
                        id="calculator-submit"
                        text={`${t("menu-message.button")} :smile:`}
                    />
                </div>
            </div>
        </>
    );
};
FormMessage.displayName = "Calculator.FormMessage";
