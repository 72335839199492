import { useCallback } from "react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import * as Chat from "../../../modules/chat";
import * as Client from "../../../modules/client";
import { chatBankAccountPathnameCheck } from "../../../modules/utils/chat-bank-account-pathname-check";
import * as Base from "../base";
import * as State from "../state";

export const ConfirmNubanLoadMessage: State.Message<
    State.MessageProps<Chat.BankAccount.ConfirmNubanLoadMessage>
> = (props) => {
    const history = useHistory();
    const handleRequest = useCallback(
        async (
            api: Client.Api.Instance,
        ): Promise<Client.Api.BankAccount.CreateBankAccountResponse> => {
            // eslint-disable-next-line no-return-await
            return await api.user.bankAccount.activateAccount(
                props.value.accountNumber,
            );
        },
        [],
    );

    const handleFinish = useCallback(
        async (
            response: Client.Api.BankAccount.CreateBankAccountResponse,
        ): Promise<Chat.Action | void> => {
            if ("account" in response) {
                if (chatBankAccountPathnameCheck(history.location.pathname)) {
                    return new Chat.ReplaceAction(
                        [new Chat.RedirectMessage("/dashboard/bank-accounts")],
                        props.value,
                    );
                }
                return new Chat.ReplaceAction(
                    [
                        new Chat.TextMessage(response.message),
                        new Chat.DelayMessage(
                            [new Chat.RefreshClientStateMessage()],
                            2000,
                        ),
                    ],
                    props.value,
                );
            }
            return new Chat.ReplaceAction(
                [
                    new Chat.TextMessage(response.message),
                    new Chat.BankAccount.WelcomeMessage(),
                    new Chat.BankAccount.InitMessage(),
                ],
                props.value,
            );
        },
        [],
    );

    return (
        <Base.DelaySubmitMessage
            delayMs={1000}
            value={props.value}
            onRequest={handleRequest}
            onFinish={handleFinish}
        />
    );
};
