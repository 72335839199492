import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as React from "react";

import * as Base from "../base";
import * as State from "../state";

export const SelectNubanBankLoadMessage: State.Message<
    State.MessageProps<Chat.BankAccount.SelectNubankBankLoadMessage>
> =
    (props) => {
        const handleRequest = React.useCallback(
            async (
                api: Client.Api.Instance,
            ): Promise<Array<Client.Api.BankAccount.Bank>> => {
                // eslint-disable-next-line no-return-await
                return await api.user.bankAccount.getBanks();
            },
            [],
        );

        const handleFinish = React.useCallback(
            async (response: Array<Client.Api.BankAccount.Bank>,
            ): Promise<Chat.Action | void> => {
                return new Chat.ReplaceAction(
                    [
                        new Chat.BankAccount.SelectNubanBankMessage(response),
                    ],
                );
            }, []);

        return (<Base.DelaySubmitMessage
            delayMs={1000}
            value={props.value}
            onRequest={handleRequest}
            onFinish={handleFinish}
        />);
    };
