import * as Credit from "./credit";

export const requestMapper = (data: Credit.Request) => {
    const mappedData = { ...data };

    if (mappedData.paid !== undefined && mappedData.paid !== null) {
        mappedData.paid = Number.parseFloat(mappedData.paid.toString());
    }

    return mappedData;
};
