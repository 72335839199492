import classNames from "classnames";
import { IconPencil } from "modules/components/Icons/IconPencil";
import * as React from "react";

export const ButtonEdit: React.FC<React.PropsWithChildren<React.HTMLProps<HTMLButtonElement>>> = (props) => {
    return (
        <button
            {...props}
            type="button"
            className={classNames("btn btn_action btn_edit enabled", props.className)}>
            <IconPencil />
        </button>
    );
};
