import * as Base from "app/chat/base";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { ResponseReauth } from "../../../modules/client/api/mono";
import * as State from "../state";

export const ReauthLoadMessage: State.Message<State.MessageProps<Chat.BankId.ReauthLoadMessage>> = ({ value }) => {
    const history = useHistory();
    const onRequest = React.useCallback((api: Client.Api.Instance) => {
        return api.user.mono.reauth().catch((error) => {
            console.error(error);
            return history.push("/failure");
        });
    }, [history]);
    const onFinish = React.useCallback(async (result: ResponseReauth | false) => {
        if (result) {
            return [
                new Chat.FlushAction([
                    new Chat.BankId.WidgetMessage(
                        result.config.publicKey,
                        result.config.data ?? {},
                        result.config.token,
                    ),
                ]),
            ];
        }
        return [
            new Chat.ReplaceAction([
                new Chat.DelayMessage([
                    new Chat.RefreshClientStateMessage(),
                ], 10000),
            ]),
        ];
    }, [value]);

    return (<Base.DelaySubmitMessage
        onRequest={onRequest}
        onFinish={onFinish}
        value={value} />);
};
