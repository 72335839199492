import * as Client from "../client";
import { Action } from "./action";
import * as Agreement from "./agreement";
import * as BankAccount from "./bank-account";
import * as BankId from "./bank-id";
import * as Calculator from "./calculator";
import * as Card from "./card";
import * as Decision from "./decision";
import * as DirectDebit from "./direct-debit";
import * as PhoneId from "./phone-id";
import * as Prolongation from "./prolongation";
import * as PromoPage from "./promo-page";
import * as Registration from "./registration";
import * as Restructuring from "./restructuring";
import * as ShareContactsMessage from "./share-сontacts";
import * as SignUp from "./sign-up";
import * as SmileId from "./smile-id";

export type InfoMessageType = "info:load" | "info:failure" | "info:service" | "info:return";

export class InfoMessage {
    constructor(public readonly type: InfoMessageType) {
        Object.freeze(this);
    }
}

export const LoadMessage = new InfoMessage("info:load");

export class TextMessage {
    public readonly type = "text";

    constructor(
        public readonly markdown: string | string[],
        public readonly author: MessageSource = "bot",
        public readonly isDebug?: boolean,
        public readonly time: string = new Date().toISOString(),
    ) {}
}

export class TextMessageEditable<S extends Client.Api.DataAttributeRecord<any> = Client.Api.DataAttributeRecord<any>> {
    public readonly type = "text-editable";

    constructor(
        public readonly content: string,
        public readonly attribute: S,
        public readonly time: string = new Date().toISOString(),
        public readonly hasError?: boolean,
    ) {}
}

export class EditableMessage {
    public readonly type = "editable";

    constructor(
        public readonly content: string,
        public readonly editAction: Action,
    ) {}
}

export class DebugMessage extends TextMessage {
    public readonly isDebug = true;
}

export class FailureMessage {
    public readonly type = "info:failure";

    constructor(public readonly text?: string) {}
}

export class TimeoutMessage {
    public readonly type = "timeout";
    public readonly time: string = new Date().toISOString();

    constructor(
        public readonly value: number,
        public readonly message: Message,
    ) {}
}

export class DelayMessage {
    public readonly type = "delay";

    constructor(
        public readonly children: Array<Message>,
        public readonly timeout: number = 500,
    ) {}
}

export class RedirectMessage {
    public readonly type = "redirect";

    constructor(public readonly to: string) {}
}

export class RefreshClientStateMessage {
    public readonly type = "refresh-client-state";
}

export type MessageSource = "bot" | "user";

export type Message =
    | InfoMessage
    | DebugMessage
    | TextMessage
    | Calculator.Message
    | SignUp.Message
    | Registration.Message
    | FailureMessage
    | DelayMessage
    | TimeoutMessage
    | BankId.Message
    | Agreement.Message
    | Restructuring.Message
    | RedirectMessage
    | TextMessageEditable
    | EditableMessage
    | RefreshClientStateMessage
    | PromoPage.Message
    | Prolongation.Message
    | PhoneId.Message
    | SmileId.Message
    | Card.Message
    | ShareContactsMessage.Message
    | DirectDebit.Message
    | BankAccount.Message| Decision.Message;
