import { Markdown, MarkdownProps } from "modules/components/markdown";
import * as React from "react";
import { MessageProps } from "./message";
import { WrapMessage } from "./wrap-message";

export type MarkdownMessageProps = MessageProps & MarkdownProps;

export const MarkdownMessage: React.FC<
    React.PropsWithChildren<MarkdownMessageProps>
> = React.memo(({ content, ...props }) => {
    return (
        <WrapMessage {...props}>
            <Markdown content={content} />
        </WrapMessage>
    );
});
MarkdownMessage.displayName = "MarkdownMessage";
