import classNames from "classnames";
import { LayoutContainer } from "modules/components/layout-container";
import { useTheme } from "modules/hooks/use-theme";

export const Layout: React.FC<React.PropsWithChildren<{ className?: string; }>> = ({ className, children }) => {
    useTheme("info");
    return (
        <LayoutContainer>
            <div className={classNames("status-wrap", className)}>
                <div className="status">{children}</div>
            </div>
        </LayoutContainer>
    );
};
