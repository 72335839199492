import * as Client from "modules/client";
import * as BtnPdf from "modules/components/btn-pdf";
import { ButtonDownload } from "modules/components/button-download";
import { useDocumentDownload } from "modules/hooks/use-document-download";
import * as React from "react";
import { compose, useLocale } from "../../../app/locale";

export const ButtonRestructuring = Client.withApi<{
    id: number;
    className?: string;
}>(({ id, className }) => {
    const handleRequest = React.useCallback(
        (api: Client.Api.Instance) => api.user.restructuring.get(id),
        [id],
    );
    const [url, setUrl] = useDocumentDownload(handleRequest);
    const { t } = useLocale("credit-history");

    switch (url) {
        case undefined:
        case "loading":
        case "failed":
            return (<ButtonDownload
                url={url}
                setUrl={setUrl}
                id="btn-restructuring_download"
                className={className}
                name={t(compose("restructuring", "agreement", "default"))} />);
        default:
            return (<BtnPdf.Layout
                link={url}
                id="btn-restructuring_open"
                className={className}
                title={t(compose("restructuring", "agreement", "open"))} />);
    }
});
ButtonRestructuring.displayName = "ButtonRestructuring";
