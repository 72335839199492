import { useLocale } from "app/locale";
import * as Chat from "modules/chat";
import * as React from "react";
import * as Base from "../base";

export const RefuseFormMessage = () => {
    const dispatch = Chat.useDispatchContext();
    const { t } = useLocale("registration");

    const handleCanceled = React.useCallback(() => {
        dispatch(
            new Chat.ReplaceAction([
                new Chat.TextMessage(t("refuse"), "user"),
                new Chat.TextMessage([t("restructuring-refuse-question"), t("refuse-question-2")], "bot"),
                new Chat.Restructuring.RestructuringRefuseInfoMessage(),
            ]),
        );
    }, []);

    const handleConfirm = React.useCallback(() => {
        dispatch(new Chat.ReplaceAction([new Chat.Restructuring.RestructuringSendMessage()]));
    }, []);

    return (
        <Base.ButtonMessage source={"user"}>
            <Base.Button
                theme="primary"
                onClick={handleCanceled}>
                {t("refuse")}
            </Base.Button>
            <Base.Button
                theme="secondary"
                className={"long"}
                onClick={handleConfirm}>
                {t("sign-a-contract")}
            </Base.Button>
        </Base.ButtonMessage>
    );
};
