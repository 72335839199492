import * as SadFace from "modules/components/bot/sad";
import * as React from "react";
import { DeleteAccountButton } from "./DeleteAccountButton";

export const Layout = () => {
    return (
        <>
            <h1>Account Deletion</h1>
            <p className="under-title">
                The account deletion request will be processed by our support
                team and may take up to three days. You will receive a
                confirmation email once the account is deleted.
            </p>
            <div className="animation-container">
                <SadFace.Layout />
            </div>
            <h4>Important Information</h4>
            <ul>
                <li>
                    <strong>Outstanding Credit</strong>. Account deletion is not
                    possible if you have an active loan. You must repay any
                    outstanding loans and fulfill all obligations to our company
                    before deleting your account.
                </li>
                <li>
                    <strong>Future Loans</strong>. After deleting your account,
                    you will not be able to apply for a loan with our company.
                </li>
            </ul>
            <h4>Data Retention Policy</h4>
            <p>
                In accordance with legal requirements, we will retain all your
                data (name, surname, phone number, email, residential address,
                bank account, and transaction history) for three years from the
                date of your last loan application or the date of loan repayment
                if a loan was issued. After this period expires, all of this
                data will be deleted. If you have any questions or need further
                assistance, please contact our support team.
            </p>
            <div className="btn-container">
                <p />
                <DeleteAccountButton />
            </div>
        </>
    );
};

export default Layout;
