import * as React from "react";

import * as Api from "./api/api";
import * as Auth from "./auth";

export type State = Auth.State & Api.State;

// eslint-disable-next-line no-redeclare
export function State(): State {
    return Object.freeze({
        ...Api.getState(),
        ...Auth.getState(),
    });
}

export const StateContext = React.createContext<State>(
    Object.freeze({
        ...Api.DefaultState,
        ...Auth.GuestState,
    }),
);

export function useStateContext(): State {
    return React.useContext(StateContext);
}
StateContext.displayName = "Client.StateContext";
