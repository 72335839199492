import * as Client from "modules/client";
import * as React from "react";
import { useHistory } from "react-router-dom";

export const LogoutButton: React.FC<React.PropsWithChildren<React.HTMLProps<HTMLButtonElement>>> = (props) => {
    const dispatch = Client.useDispatchContext();
    const history = useHistory();
    const handleClick = React.useCallback(() => {
        dispatch(new Client.Auth.LogoutAction());
        history.push("/");
    }, [dispatch, history]);
    return (<button
        {...props}
        type="button"
        onClick={handleClick} />);
};
