import * as Chat from "modules/chat";
import * as Client from "modules/client";
import { sendConversion } from "modules/utils/send-conversion";
import React from "react";
import * as Base from "../base";
import * as State from "../state";

export const CompleteSubmitMessage: State.Message<State.MessageProps<Chat.Registration.FormSubmitMessage>> =
    (props) => {
        const dispatch = Client.useDispatchContext();
        const handleRequest = React.useCallback((api: Client.Api.Instance) => {
            sendConversion("registration");
            return api.user.data.complete();
        }, []);
        const handleFinish = React.useCallback((): void => {
            dispatch(new Client.Auth.RefreshAction());
        }, [dispatch]);

        return (<Base.DelaySubmitMessage
            delayMs={5000}
            value={props.value}
            onRequest={handleRequest}
            onFinish={handleFinish}
        />);
    };
