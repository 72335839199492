import * as React from "react";

export const Title: React.FC<React.PropsWithChildren<{ text: string; }>> = ({ text, children }) => (
    <>
        <h4 className="accordion__title">
            {children ? <span>{text}</span> : text}
            {children}
        </h4>
    </>
);
Title.displayName = "Accordion.Title";
