import * as Client from "modules/client";

export const Name = Client.withState<{}, { name: string | undefined; lastName: string | undefined; }>(
    ({ name, lastName }) => {
        return (
            <h3>
                {name} {lastName}
            </h3>
        );
    },
    (state) => {
        return (
            state.type === "user" && {
                name: state.user.firstName,
                lastName: state.user.lastName,
            }
        );
    },
);
