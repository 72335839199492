import { useLocale } from "app/locale";
import * as React from "react";

export const HeaderMessage: React.FC<React.PropsWithChildren> = React.memo(() => {
    const { t } = useLocale("main");

    return (
        <>
            <h1 className="title">{t("title", { money: "10 000" })}</h1>
            <h2 className="under-title">{t("under-title", { time: "1" })}</h2>
        </>
    );
});
