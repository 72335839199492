import { LocalizedRoute } from "app/locale";
import * as React from "react";
import { Page } from "./Page";

export const Route: () => React.ReactElement = () => {
    return (
        <LocalizedRoute
            key="update-loading"
            path="/update-loading"
            exact
            component={Page}
        />
    );
};

export default Route;
