import * as React from "react";
import { ReviewApiType } from "../../../modules/client/api/review";
import happyBot from "./bot-happy.png";
import happyBotWebp from "./bot-happy.webp";
import normalBot from "./bot-normal.png";
import normalBotWebp from "./bot-normal.webp";
import sadBot from "./bot-sad.png";
import sadBotWebp from "./bot-sad.webp";

import "./styles.scss";

type ReviewButtonItem = { png: string; webp: string; type: ReviewApiType; };

type ReviewButtonProps = {
    onButtonClick: (type: ReviewApiType, event: React.MouseEvent) => void;
};

type ReviewButtonImageProps = {
    onClick: (event: React.MouseEvent) => void;
    button: ReviewButtonItem;
};
export const ReviewButtonImage: React.FC<React.PropsWithChildren<ReviewButtonImageProps>> = (props) => {
    return (
        <button
            className="btn btn_review"
            onClick={props.onClick}>
            <picture>
                <source
                    srcSet={props.button.webp}
                    type="image/webp" />
                <source
                    srcSet={props.button.png}
                    type="image/png" />
                <img
                    src={props.button.png}
                    alt="Review bot" />
            </picture>
        </button>
    );
};

export const ReviewButton: React.FC<React.PropsWithChildren<ReviewButtonProps>> = ({ onButtonClick }) => {
    const buttons: Array<ReviewButtonItem> = [
        {
            png: happyBot,
            webp: happyBotWebp,
            type: "good",
        },
        {
            png: normalBot,
            webp: normalBotWebp,
            type: "neutral",
        },
        {
            png: sadBot,
            webp: sadBotWebp,
            type: "bad",
        },
    ];

    // todo продумать лоадер пока грузится картинка
    return (
        <div className="review-btn-container">
            {buttons.map((button) => {
                return (<ReviewButtonImage
                    key={button.type}
                    onClick={React.useCallback(
                        event => onButtonClick(button.type, event), [onButtonClick, button.type],
                    )}
                    button={button} />);
            })}
        </div>
    );
};
