import * as Chat from "modules/chat";
import * as Client from "modules/client";

import { isRestructuringSignAvailable } from "modules/utils/is-restructuring-sign-available";
import * as State from "./state";

export class ProviderProps implements ProviderProps {
    public static create(state: Client.State): ProviderProps {
        return new ProviderProps(state.api, ProviderProps.getStatus(state));
    }

    private static getStatus(state: Client.State): Chat.ClientStatus {
        if (state.user === undefined) {
            return null;
        }
        if ("string" === typeof state.user) {
            return undefined;
        }
        if (isRestructuringSignAvailable(state)) {
            return "restructuring";
        }
        return state.user.status;
    }

    private constructor(
        public api: Client.Api.Instance,
        public readonly status: Chat.ClientStatus,
    ) {}
}

export const Provider: React.FC<React.PropsWithChildren<ProviderProps>> = ({ status, api }) => {
    const [state, dispatch] = Chat.useReducer(status, api);

    return (
        <Chat.DispatchContext.Provider value={dispatch}>
            {state.messages.map((value, key) => (
                <State.Message
                    value={value}
                    key={value.type + key}
                    i={key} />
            ))}
        </Chat.DispatchContext.Provider>
    );
};
Provider.displayName = "Chat.Provider";

export default Client.withState<{}, ProviderProps>(Provider, ProviderProps.create);
