import { useLocale } from "app/locale";
import * as Client from "modules/client";
import { useCallback } from "react";
import * as React from "react";
import * as Base from "../base";

export const ExitMessage = () => {
    const dispatch = Client.useDispatchContext();
    const { t } = useLocale("registration");

    const handleClick = useCallback(() => {
        dispatch(new Client.Auth.RefreshAction());
    }, []);

    return (
        <Base.ButtonMessage source={"user"}>
            <Base.Button
                theme={"secondary"}
                onClick={handleClick}>
                {t("goodbye")}
            </Base.Button>
        </Base.ButtonMessage>
    );
};
