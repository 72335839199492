import * as React from "react";
import * as Base from "../base";

export const WelcomeMessage: React.FC<React.PropsWithChildren> = () => {
    return (<>
        <Base.WrapMessage source="bot">
            <p>
                To receive a loan, you need to link your bank account.
                The specified account will be used to issue funds.
                The account must be in the name you provided on your application.
            </p>
            <p>
                Please method to link your bank account
            </p>
        </Base.WrapMessage>
    </>);
};
