import { AxiosInstance } from "axios";

// TODO вынести в capacitorApi аналогично guestApi и UserApi
export const Api = (endpoint: AxiosInstance) => ({
    postDeviceInfo: async (deviceToken: string, details: {}) => {
        const response = await endpoint.put<any>("/v3/mobile/device/id", {
            Device: {
                tag: deviceToken,
                details,
            },
        });

        return response.data;
    },
    postDeviceLead: async (deviceToken: string, source: string, medium: string, referrer?: string) => {
        const response = await endpoint.put<any>(`/v3/mobile/device/lead?tag=${deviceToken}`, {
            Form: {
                source,
                query: {
                    medium,
                },
                referrer,
            },
        });

        return response.data;
    },
});
