import * as React from "react";
import * as Internal from "../Layout";

const Article = React.lazy(() => import(/* webpackChunkName: "faq" */ "./Layout"));
export const Page: React.FC<React.PropsWithChildren> = () => {
    return (
        <Internal.Layout>
            <Article />
        </Internal.Layout>
    );
};
