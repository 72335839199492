import * as Base from "app/chat/base";
import * as State from "app/chat/state";
import axios, { CancelTokenSource } from "axios";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as React from "react";
import { useHistory } from "react-router-dom";

export const WaitingMessage: State.Message<State.MessageProps<Chat.SmileId.WaitingMessage>> = ({ value: { id } }) => {
    const history = useHistory();
    const { api } = Client.useStateContext();
    const dispatch = Chat.useDispatchContext();

    React.useEffect(() => {
        let cancelToken: CancelTokenSource | undefined;
        const intervalId = setInterval(() => {
            cancelToken = axios.CancelToken.source();
            api.with({ cancelToken: cancelToken.token })
                .user.smileId.getJob(id)
                .then((jobResponse) => {
                    cancelToken = undefined;
                    if (!jobResponse.job) {
                        // redirect to /failure so user can restart identification
                        throw new Error("Missing job.");
                    }
                    switch (jobResponse.job.status) {
                        case "Approved":
                            dispatch(new Chat.FlushAction([new Chat.SmileId.SuccessMessage()]));
                            return;
                        case "Inconclusive":
                        case "Rejected":
                        case "Error":
                            const nextMessages: Array<Chat.SmileId.Message> = [
                                new Chat.SmileId.InfoMessage(jobResponse.job.status),
                            ];
                            if (jobResponse.isSandbox) {
                                nextMessages.push(new Chat.SmileId.MockButtonMessage());
                            }
                            nextMessages.push(new Chat.SmileId.ButtonMessage());
                            dispatch(new Chat.FlushAction(nextMessages));
                            return;
                        case "Pending":
                        case "Initiated":
                            console.log(`[SmileID] Waiting for job ${id} result...`);
                            return;
                    }
                })
                .catch((error: Error) => {
                    cancelToken = undefined;
                    if (!axios.isCancel(error)) {
                        history.push("/failure");
                        return Promise.reject(error);
                    }
                });
        }, 10000);

        return () => {
            clearInterval(intervalId);
            if (cancelToken) {
                cancelToken.cancel();
            }
        };
    }, []);

    return (
        <>
            <Base.WrapMessage source="bot">
                <p>Please wait while we process your information.</p>
            </Base.WrapMessage>
            <Base.LoadMessage />
        </>
    );
};
