import * as Base from "app/chat/base";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import * as State from "../state";

const maxAttempts = 3;
export const ConfirmLoadMessage: State.Message<State.MessageProps<Chat.PhoneId.ConfirmLoadMessage>> = ({ value }) => {
    const history = useHistory();
    const onRequest = useCallback(
        (
            api: Client.Api.Instance,
            { sessionId, otpToken }: Chat.PhoneId.ConfirmLoadMessage,
        ) => {
            return api.user.phoneId.verify(sessionId, otpToken)
                .catch(() => {
                    return history.push("/failure");
                });
        }, [history]);
    const onFinish = useCallback(async (error: string | undefined) => {
        if (error) {

            if (value.attempt > maxAttempts) {
                return [
                    new Chat.FlushAction([
                        new Chat.TextMessage("The number of attempts has been exhausted. You can try again."),
                        new Chat.PhoneId.InfoMessage(),
                        new Chat.PhoneId.SelectOperatorMessage(),
                    ]),
                ];
            }
            return [
                new Chat.ReplaceAction([
                    new Chat.TextMessage(value.otpToken, "user"),
                    new Chat.TextMessage(error),
                    new Chat.PhoneId.ConfirmFormMessage(value.sessionId, value.attempt + 1),
                ]),
            ];
        }
        return [
            new Chat.ReplaceAction([
                new Chat.DelayMessage([
                    new Chat.RefreshClientStateMessage(),
                ], 10000),
            ]),
        ];
    }, [value]);

    return (<Base.DelaySubmitMessage
        onRequest={onRequest}
        onFinish={onFinish}
        value={value}/>);
};
