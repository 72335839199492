import { Job, WidgetConfig, MockDecision, JobStatus } from "../client/api/smile-id";

export class InitMessage {
    public readonly type = "face-id:init-message";
}

export class InfoMessage {
    public readonly type = "face-id:info-message";

    public constructor(public readonly status?: JobStatus) {}
}

export class ButtonMessage {
    public readonly type = "face-id:button-message";
}

export class LoadMessage {
    public readonly type = "face-id:load-message";
}

export class WidgetMessage {
    public readonly type = "face-id:widget-message";

    public constructor(
        public readonly config: WidgetConfig,
        public readonly job: Job,
    ) {}
}

export class WaitingMessage {
    public readonly type = "face-id:waiting-message";

    public constructor(public readonly id: number) {}
}

export class SuccessMessage {
    public readonly type = "face-id:success-message";
}

export class ErrorMessage {
    public readonly type = "face-id:error-message";
}

// region Sandbox only

export class MockButtonMessage {
    public readonly type = "face-id:mock-button-message";
}

export class MockLoadMessage {
    public readonly type = "face-id:mock-load-message";

    public constructor(public readonly decision: MockDecision) {}
}
export class OpenBrowserMessage {
    public readonly type = "face-id:open-browser-message";

    public constructor(
        public readonly url: string,
        public readonly jobId: number,
    ) {}
}

// endregion

export type Message =
    | InitMessage
    | InfoMessage
    | ButtonMessage
    | WidgetMessage
    | SuccessMessage
    | WaitingMessage
    | ErrorMessage
    | LoadMessage
    | MockButtonMessage
    | MockLoadMessage
    | OpenBrowserMessage;
