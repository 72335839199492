import { Capacitor } from "@capacitor/core";
import * as Chat from "modules/chat";
import * as React from "react";
import { OpenInCapacitorBrowser } from "../../../modules/components/open-in-capacitor-browser";
import { DeepLinkCloseBrowserMonoDirectDebit } from "../../app-url-listener/AppUrlListener";
import * as Base from "../base";
import * as State from "../state";

type WidgetMessageProps = State.MessageProps<Chat.DirectDebit.WidgetMessage>;
export type WidgetProps = { redirectUrl: string; };

const AppWidget: React.FC<React.PropsWithChildren<WidgetProps>> = ({ redirectUrl }) => {
    const dispatch = Chat.useDispatchContext();

    const onAppUrlOpen = React.useCallback((url: string) => {
        if (url.startsWith(DeepLinkCloseBrowserMonoDirectDebit)) {
            return dispatch(new Chat.FlushAction([
                new Chat.DirectDebit.InitMessage(),
            ]));
        }

        return dispatch(new Chat.FlushAction([
            new Chat.TextMessage("Invalid AppOpenUrl."),
        ]));
    }, [dispatch]);

    const onBrowserFinished = React.useCallback(() => {
        dispatch(new Chat.FlushAction([
            new Chat.SmileId.InitMessage,
        ]));
    }, [dispatch]);

    return (
        <OpenInCapacitorBrowser
            url={redirectUrl}
            onBrowserFinished={onBrowserFinished}
            onAppUrlOpen={onAppUrlOpen}/>
    );
};

const BrowserWidget: React.FC<React.PropsWithChildren<WidgetProps>> = ({ redirectUrl }) => {
    const handleClick = React.useCallback(() => {
        location.href = redirectUrl;
    }, [redirectUrl]);

    return (
        <Base.ButtonMessage
            source={"user"}
            className={"confirm-bank"}>
            <Base.Button
                theme="primary"
                onClick={handleClick}>
                Continue with Mono
            </Base.Button>
        </Base.ButtonMessage>
    );
};

export const WidgetMessage: React.FC<React.PropsWithChildren<WidgetMessageProps>> = ({ value }) => {
    if (Capacitor.isNativePlatform()) {
        return <AppWidget redirectUrl={value.redirectUrl}/>;
    }
    return <BrowserWidget redirectUrl={value.redirectUrl}/>;
};
