/* eslint-disable max-lines */
import "./styles.scss";

export const Layout = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="bot bot_happy"
            viewBox="0 0 296.912 271.023">
            <defs>
                <radialGradient
                    id="a"
                    cx="0.5"
                    cy="0.5"
                    r="0.5"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#fff" />
                    <stop
                        offset="0.695"
                        stopColor="#fff" />
                    <stop
                        offset="1"
                        stopColor="#f5f5f5" />
                </radialGradient>
                <filter
                    id="b"
                    x="22.685"
                    y="11.782"
                    width="251.242"
                    height="251.242"
                    filterUnits="userSpaceOnUse">
                    <feOffset dy="4" />
                    <feGaussianBlur
                        stdDeviation="15"
                        result="c" />
                    <feFlood
                        floodColor="#0081e0"
                        floodOpacity="0.302" />
                    <feComposite
                        operator="in"
                        in2="c" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <linearGradient
                    id="d"
                    x1="0.043"
                    y1="0.123"
                    x2="0.848"
                    y2="0.759"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#0b5b90" />
                    <stop
                        offset="0.269"
                        stopColor="#65c4ff" />
                    <stop
                        offset="0.755"
                        stopColor="#fff" />
                    <stop
                        offset="1"
                        stopColor="#fff" />
                </linearGradient>
                <filter
                    id="e"
                    x="129.872"
                    y="0"
                    width="167.04"
                    height="165.845"
                    filterUnits="userSpaceOnUse">
                    <feOffset dy="3" />
                    <feGaussianBlur
                        stdDeviation="10"
                        result="f" />
                    <feFlood
                        floodColor="#37a6f7"
                        floodOpacity="0.243" />
                    <feComposite
                        operator="in"
                        in2="f" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <radialGradient
                    id="g"
                    cx="0.388"
                    cy="0.44"
                    r="0.332"
                    gradientTransform="matrix(-0.615, 0.788, -1.827, -1.426, 1.532, 0.72)"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#0166ba" />
                    <stop
                        offset="0.3"
                        stopColor="#2795f4" />
                    <stop
                        offset="1"
                        stopColor="#93d5ff" />
                </radialGradient>
                <linearGradient
                    id="h"
                    x1="0.038"
                    y1="0.895"
                    x2="0.84"
                    y2="0.234"
                    xlinkHref="#d" />
                <filter
                    id="i"
                    x="0"
                    y="0"
                    width="167.04"
                    height="165.845"
                    filterUnits="userSpaceOnUse">
                    <feOffset dy="3" />
                    <feGaussianBlur
                        stdDeviation="10"
                        result="j" />
                    <feFlood
                        floodColor="#37a6f7"
                        floodOpacity="0.243" />
                    <feComposite
                        operator="in"
                        in2="j" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <radialGradient
                    id="k"
                    cx="0.36"
                    cy="0.591"
                    r="0.402"
                    gradientTransform="matrix(-0.457, -0.889, 2.349, -1.208, -1.185, 1.707)"
                    xlinkHref="#g" />
                <radialGradient
                    id="l"
                    cx="0.5"
                    cy="0.533"
                    r="0.5"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#fff" />
                    <stop
                        offset="0.72"
                        stopColor="#fff" />
                    <stop
                        offset="1"
                        stopColor="#bce7ff" />
                </radialGradient>
                <filter
                    id="m"
                    x="13.685"
                    y="1.567"
                    width="269.242"
                    height="269.456"
                    filterUnits="userSpaceOnUse">
                    <feOffset dy="3" />
                    <feGaussianBlur
                        stdDeviation="18"
                        result="n" />
                    <feFlood
                        floodColor="#1098ff"
                        floodOpacity="0.098" />
                    <feComposite
                        operator="in"
                        in2="n" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <radialGradient
                    id="o"
                    cx="0.5"
                    cy="0.624"
                    r="0.506"
                    gradientTransform="translate(0.081 -0.069) rotate(8.622)"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#fff" />
                    <stop
                        offset="0.578"
                        stopColor="#fff"
                        stopOpacity="0" />
                    <stop
                        offset="1"
                        stopColor="#fff"
                        stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="p"
                    x1="0.485"
                    y1="0.266"
                    x2="0.809"
                    y2="1.019"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#f5fcff" />
                    <stop
                        offset="1"
                        stopColor="#ccefff" />
                </linearGradient>
                <filter
                    id="q"
                    x="48.012"
                    y="126.242"
                    width="56.896"
                    height="82.354"
                    filterUnits="userSpaceOnUse">
                    <feOffset
                        dx="2"
                        dy="2" />
                    <feGaussianBlur
                        stdDeviation="2"
                        result="r" />
                    <feFlood
                        floodColor="#1e91f4"
                        floodOpacity="0.447" />
                    <feComposite
                        operator="in"
                        in2="r" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <radialGradient
                    id="s"
                    cx="0.411"
                    cy="0.229"
                    r="0.714"
                    gradientTransform="matrix(-0.784, 0.621, -0.22, -0.278, 0.784, 0.037)"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#72d9ff" />
                    <stop
                        offset="0.587"
                        stopColor="#009dff" />
                    <stop
                        offset="0.852"
                        stopColor="#0079dc" />
                    <stop
                        offset="1"
                        stopColor="#006cc9" />
                </radialGradient>
                <linearGradient
                    id="t"
                    x1="0.904"
                    y1="0.006"
                    x2="0.135"
                    y2="1.003"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#fff" />
                    <stop
                        offset="0.32"
                        stopColor="#f8fcff" />
                    <stop
                        offset="1"
                        stopColor="#006e9f" />
                </linearGradient>
                <filter
                    id="v"
                    x="191.206"
                    y="126.243"
                    width="56.897"
                    height="82.355"
                    filterUnits="userSpaceOnUse">
                    <feOffset
                        dx="-2"
                        dy="2" />
                    <feGaussianBlur
                        stdDeviation="2"
                        result="w" />
                    <feFlood
                        floodColor="#1e91f4"
                        floodOpacity="0.447" />
                    <feComposite
                        operator="in"
                        in2="w" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <radialGradient
                    id="x"
                    cx="0.411"
                    cy="0.771"
                    r="0.714"
                    gradientTransform="matrix(-0.784, -0.621, -0.22, 0.278, 0.903, 0.812)"
                    xlinkHref="#s" />
                <linearGradient
                    id="y"
                    x1="0.904"
                    y1="0.994"
                    x2="0.135"
                    y2="-0.003"
                    xlinkHref="#t" />
                <linearGradient
                    id="z"
                    x1="0.5"
                    x2="0.5"
                    y2="1"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#9dd2ff" />
                    <stop
                        offset="1"
                        stopColor="#00a8ff" />
                </linearGradient>
                <linearGradient
                    id="aa"
                    x1="0.5"
                    x2="0.5"
                    y2="0.918"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#f2f9ff" />
                    <stop
                        offset="0.155"
                        stopColor="#71c1ff" />
                    <stop
                        offset="0.649"
                        stopColor="#6cc6ff" />
                    <stop
                        offset="1"
                        stopColor="#d8f1ff" />
                </linearGradient>
                <radialGradient
                    id="ab"
                    cx="0.5"
                    cy="0.5"
                    r="0.986"
                    gradientTransform="translate(0.182 0.17) scale(0.637 0.66)"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#21bbf4" />
                    <stop
                        offset="0.842"
                        stopColor="#58b2ff" />
                    <stop
                        offset="1"
                        stopColor="#0065bc" />
                </radialGradient>
                <radialGradient
                    id="ac"
                    cx="0.514"
                    cy="0.714"
                    r="0.512"
                    gradientTransform="matrix(0.996, -0.094, 0.075, 0.799, -0.052, 0.192)"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#b5ebff" />
                    <stop
                        offset="0.655"
                        stopColor="#009dff" />
                    <stop
                        offset="0.906"
                        stopColor="#11649d" />
                    <stop
                        offset="1"
                        stopColor="#034b81" />
                </radialGradient>
                <linearGradient
                    id="ad"
                    x1="0.5"
                    x2="0.5"
                    y2="1"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#d5f0ff"
                        stopOpacity="0.867" />
                    <stop
                        offset="1"
                        stopColor="#739eff"
                        stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="ae"
                    cx="0.423"
                    cy="0.304"
                    r="0.577"
                    gradientTransform="matrix(1, 0, 0, 1.206, 0, -0.063)"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#fff" />
                    <stop
                        offset="0.72"
                        stopColor="#fff"
                        stopOpacity="0" />
                    <stop
                        offset="1"
                        stopColor="#fff"
                        stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="af"
                    x1="0.235"
                    y1="0.977"
                    x2="0.645"
                    y2="0.09"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#fff"
                        stopOpacity="0" />
                    <stop
                        offset="0.256"
                        stopColor="#fff"
                        stopOpacity="0.725" />
                    <stop
                        offset="1"
                        stopColor="#fff"
                        stopOpacity="0" />
                </linearGradient>
                <linearGradient
                    id="ag"
                    x1="0.5"
                    y1="0.945"
                    x2="0.5"
                    gradientUnits="objectBoundingBox">
                    <stop
                        offset="0"
                        stopColor="#33c0ff" />
                    <stop
                        offset="0.608"
                        stopColor="#0d69af" />
                    <stop
                        offset="1"
                        stopColor="#004c85" />
                </linearGradient>
                <filter
                    id="ah"
                    x="127.561"
                    y="154.292"
                    width="43.451"
                    height="33.558"
                    filterUnits="userSpaceOnUse">
                    <feOffset />
                    <feGaussianBlur
                        stdDeviation="1"
                        result="ai" />
                    <feFlood
                        floodColor="#02a7ff"
                        floodOpacity="0.776" />
                    <feComposite
                        operator="in"
                        in2="ai" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter
                    id="aj"
                    x="88.88"
                    y="107.781"
                    width="49.151"
                    height="33.137"
                    filterUnits="userSpaceOnUse">
                    <feOffset />
                    <feGaussianBlur
                        stdDeviation="2"
                        result="ak" />
                    <feFlood floodColor="#fff" />
                    <feComposite
                        operator="in"
                        in2="ak" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter
                    id="al"
                    x="159.543"
                    y="107.768"
                    width="49.212"
                    height="33.133"
                    filterUnits="userSpaceOnUse">
                    <feOffset />
                    <feGaussianBlur
                        stdDeviation="2"
                        result="am" />
                    <feFlood floodColor="#fff" />
                    <feComposite
                        operator="in"
                        in2="am" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g transform="translate(30.011 28)">
                <g transform="translate(-0.779 -1)">
                    <g
                        transform="matrix(1, 0, 0, 1, -29.23, -27)"
                        filter="url(#b)">
                        <circle
                            cx="80.621"
                            cy="80.621"
                            r="80.621"
                            transform="translate(67.69 52.78)"
                            fill="url(#a)" />
                    </g>
                    <g transform="translate(0 0)">
                        <g
                            id="ear-right"
                            transform="matrix(0.98480775301221 -0.17364817766693 0.17364817766693 0.98480775301221 112.10715426017710 1.66855980849802)">
                            <g
                                transform="matrix(1, 0, 0, 1, -159.87, -27)"
                                filter="url(#e)">
                                <path
                                    d="M45.967,74.238C27.963,67.064,10.159,34.8.686,8.363S83.033,26.2,78.841,30.949C57.228,69.443,57.943,77.569,45.967,74.238Z"
                                    transform="matrix(-0.84, -0.54, 0.54, -0.84, 226.11, 132.84)"
                                    fill="url(#d)"
                                />
                            </g>
                            <path
                                d="M45.47,70.839C33.3,65.99,13.289,37.943,0,0,10.414,19.117,27.574,36.913,36.92,47.284,50.092,63.163,57.445,74.17,45.47,70.839Z"
                                transform="matrix(-0.839, -0.545, 0.545, -0.839, 68.901, 102.722)"
                                fill="url(#g)"
                            />
                            <path
                                d="M2.083-1.682C-5.91,6.876,10.385,54,7.028,85.83"
                                transform="translate(62.003 17.315)"
                                fill="none"
                                stroke="#d8edff"
                                strokeLinecap="round"
                                strokeWidth="1" />
                        </g>
                        <g
                            id="ear-left"
                            transform="matrix(0.99026806874157 -0.13917310096007 0.13917310096007 0.99026806874157 -13.75594952985993 16.10774186656960)">
                            <g
                                transform="matrix(1, 0, 0, 1, -29.23, -27)"
                                filter="url(#i)">
                                <path
                                    d="M45.968.677C27.963,7.851,10.159,40.111.686,66.552S83.033,48.713,78.841,43.966C57.228,5.472,57.943-2.654,45.968.677Z"
                                    transform="matrix(0.84, -0.54, 0.54, 0.84, 30, 70.02)"
                                    fill="url(#h)"
                                />
                            </g>
                            <path
                                d="M45.47.6C33.3,5.446,13.289,33.493,0,71.436,10.414,52.319,27.574,34.523,36.92,24.151,50.092,8.272,57.445-2.735,45.47.6Z"
                                transform="matrix(0.839, -0.545, 0.545, 0.839, 0, 42.812)"
                                fill="url(#k)"
                            />
                            <path
                                d="M5.258-1.682C13.251,6.876-3.044,54,.313,85.831"
                                transform="translate(38.465 17.315)"
                                fill="none"
                                stroke="#d8edff"
                                strokeLinecap="round"
                                strokeWidth="1" />
                        </g>
                    </g>
                    <g
                        transform="matrix(1, 0, 0, 1, -29.23, -27)"
                        filter="url(#m)">
                        <path
                            d="M80.831-.174c44.526,0,80.41,36.309,80.41,80.835A80.621,80.621,0,1,1,80.831-.174Z"
                            transform="translate(67.69 52.74)"
                            fill="url(#l)" />
                    </g>
                    <path
                        d="M19.18,66.212c3.188-3.135-6.416-7.785-2.573-10.261,4.018-2.272,11.741,15.372,45.247,15.728"
                        transform="translate(57.272 108.328)"
                        fill="none"
                        stroke="#2fb6ef"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                        opacity="0.647"
                    />
                    <path
                        d="M58.93,66.212c-3.188-3.135,6.416-7.786,2.573-10.261C57.484,53.679,49.761,71.324,16.255,71.68"
                        transform="translate(102.881 108.328)"
                        fill="none"
                        stroke="#2fb6ef"
                        strokeLinecap="round"
                        strokeWidth="0.5"
                        opacity="0.647"
                    />
                    <path
                        d="M56.043,7.553c17.16-.13,49.445-10.813,49.445-3.685S84.4,23.095,56.043,23.443,2.173,11,2.173,3.868,38.883,7.682,56.043,7.553Z"
                        transform="translate(65.993 165.403)"
                        opacity="0.996"
                        fill="url(#o)"
                    />
                </g>
                <g transform="translate(21.367 102.242)">
                    <g transform="translate(0 0)">
                        <g
                            transform="matrix(1, 0, 0, 1, -51.38, -130.24)"
                            filter="url(#q)">
                            <path
                                d="M17.046.158C13.092,5.721,9.381,27.207,9.261,38.1a81.287,81.287,0,0,0,6.145,30.527c-.1.224-.119,2.316-1.954-.1C7.5,60.7-.694,39.312.047,21.737S14.47-1.016,17.046.158Z"
                                transform="translate(52.01 137.45) rotate(-25)"
                                fill="url(#p)"
                            />
                        </g>
                        <path
                            d="M13.467.812C15.228,2.436,8.123,15.276,6.438,26.2A71.85,71.85,0,0,0,5.6,43.829c.056.153.22,1.329-1.02-.136C.386,37.78-1.437,20.809,1.3,9.938,4.042-.987,11.706-.812,13.467.812Z"
                            transform="translate(0 7.459) rotate(-25)"
                            fill="url(#s)"
                        />
                        <path
                            d="M10.412.288C12.5,1.384,3.91,2.861,2.219,13.4S.551,35.9,3.86,42.345C-2.377,31.165.8,11.6.869,11.281,3.053.895,8.328-.809,10.412.288Z"
                            transform="translate(1.074 7.928) rotate(-25)"
                            opacity="0.485"
                            fill="url(#t)"
                        />
                    </g>
                    <g transform="translate(147.828 0.001)">
                        <g
                            transform="matrix(1, 0, 0, 1, -199.21, -130.24)"
                            filter="url(#v)">
                            <path
                                d="M17.046,69.521c-3.954-5.563-7.665-27.049-7.785-37.939A81.288,81.288,0,0,1,15.406,1.054c-.1-.224-.119-2.316-1.954.1C7.5,8.979-.694,30.367.047,47.941S14.47,70.7,17.046,69.521Z"
                                transform="translate(214.65 200.6) rotate(-155)"
                                fill="url(#p)"
                            />
                        </g>
                        <path
                            d="M13.467,43.609c1.761-1.624-5.344-14.464-7.03-25.391A71.851,71.851,0,0,1,5.6.591c.056-.153.22-1.329-1.02.136C.386,6.64-1.437,23.612,1.3,34.483,4.042,45.408,11.706,45.233,13.467,43.609Z"
                            transform="translate(26.758 47.718) rotate(-155)"
                            fill="url(#x)"
                        />
                        <path
                            d="M10.412,42.058c2.084-1.1-6.5-2.573-8.194-13.111S.551,6.443,3.86,0C-2.377,11.181.8,30.747.869,31.065,3.053,41.451,8.328,43.154,10.412,42.058Z"
                            transform="translate(26.562 46.306) rotate(-155)"
                            opacity="0.485"
                            fill="url(#y)"
                        />
                    </g>
                </g>
                <g
                    transform="translate(37.713 25.064)"
                    opacity="0.997">
                    <g
                        transform="translate(0 2.059)"
                        opacity="0.518">
                        <path
                            d="M15.507,86.874c0-19.761,18.466-74.411,79.564-74.411s81.755,55.543,81.755,74.411-5.5,32-27.8,32-37.108-14.2-52.874-13.768-29.1,13.768-52.685,13.768S15.507,106.635,15.507,86.874Z"
                            transform="translate(-15.507 -12.462)"
                            opacity="0.395"
                            fill="url(#z)"
                        />
                        <path
                            d="M15.612,85.427c0-19.377,18.952-72.853,80.514-72.853s80.445,55.006,80.445,73.507-6.5,31.932-28.8,31.932-35.879-13.926-51.645-13.5-26.638,13.5-50.226,13.5S15.612,104.8,15.612,85.427Z"
                            transform="translate(-15.483 -12.437)"
                            fill="url(#aa)"
                        />
                    </g>
                    <g transform="translate(4.165 4.664)">
                        <path
                            d="M14.475,83.477c0-18.967,16.5-68.9,76.6-68.9s76.6,50.785,76.6,68.9-8.6,31.9-29.452,31.9-30.581-12.011-47.145-12.011-23.76,12.011-45.818,12.011S14.475,102.444,14.475,83.477Z"
                            transform="translate(-14.475 -14.582)"
                            fill="url(#ab)"
                        />
                        <path
                            d="M14.475,80.957c0-17.091,20.44-66.375,73.75-66.375s73.749,49.253,73.749,66.375-11.689,28.2-30,31.438-27.789-12.281-43.746-12.281S67.247,113.468,49.159,112.4,14.475,98.048,14.475,80.957Z"
                            transform="translate(-11.648 -14.581)"
                            fill="url(#ac)"
                        />
                    </g>
                    <path
                        d="M48.631.039c28.224,0,45.515,13.424,50,22.771,4.551,10.848-18.554,19.973-50,20.166-27.1.167-51.138-9.86-49.072-20.456C2.189,12.547,22.721.039,48.631.039Z"
                        transform="translate(32.111 8.396)"
                        fill="url(#ad)"
                    />
                    <path
                        d="M46.241,12.861a19.128,19.128,0,0,1,2.4,9.389c0,13.916-9.9,24.6-23.476,24.6-9.3,0-18.5-4.7-22.849-12.832.233.085,8.3-9.025,20.006-13.942A128.287,128.287,0,0,1,46.241,12.861Z"
                        transform="translate(24.792 -12.861)"
                        opacity="0.782"
                        fill="url(#ae)"
                    />
                    <path
                        d="M28.651,94.664c-11.309-6.808-10.01-37.833,7.918-63.479C42.046,23.726,59.125,13.3,59.1,14.822c-11.4,22.6-12.339,49.01-2.047,86.418C47.019,104.846,31.007,96.822,28.651,94.664Z"
                        transform="translate(-6.227 -0.682)"
                        opacity="0.546"
                        fill="url(#af)"
                    />
                </g>
                <g transform="translate(64.817 99.839)">
                    <g
                        id="mouth"
                        transform="matrix(1 0 0 1 35.73300000000000 29.45300000000000)">
                        <g
                            transform="matrix(1, 0, 0, 1, -130.56, -157.29)"
                            filter="url(#ah)">
                            <path
                                d="M171.565,119.737a68.239,68.239,0,0,0-18.78-3.124,66.973,66.973,0,0,0-18.67,3.124s-.452,24.434,18.67,24.434S171.565,119.737,171.565,119.737Z"
                                transform="translate(-3.55 40.68)"
                                fill="url(#ag)"
                            />
                        </g>
                        <path
                            d="M136.978,131.526a17.445,17.445,0,0,0,14.993,7.826c6.775,0,11.4-3.1,14.843-7.574C158.274,138.542,147.389,140.627,136.978,131.526Z"
                            transform="translate(-133.212 -111.794)"
                            fill="#8dd6ff"
                            opacity="0.683"
                        />
                    </g>
                    <g
                        id="eyes"
                        transform="matrix(1, 0, 0, 1, 0, 0)">
                        <g
                            transform="matrix(1, 0, 0, 1, -94.83, -127.84)"
                            filter="url(#aj)">
                            <path
                                d="M245.253,72.048c.939.6,3.386-14.31,17.408-16.134S279.7,70.446,281.868,69.4s-5.514-21-20.286-18.2S244.314,71.45,245.253,72.048Z"
                                transform="translate(-150.2 62.85)"
                                fill="#fff"
                            />
                        </g>
                        <g
                            transform="matrix(1, 0, 0, 1, -94.83, -127.84)"
                            filter="url(#al)">
                            <path
                                d="M280.664,72.048c-1.341.2-3.386-14.31-17.408-16.134S246,70.345,244.048,69.4s5.436-21.017,20.208-18.21S282.005,71.848,280.664,72.048Z"
                                transform="translate(-78.2 62.85)"
                                fill="#fff"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

Layout.displayName = "BotHappy.Layout";
