import * as Client from "modules/client";
import { getPlatform } from "modules/utils/get-platform";
import * as React from "react";
import { ControlProps } from "./control";

export type TextControlProps = ControlProps<Client.Api.JSONSchemaString> & {
    filter?: (value: string, prevValue?: string) => string;
    inputProps?: React.HTMLProps<HTMLInputElement>;
};

function getInputType(schema: Client.Api.JSONSchemaString) {
    switch (schema.format) {
        case "email":
            return "email";
        case "date":
            if (getPlatform() === "iPhone" || getPlatform() === "iPad") {
                return "date";
            }
        default:
            return "text";
    }
}

export const TextControl: React.FC<React.PropsWithChildren<TextControlProps>> = (
    { attribute, schema, value, onChange, filter, id, inputProps = {} },
) => {
    const prevValueRef = React.useRef<string>("");
    const prevValue = prevValueRef.current;
    const handleChange = React.useCallback(
        ({ currentTarget: { value: newValue } }: React.ChangeEvent<HTMLInputElement>) => {
            const result = filter ? filter(newValue, prevValue) : newValue;
            onChange(result);
            prevValueRef.current = result;
        },
        [onChange, filter, prevValueRef.current],
    );

    return (
        <input
            id={id}
            className="form__control"
            type={getInputType(schema)}
            name={attribute}
            placeholder={schema.examples?.[0] ?? schema.title}
            title={schema.title}
            value={value}
            onChange={handleChange}
            autoCorrect="on"
            autoFocus
            required
            {...inputProps}
        />
    );
};
