import { MessageProps } from "app/chat/state/message";
import * as Chat from "modules/chat";
import * as React from "react";
import { LoadMessage } from "./load-message";

export const DelayMessage: React.FC<React.PropsWithChildren<MessageProps<Chat.DelayMessage>>> = (props) => {
    const dispatch = Chat.useDispatchContext();
    React.useEffect(() => {
        const id = setTimeout(() => dispatch(new Chat.ReplaceAction(props.value.children)), props.value.timeout);

        return () => clearTimeout(id);
    }, [props.value.children, props.value.timeout]);
    return <LoadMessage />;
};
