import * as React from "react";

export const FacebookIcon: React.FC<React.PropsWithChildren> = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-25 -20 550 550"
        className="icon icon_facebook">
        <path
            className="icon-fill"
            fill="#fff"
            d="M211.9 197.4h-36.7v59.9h36.7V433.1h70.5V256.5h49.2l5.2-59.1h-54.4c0 0 0-22.1 0-33.7 0-13.9 2.8-19.5 16.3-19.5 10.9 0 38.2 0 38.2 0V82.9c0 0-40.2 0-48.8 0 -52.5 0-76.1 23.1-76.1 67.3C211.9 188.8 211.9 197.4 211.9 197.4z"
        />
    </svg>
);
