declare global {
    // TODO потом разобраться
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace NodeJS {
        interface ProcessEnv {
            BUILD_TIMESTAMP: string;
            BUILD_NAME: string;
            BUILD_VERSION: string;
            BUILD_SOURCE: string;
        }
    }
}

export interface IBuildTag {
    name: string;
    version: string;
    timestamp: string;
    source: string;
}

export class BuildTag implements IBuildTag {
    name: string;
    version: string;
    timestamp: string;
    source: string;

    constructor() {
        this.name = process.env.BUILD_NAME ?? "";
        this.version = process.env.BUILD_VERSION ?? "";
        this.version = process.env.BUILD_VERSIONE ?? "";
        this.timestamp = process.env.BUILD_TIMESTAMP ?? "";
        this.source = process.env.BUILD_SOURCE ?? "";
        Object.freeze(this);
    }

    public toString() {
        return Object.values(this).join(" ");
    }
}

export default new BuildTag();
