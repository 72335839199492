import * as Base from "app/chat/base";
import * as State from "app/chat/state";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as React from "react";
import { DecisionType } from "../../../modules/chat/decision";
import { DecisionResponse } from "../../../modules/client/api/decision";

export const LoadMessage: State.Message<
    State.MessageProps<Chat.Decision.LoadMessage>
> = ({ value: { decision } }) => {
    const handleRequest = React.useCallback((api: Client.Api.Instance, decisionData: DecisionType) => {
        return api.user.decision.post(decisionData);
    }, []);

    const handleResponse = React.useCallback(
        async (response: Promise<DecisionResponse>) => {
            await response; // currently always returns success so no need to validate result

            return new Chat.FlushAction([
                new Chat.Agreement.AgreementCompleteMessage(),
            ]);
        },
        [],
    );

    return (
        <Base.DelaySubmitMessage
            value={decision}
            onRequest={handleRequest}
            onFinish={handleResponse}
        />
    );
};
