import * as React from "react";
import { IconBobra } from "../Icons/IconBobra";
import { IconSho } from "../Icons/IconSho";

export const Author: React.FC<React.PropsWithChildren> = () => {
    return (
        <div className="author">
            <a
                href="https://wearesho.com/"
                target="_blank">
                <IconSho />
            </a>
            <span className="line" />
            <a
                href="https://wearesho.com/bobra-cs"
                target="_blank">
                <IconBobra />
            </a>
        </div>
    );
};
Author.displayName = "Footer.Author";
