import * as Chat from "modules/chat";
import * as State from "../state";
import { ButtonMessage } from "./button-message";
import { LoadMessage } from "./load-message";

export function MessageComponentFactory(
    type: Chat.Decision.Message["type"],
): State.Message | undefined {
    switch (type) {
        case "decision:load-message":
            return LoadMessage;
        case "decision:button-message":
            return ButtonMessage;
    }
}
