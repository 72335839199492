import * as React from "react";
import * as Base from "../base";

export const ReauthInfoMessage: React.FC<React.PropsWithChildren> = () => {
    return (
        <Base.WrapMessage source="bot">
            <p>We apologize for the inconvenience, but an error occurred during the verification of your bank account.
                To proceed further, we kindly request you to verify your bank account again.</p>
            <p>
                If the issue persists, you may need to retry the verification after some time.
            </p>
            <p>
                You may also consider trying the verification process with your account at another bank.
            </p>
        </Base.WrapMessage>
    );
};
