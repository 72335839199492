export class InfoMessage {
    public readonly type = "phone-id:info";
}

export class SelectOperatorMessage {
    public readonly type = "phone-id:select-operator";
}

export class ConfirmFormMessage {
    public readonly type = "phone-id:confirm-form";

    constructor(
        public readonly sessionId: string,
        public readonly attempt: number = 1,
    ) {}
}

export class ConfirmLoadMessage {
    public readonly type = "phone-id:confirm-load";

    constructor(
        public readonly sessionId: string,
        public readonly otpToken: string,
        public readonly attempt: number,
    ) {}
}

export type Message = InfoMessage | SelectOperatorMessage | ConfirmFormMessage | ConfirmLoadMessage;
