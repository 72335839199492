export const NavArrow = () => (
    <svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon_nav-arrow"
    >
        <path
            d="M0.9234 0.895425L1.09052 1.08136L0.923401 0.895425C1.13913 0.701525 1.47897 0.701525 1.6947 0.895425L1.52795 1.08095L1.6947 0.895425L7.16712 5.81407L7.37399 6L7.16712 6.18593L1.6947 11.1046L1.52759 10.9186L1.6947 11.1046C1.47897 11.2985 1.13913 11.2985 0.923401 11.1046C0.6922 10.8968 0.6922 10.5477 0.9234 10.3399L5.75188 6L0.9234 1.66013C0.6922 1.45233 0.6922 1.10323 0.9234 0.895425Z"
            fill="white"
            stroke="white"
            strokeWidth="0.5"
            strokeLinecap="round"
            className="icon-fill"
        />
    </svg>
);
