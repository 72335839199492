import * as State from "app/chat/state";
import * as Chat from "modules/chat";
import * as React from "react";
import * as Base from "../base";

export const CompleteButtonMessage: State.Message<State.MessageProps<Chat.Card.CompleteButtonMessage>> = () => {
    const dispatch = Chat.useDispatchContext();

    const handleConfirm = React.useCallback(() => {
        location.reload();
    }, [dispatch]);

    return (
        <Base.ButtonMessage
            source={"user"}
            className={"confirm-bank"}>
            <Base.Button
                theme="primary"
                onClick={handleConfirm}>
                Confirm
            </Base.Button>
        </Base.ButtonMessage>
    );
};
