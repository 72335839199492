import { PhoneList } from "app/chat/base/phone-list";
import * as React from "react";
import * as Icon from "./icons";

const Email: React.FC<React.PropsWithChildren<{ value: string; }>> = ({ value }) => {
    return (
        <li>
            <Icon.Email />
            <div className="align">
                <h4>Email</h4>
                <a href={`mailto:${value}`}>{value}</a>
            </div>
        </li>
    );
};

const Phones: React.FC<React.PropsWithChildren> = () => {
    return (
        <li>
            <Icon.Phone />
            <div className="align">
                <h4>Phone</h4>
                <PhoneList />
            </div>
        </li>
    );
};

export const Contacts = () => {
    return (
        <ul className="contacts">
            <Email value="info@kobogo.ng" />
            <Phones />
        </ul>
    );
};
