import { AxiosError, AxiosInstance } from "axios";

export const Api = (endpoint: AxiosInstance) => {
    return {
        get: (id: number): Promise<string> => {
            return endpoint
                .get<{ url: string; }>("/v2/restructuring/agreement", {
                    params: {
                        applicationId: id,
                    },
                })
                .then(({ data: { url } }) => url);
        },
        refuse: async (): Promise<void> => {
            return endpoint.post("/v2/restructuring/refuse").then(() => undefined);
        },
        sign: async (token: string): Promise<string | boolean> => {
            const response = await endpoint.post<{ message?: string; errors: [{ details: string; }]; }>(
                "/v2/restructuring/sign",
                {
                    Restructuring: { token },
                },
                {
                    validateStatus: status => [202, 429, 400].includes(status),
                },
            );
            return response.status === 202 || response.data.errors[0].details || "Неизвестная ошибка";
        },
        send: async (): Promise<string | true> => {
            return endpoint
                .put<{}>("/v2/restructuring/send")
                .then(() => true)
                .catch((error: AxiosError) => error?.response?.data.message ?? Promise.reject(error));
        },
    } as const;
};
