import * as Info from "app/info/Layout";
import * as React from "react";

const Article = React.lazy(() => import(/* webpackChunkName: "mono-auth" */ "./Layout"));
export const Page: React.FC<React.PropsWithChildren> = () => {
    return (
        <Info.Layout>
            <Article />
        </Info.Layout>
    );
};
