import * as Chat from "modules/chat";
import { ButtonEdit } from "../button-edit";
import { useHandleDataChange } from "../registration/use-handle-data-change";
import * as State from "../state";
import { WrapMessage } from "./wrap-message";

export const TextMessageEditable: State.Message<State.MessageProps<Chat.TextMessageEditable>> = (props) => {
    const handleChange = useHandleDataChange(props.value.content, props.value.attribute);

    return (
        <WrapMessage
            source="user"
            at={new Date().toISOString()}>
            <p>{props.value.content}</p>
            <ButtonEdit
                onClick={handleChange}
                id={`edit-${props.value.attribute.name}-btn`} />
        </WrapMessage>
    );
};
