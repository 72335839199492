import * as Client from "modules/client";
import * as React from "react";
import { Image } from "./image";

import "./styles.scss";

export const Layout = Client.withApi(({ api }) => {
    const [photo, setPhoto] = React.useState<File | undefined>(undefined);

    const dispatch = Client.useDispatchContext();

    const handleImageChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();

            const reader = new FileReader();

            if (!e.target.files) {
                return;
            }

            const file: File = e.target.files[0];

            reader.onloadend = () => {
                setPhoto(file);
            };
            reader.readAsDataURL(file);
        },
        [setPhoto],
    );

    React.useEffect(() => {
        if (!photo) {
            return;
        }

        api.user.profile.avatar(photo).then(() => {
            dispatch(new Client.Auth.RefreshAction());
        });
    }, [photo]);

    return (
        <div className="avatar-wrap">
            <div className="avatar">
                <input
                    className="file-input"
                    type="file"
                    onChange={handleImageChange} />
                <Image />
            </div>
        </div>
    );
});

Layout.displayName = "Avatar.Layout";
