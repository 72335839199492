import * as Base from "app/chat/base";
import * as State from "app/chat/state";
import axios from "axios";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as React from "react";
import { useHistory } from "react-router-dom";

export const MockLoadMessage: State.Message<State.MessageProps<Chat.SmileId.MockLoadMessage>> = (
    { value: { decision } },
) => {
    const history = useHistory();
    const { api } = Client.useStateContext();
    const dispatch = Chat.useDispatchContext();

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        api.with({ cancelToken: cancelToken.token })
            .user.smileId.createMock(decision)
            .then((mockResponse) => {
                dispatch(new Chat.FlushAction([new Chat.SmileId.WaitingMessage(mockResponse.job.id)]));
            })
            .catch((error: Error) => {
                if (!axios.isCancel(error)) {
                    history.push("/failure");
                    return Promise.reject(error);
                }
            });
        return () => cancelToken.cancel();
    }, [decision]);

    return <Base.LoadMessage />;
};
