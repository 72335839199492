import * as React from "react";
import * as Chat from "../../../modules/chat";
import { IconArrow } from "../../../modules/components/Icons/IconArrow";
import * as Base from "../base";

const completeText = "Continue";

export const ConfirmMessage: React.FC<React.PropsWithChildren> = () => {
    const dispatch = Chat.useDispatchContext();
    const handleConfirm = React.useCallback(() => {
        dispatch(
            new Chat.ReplaceAction([
                new Chat.DirectDebit.LoadMessage(),
            ]),
        );
    }, [dispatch]);

    return (
        <Base.ButtonMessage source={"user"}>
            <Base.Button
                id={"complete-registration-submit"}
                theme="secondary"
                onClick={handleConfirm}
            >
                <span>{completeText}</span>
                <IconArrow />
            </Base.Button>
        </Base.ButtonMessage>
    );
};
