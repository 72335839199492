import * as Chat from "modules/chat";
import { IconArrow } from "modules/components/Icons/IconArrow";
import { useCallback } from "react";
import * as Base from "../base";

export const ConfirmRequestMessage = () => {
    const dispatch = Chat.useDispatchContext();

    const handleCanceled = useCallback(() => {
        dispatch(
            new Chat.ReplaceAction([
                new Chat.TextMessage("What if I refuse?", "user"),
                new Chat.TextMessage("Unfortunately, we will not be able to transfer you the approved amount of money.", "bot"),
                new Chat.Agreement.AgreementRefuseFormMessage(),
            ]),
        );
    }, [dispatch]);

    const handleConfirm = useCallback(() => {
        dispatch(new Chat.ReplaceAction([new Chat.Agreement.AgreementSendMessage()]));
    }, [dispatch]);

    return (
        <Base.ButtonMessage source={"user"}>
            <Base.Button
                theme="primary"
                onClick={handleCanceled}>
                What if I refuse?
            </Base.Button>
            <Base.Button
                id="confirm-agreement"
                theme="secondary"
                className="long"
                onClick={handleConfirm}>
                <span>Confirm</span>
                <IconArrow />
            </Base.Button>
        </Base.ButtonMessage>
    );
};
