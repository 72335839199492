import { ContactPayload } from "../../app/chat/share-contacts/mock.type";
import { ContactsState } from "../client/api/contacts-api";

export class ShareContactsMessage {
    public readonly type = "contacts";

    constructor(public readonly state: ContactsState) {}
}

export class ShareContactsInitMessage {
    public readonly type = "contacts:init";
}

export class ShareContactsNotEnough {
    public readonly type = "contacts:not-enough";
}

export class ShareContactsDisabled {
    public readonly type = "contacts:disabled";
}

export class ShareContactsLoadMessage {
    public readonly type = "contacts:load-message";

    constructor(public readonly contacts: ContactPayload[]) {}
}

export type Message =
    | ShareContactsMessage
    | ShareContactsNotEnough
    | ShareContactsDisabled
    | ShareContactsLoadMessage
    | ShareContactsInitMessage
    ;
