import * as Chat from "modules/chat";
import * as State from "../state";
import { CompleteButtonMessage } from "./complete-button-message";
import { CompleteMessage } from "./complete-message";
import { ConfirmMessage } from "./confirm-message";
import { FailureMessage } from "./failure-message";
import { InitMessage } from "./init-message";
import { LoadCompleteMessage } from "./load-complete-message";
import { LoadRedirectMessage } from "./load-redirect-message";
import { RedirectMessage } from "./redirect-message";

export function MessageComponentFactory(type: Chat.Card.Message["type"]): State.Message | undefined {
    switch (type) {
        case "card:init-message":
            return InitMessage;
        case "card:confirm-message":
            return ConfirmMessage;
        case "card:load-redirect-message":
            return LoadRedirectMessage;
        case "card:redirect-message":
            return RedirectMessage;
        case "card:failure-message":
            return FailureMessage;
        case "card:load-complete-message":
            return LoadCompleteMessage;
        case "card:complete-message":
            return CompleteMessage;
        case "card:complete-button-message":
            return CompleteButtonMessage;
    }
}
