import * as Base from "app/chat/base";
import * as State from "app/chat/state";
import { useLocale } from "app/locale";
import * as Chat from "modules/chat";
import * as React from "react";

type ConfirmMessageProps = State.MessageProps<Chat.BankId.ConfirmMessage>;

export const ConfirmMessage: React.FC<React.PropsWithChildren<ConfirmMessageProps>> = ({ value: { bank } }) => {
    const { t } = useLocale("registration");
    const dispatch = Chat.useDispatchContext();

    const handleConfirm = React.useCallback(() => {
        if (bank) {
            dispatch([
                new Chat.ReplaceAction([], 1),
                new Chat.ReplaceAction([
                    new Chat.EditableMessage(
                        bank.name,
                        new Chat.ReplaceAction([new Chat.RefreshClientStateMessage()]),
                    ),
                    new Chat.BankId.LoadMessage(bank.id),
                ], 1),
            ]);
        }
    }, [bank, dispatch]);

    return (
        <Base.ButtonMessage
            source={"user"}
            className={"confirm-bank"}>
            <Base.Button
                theme="secondary"
                onClick={handleConfirm}
                disabled={!bank}>
                {t("ready")}
            </Base.Button>
        </Base.ButtonMessage>
    );
};
