import "./styles.scss";

export const IframeLoader = () => {
    return (
        <div className="loader-wrap">
            <div className="loader">
                <span />
                <span />
                <span />
                <span />
            </div>
        </div>
    );
};
IframeLoader.displayName = "IframeLoader";
