export const HeaderBack = () => (
    <svg
        preserveAspectRatio="none"
        width="100%"
        height="109"
        viewBox="0 0 360 109"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="header__back">
        <g filter="url(#filter0_d_529_11254)">
            <path
                d="M360 0H0V54.6806C57.1203 71.1676 117.447 80 179.815 80C242.308 80 302.763 71.1201 360 54.5414V0Z"
                fill="url(#paint0_linear_529_11254)" />
        </g>
        <defs>
            <filter
                id="filter0_d_529_11254"
                x="-25"
                y="-21"
                width="410"
                height="130"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
                <feFlood
                    floodOpacity="0"
                    result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="12.5" />
                <feComposite
                    in2="hardAlpha"
                    operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.0509804 0 0 0 0 0.584314 0 0 0 0 1 0 0 0 0.15 0" />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_529_11254" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_529_11254"
                    result="shape" />
            </filter>
            <linearGradient
                id="paint0_linear_529_11254"
                x1="179.5"
                y1="80"
                x2="179.5"
                y2="0"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="white" />
                <stop
                    offset="1"
                    stopColor="#E7F6FF" />
            </linearGradient>
        </defs>
    </svg>
);
