import * as Chat from "modules/chat";
import * as Client from "modules/client";
import { useCallback } from "react";
import * as Base from "../base";
import * as State from "../state";

const cid = (): string | undefined => {
    let match = document.cookie.match("(?:^|;)\\s*_ga=([^;]*)");
    const raw = match ? decodeURIComponent(match[1]) : null;
    if (raw) {
        match = raw.match(/(\d+\.\d+)$/);
    }
    return match ? match[1] : undefined;
};

export const ConfirmSubmitMessage: State.Message<State.MessageProps<Chat.Agreement.AgreementConfirmSubmitMessage>> =
    ({ value }) => {

        const handleRequest = useCallback((api: Client.Api.Instance, code: string) => {
            return api.user.agreement.sign(code, cid());
        }, []);
        const handleResponse = useCallback(async (response: Promise<string | undefined>) => {
            const failure = await response;
            if (failure && (failure.toString() !== "true")) {
                return new Chat.ReplaceAction([
                    new Chat.TextMessage(failure || "Error signing contract"),
                    new Chat.Agreement.AgreementConfirmFormMessage(),
                ], value);
            }
            return new Chat.FlushAction([
                new Chat.Agreement.AgreementCompleteMessage(),
            ]);
        }, [value]);
        return (<Base.DelaySubmitMessage
            value={value.code}
            onRequest={handleRequest}
            onFinish={handleResponse}/>);
    };
