import * as React from "react";

export const RandomBlockquote: React.FC<React.PropsWithChildren> = () => {
    const [state, setState] = React.useState<[string, string] | undefined>(undefined);
    React.useEffect(() => {
        import("./blockquote.json").then(({ default: { l } }: { default: any; }) => setState(l));
    }, [setState]);
    if (!state) {
        return null;
    }
    const [cite, q] = state[Math.floor(state.length * Math.random())];
    return (
        <div
            className="failure-blockquote"
            style={{ textAlign: "right" }}>
            <blockquote>{q}</blockquote>
            <cite>{cite}</cite>
        </div>
    );
};
