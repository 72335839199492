import * as Client from "modules/client";
import { sendConversion } from "modules/utils/send-conversion";
import * as React from "react";
import * as Base from "../base";

export const CompleteMessage: React.ComponentType<React.PropsWithChildren<{ value: any; }>> =
    Client.withState<{ value: any; }, { creditNumber: number; requestId: number; }>(
        ({ creditNumber, requestId }) => {
            const dispatch = Client.useDispatchContext();

            React.useEffect(() => {
                sendConversion("credit", creditNumber, requestId);
                const id = setTimeout(() => {
                    dispatch(new Client.Auth.RefreshAction());
                }, 5000);
                return () => clearTimeout(id);
            }, [dispatch, creditNumber]);

            return (
                <Base.WrapMessage source="bot">
                    <p>Ready! While the money is being transferred to your card, I will show your personal account</p>
                </Base.WrapMessage>
            );
        },
        state =>
            state.type === "user" && {
                creditNumber: state.user.stats.outpayment.count,
                requestId: state.request.id,
            },
    );
