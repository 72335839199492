import * as BtnPdf from "modules/components/btn-pdf";
import { ButtonDownload } from "modules/components/button-download";
import { useDownloadState } from "modules/hooks/use-download-state";
import React from "react";

type LoadPdfProps = {
    className?: string;
    loadFile: () => Promise<string> | undefined;
    label: {
        default: string;
        downloaded: string;
    };
};

export const LoadPdf: React.FC<React.PropsWithChildren<LoadPdfProps>> = ({ className, loadFile, label }) => {
    const [url, setUrl] = useDownloadState();

    React.useEffect(() => {
        if (url !== "loading") {
            return;
        }

        loadFile()
            ?.then((newUrl) => {
                setUrl(newUrl);
            })
            .catch((err) => {
                setUrl("failed");
                return Promise.reject(err);
            });
    }, [setUrl, url]);

    switch (url) {
        case undefined:
        case "loading":
        case "failed":
            return (<ButtonDownload
                url={url}
                setUrl={setUrl}
                id="btn-agreement_download"
                className={className}
                name={label.default} />);
        default:
            return (<BtnPdf.Layout
                title={label.downloaded}
                link={url}
                id="btn-agreement_open"
                className={className} />);
    }
};
