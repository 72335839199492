import { Capacitor } from "@capacitor/core";
import { Contacts } from "@capacitor-community/contacts";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import * as Chat from "modules/chat";
import { ContactsStateType } from "modules/client/api/contacts-api";
import { IconArrow } from "modules/components/Icons/IconArrow";
import * as React from "react";
import * as Base from "../base";
import * as State from "../state";
import { ContactPayload, GetContactsResult, PhoneType } from "./mock.type";

export const MOCK_CONTACTS: ContactPayload[] = [
    {
        contactId: "1",
        name: {
            display: "Mama",
            given: "Mama",
            middle: null,
            family: null,
            prefix: null,
            suffix: null,
        },
        phones: [
            {
                type: PhoneType.Mobile,
                isPrimary: false,
                // eslint-disable-next-line id-denylist
                number: "+380660011222",
            },
        ],
    },
    {
        contactId: "2",
        name: {
            display: "Papa",
            given: "Papa",
            middle: null,
            family: null,
            prefix: null,
            suffix: null,
        },
        phones: [
            {
                type: PhoneType.Mobile,
                isPrimary: false,
                // eslint-disable-next-line id-denylist
                number: "+380660022333",
            },
        ],
    },
];

type SelectCardMessageProps =
    {} & State.MessageProps<Chat.ShareContacts.ShareContactsMessage>;

const getContacts: () => Promise<ContactPayload[]> = (): Promise<
    ContactPayload[]
> => {
    if (Capacitor.isNativePlatform()) {
        return Contacts.getContacts({
            projection: {
                name: true,
                organization: true,
                birthday: true,
                note: true,
                phones: true,
                emails: true,
                urls: true,
                postalAddresses: true,
                image: false,
            },
        }).then((result: GetContactsResult) => result.contacts);
    }

    return new Promise(resolve => resolve(MOCK_CONTACTS));
};

export const ShareContactsMessage: React.FC<React.PropsWithChildren<SelectCardMessageProps>> = ({
    value: { state },
}) => {
    const dispatch: Chat.Dispatch = Chat.useDispatchContext();

    const handleDisagree = React.useCallback((): void => {
        if (Capacitor.isNativePlatform()) {
            FirebaseAnalytics.logEvent({ name: "contacts-rejected" });
        }
        dispatch([
            new Chat.FlushAction([
                new Chat.ShareContacts.ShareContactsLoadMessage([]),
            ]),
        ]);
    }, []);

    const handleAgree = React.useCallback(async (): Promise<void> => {
        if (Capacitor.isNativePlatform()) {
            FirebaseAnalytics.logEvent({ name: "contacts-allowed" });
        }
        getContacts()
            .then((val: ContactPayload[]): void => {
                if (Capacitor.isNativePlatform()) {
                    FirebaseAnalytics.logEvent({ name: "contacts-received-success" });
                }
                dispatch([
                    new Chat.FlushAction([
                        new Chat.ShareContacts.ShareContactsLoadMessage(val),
                    ]),
                ]);
            })
            .catch(async (error) => {
                if (Capacitor.isNativePlatform()) {
                    FirebaseAnalytics.logEvent({ name: "contacts-received-error", params: { message: error } });
                }

                if (state.state === ContactsStateType.required) {
                    dispatch([
                        new Chat.FlushAction([
                            new Chat.ShareContacts.ShareContactsDisabled(),
                        ]),
                    ]);
                }

                dispatch([
                    new Chat.FlushAction([
                        new Chat.ShareContacts.ShareContactsLoadMessage([]),
                    ]),
                ]);
            });
    }, []);

    return (
        <React.Suspense fallback={<Base.LoadMessage />}>
            <div className="status-wrap">
                <div className="status">
                    <h1>Share your contacts</h1>
                    <div className="alert">
                        <div className="alert__heading">
                            <h5>Information</h5>
                        </div>
                        <p>
                            The installed application collects information
                            stored on your mobile device about your contact list
                            for security, compliance and fraud prevention
                            purposes.
                        </p>
                        <p>
                            Your data will be redirected/uploaded to our server{" "}
                            <span>(https://api.kobogo.ng/)</span>.
                        </p>
                        <p>
                            <span>
                                Get more discounts and increase your available
                                credit!
                            </span>
                        </p>
                    </div>
                    <div className="btn-container">
                        <p>
                            Access to your phone book is necessary for security
                            and fraud prevention.
                        </p>
                        <button
                            type="button"
                            className="btn btn_white"
                            onClick={handleDisagree}
                        >
                            <span>No, I don't agree to share</span>
                            <IconArrow />
                        </button>
                        <button
                            type="button"
                            className="btn btn_blue"
                            onClick={handleAgree}
                        >
                            <span>Yes, I agree to share</span>
                            <IconArrow />
                        </button>
                    </div>
                </div>
            </div>
        </React.Suspense>
    );
};
