import { LocalizedRoute } from "app/locale";
import * as React from "react";
import { Page } from "./page";

export const Route: () => React.ReactElement = () => {
    return (<LocalizedRoute
        key="payment-passed"
        path="/dashboard/payment/passed"
        component={Page} />);
};
