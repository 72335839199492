/* eslint-disable no-return-await */
import { AxiosError, AxiosInstance, AxiosResponse } from "axios";

export const Api = (endpoint: AxiosInstance) => {
    return {
        provider: async (value: string): Promise<string> => {
            return endpoint
                .get<{ code: string; }>("/v3/mono/telco", {
                    params: {
                        provider: value,
                    },
                })
                .then((data: AxiosResponse<{ code: string; }>) => {
                    return data.data.code;
                });
        },
        verify: async (sessionId: string, otpToken: string): Promise<void | string> => {
            return await endpoint
                .post("/v3/mono/telco", {
                    Form: {
                        sessionId,
                        otpToken,
                    },
                })
                .then(() => Promise.resolve())
                .catch((error: AxiosError | Error) => {
                    if (!("response" in error)) {
                        return Promise.reject(error);
                    }
                    if (error.response?.status !== 400
                        || !Array.isArray(error.response.data.errors)
                        || error.response.data.errors.length !== 1
                    ) {
                        return Promise.reject(error);
                    }
                    return error.response.data.errors[0].details;
                });
        },
    } as const;
};
