import * as Client from "../client";

export type Promocode =
    | {
        value: string;
        isValid?: true;
        discount: number;
    }
    | {
        value: string;
        isValid?: false;
    };

export class PromoPageWelcome {
    constructor(public promoShortUrl: string) {}

    public readonly type = "promo-page:welcome";
}

export class IntroMessage {
    constructor(
        public welcomeRu?: string,
        public welcomeUa?: string,
    ) {}

    public readonly type = "promo-page:intro";
}

export class LoadingMessage {
    constructor(
        public promo?: {
            code: string;
            discount: number;
        },
    ) {}

    public readonly type = "promo-page:loading";
}

export class FormMessage {
    public readonly type = "promo-page:form";

    constructor(
        public readonly conditions: Client.Api.Conditions<Required<Client.Api.ConditionsItem>>,
        public promo?: {
            code: string;
            discount: number;
        },
        public readonly sent?: true,
    ) {}
}

export class SetPromocodeMessage {
    constructor(
        public promo?: {
            code: string;
            discount: number;
        },
    ) {}

    public readonly type = "promo-page:set-promocode";
}

export type Message = PromoPageWelcome | IntroMessage | LoadingMessage | FormMessage | SetPromocodeMessage;
