export const Signout = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon_signout"
    >
        <mask
            id="mask0_173_23439"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <rect
                width="24"
                height="24"
                fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_173_23439)">
            <path
                d="M15.6251 15.975C15.4751 15.8083 15.4001 15.625 15.4001 15.425C15.4001 15.225 15.4751 15.05 15.6251 14.9L17.5251 13H9.8751C9.65843 13 9.47943 12.929 9.3381 12.787C9.1961 12.6457 9.1251 12.4667 9.1251 12.25C9.1251 12.0333 9.1961 11.854 9.3381 11.712C9.47943 11.5707 9.65843 11.5 9.8751 11.5H17.5251L15.6251 9.575C15.4751 9.425 15.4001 9.25 15.4001 9.05C15.4001 8.85 15.4751 8.675 15.6251 8.525C15.7751 8.375 15.9501 8.3 16.1501 8.3C16.3501 8.3 16.5168 8.36667 16.6501 8.5L19.7751 11.625C19.8584 11.7083 19.9211 11.804 19.9631 11.912C20.0044 12.0207 20.0251 12.1333 20.0251 12.25C20.0251 12.3667 20.0044 12.479 19.9631 12.587C19.9211 12.6957 19.8584 12.7917 19.7751 12.875L16.6501 16C16.5001 16.1667 16.3294 16.2417 16.1381 16.225C15.9461 16.2083 15.7751 16.125 15.6251 15.975ZM5.4001 21C4.9001 21 4.4751 20.825 4.1251 20.475C3.7751 20.125 3.6001 19.7 3.6001 19.2V5.3C3.6001 4.8 3.7751 4.375 4.1251 4.025C4.4751 3.675 4.9001 3.5 5.4001 3.5H11.3751C11.5751 3.5 11.7501 3.57067 11.9001 3.712C12.0501 3.854 12.1251 4.03333 12.1251 4.25C12.1251 4.46667 12.0501 4.64567 11.9001 4.787C11.7501 4.929 11.5751 5 11.3751 5H5.4001C5.33343 5 5.26676 5.03333 5.2001 5.1C5.13343 5.16667 5.1001 5.23333 5.1001 5.3V19.2C5.1001 19.2667 5.13343 19.3333 5.2001 19.4C5.26676 19.4667 5.33343 19.5 5.4001 19.5H11.3751C11.5751 19.5 11.7501 19.5707 11.9001 19.712C12.0501 19.854 12.1251 20.0333 12.1251 20.25C12.1251 20.4667 12.0501 20.646 11.9001 20.788C11.7501 20.9293 11.5751 21 11.3751 21H5.4001Z"
                fill="white"
                className="icon-fill"
            />
        </g>
    </svg>
);
