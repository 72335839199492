import * as React from "react";
import { useLocalStorageState } from "../../hooks/use-local-storage-state";
import { reducer, CapacitorStoreAction } from "./reducer";
import { initialState, StateContext } from "./state";

export const DispatchContext = React.createContext<React.Dispatch<CapacitorStoreAction>>(() => undefined);
DispatchContext.displayName = "CapacitorApp.DispatchContext";

export function useCapacitorStoreDispatchContext() {
    return React.useContext(DispatchContext);
}

export const Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [storedState, setStoredState] = useLocalStorageState("capacitor:state", initialState);
    const [state, dispatch] = React.useReducer(reducer, storedState);

    React.useEffect(() => {
        setStoredState(state);
    }, [state, setStoredState]);

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    );
};
Provider.displayName = "CapacitorApp.Provider";
