import * as Base from "app/chat/base";
import { useLocale } from "app/locale";
import * as Chat from "modules/chat";
import React from "react";

export const ConfirmRequestMessage = () => {
    const dispatch = Chat.useDispatchContext();
    const { t } = useLocale("registration");

    const handleCanceled = React.useCallback(() => {
        dispatch(
            new Chat.ReplaceAction([
                new Chat.TextMessage(t("credit-question"), "user"),
                new Chat.TextMessage(t("restructuring-question-sorry"), "bot"),
                new Chat.Restructuring.RestructuringRefuseFormMessage(),
            ]),
        );
    }, []);

    const handleConfirm = React.useCallback(() => {
        dispatch(new Chat.ReplaceAction([new Chat.Restructuring.RestructuringSendMessage()]));
    }, []);

    return (
        <Base.ButtonMessage source={"user"}>
            <Base.Button
                theme="primary"
                onClick={handleCanceled}>
                {t("credit-question")}
            </Base.Button>
            <Base.Button
                id="confirm-agreement"
                theme="secondary"
                className="long"
                onClick={handleConfirm}>
                {t("sign-a-contract")}
            </Base.Button>
        </Base.ButtonMessage>
    );
};
