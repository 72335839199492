import * as Avatar from "app/dashboard/avatar";
import { useLocale } from "app/locale";
import * as Client from "modules/client";
import IconClient from "./icon-client.svg";
import { Name } from "./Name";

import IconNewbie from "./newbie-icon.svg";

export type UserNameProps = {
    title: string;
    text: string;
    image: string;
};

export const UserName = Client.withState<{}, UserNameProps>(
    ({ title, text, image }) => {
        const { t } = useLocale("personal-data");
        return (
            <>
                <div className="user-name">
                    <Name />
                    <span>{t(title)}</span>
                    <p>{t(text)}</p>
                </div>
                <img
                    src={image}
                    alt="Level image"
                    width="157"
                    height="174" />
            </>
        );
    },
    (state) => {
        if (state.type !== "user") {
            return false;
        }
        if (state.request.status === "Closed" || (state.request.status !== "Reject" && state.request.number >= 2)) {
            return {
                title: "client",
                text: "client-text",
                image: IconClient,
            };
        }
        return {
            title: "newbie",
            text: "newbie-text",
            image: IconNewbie,
        };
    },
);

export const Layout = () => {
    return (
        <div className="user-info">
            <Avatar.Layout />
            <UserName />
        </div>
    );
};

Layout.displayName = "UserInfo.Layout";
