import classNames from "classnames";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import { formatDate } from "modules/utils/formatDate";
import * as React from "react";
import { Control } from "./control";
import { ControlMessage } from "./control-message";
import { LabelMessage } from "./label-message";

export interface MessageProps<TValue = any> {
    onSubmit: (attribute: string, value: TValue) => Chat.Action;
    attribute: Client.Api.DataAttributeRecord<any>;
    label: string;
}

function getButtonEnabled(
    schema: Client.Api.DataAttributeSchema,
    value: string,
): boolean {
    if (schema.minLength) {
        return value.length >= schema.minLength;
    }

    switch (schema.format) {
        case "date":
        case "inn":
            return value.length === 10;
        default:
            return value.length > 0;
    }
}

export const Message: React.FC<React.PropsWithChildren<MessageProps>> = ({
    onSubmit,
    attribute,
    label,
}) => {
    const dispatch = Chat.useDispatchContext();
    const [value, setValue] = React.useState<string>(
        attribute.value || attribute.schema.default || "",
    );
    const isEnum = "oneOf" in attribute.schema;
    const isButtonEnabled = getButtonEnabled(attribute.schema, value);
    const handleSubmit = React.useCallback(
        (e: React.FormEvent | Event) => {
            e.preventDefault();

            if (!isButtonEnabled) {
                return;
            }

            const response = isEnum
                ? attribute.schema.oneOf.find(
                    ({ const: v }: any) => v === value,
                ).title
                : value;
            const serverValue =
                attribute.schema.format === "date" ? formatDate(value) : value;
            dispatch([
                new Chat.ReplaceAction([
                    new Chat.TextMessage(label, "bot"),
                    new Chat.TextMessageEditable(response, attribute),
                ]),
                onSubmit(attribute.name, serverValue),
            ]);
        },
        [attribute, value, dispatch, onSubmit],
    );

    React.useEffect(() => {
        if (!attribute.value) {
            return;
        }

        setValue(attribute.value);
    }, [attribute.value, setValue]);

    React.useEffect(() => {
        if (
            !isEnum
            || !attribute.schema.oneOf.some(({ const: v }: any) => v === value)
            || attribute.schema.oneOf.length === 2
        ) {
            return;
        }

        handleSubmit(new Event("change"));
    }, [isEnum, value]);

    const id = `data-${attribute.name}`;

    return (
        <form onSubmit={handleSubmit}>
            <LabelMessage
                id={id}
                message={label}/>
            <ControlMessage>
                <Control
                    id={id}
                    attribute={attribute.name}
                    schema={attribute.schema}
                    value={value}
                    onChange={setValue}
                />
                {!isEnum && (
                    <div className="btn-container v-align">
                        <button
                            id={id ? `${id}-submit` : undefined}
                            className={classNames(
                                "btn btn_action",
                                isButtonEnabled ? "enabled" : "disabled",
                            )}
                            type="submit"
                        >
                            <i className="icon icon_arrow" />
                        </button>
                    </div>
                )}
            </ControlMessage>
        </form>
    );
};
