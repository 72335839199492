import * as React from "react";

import "./index.scss";

export const TypingSpinner: React.FC<React.PropsWithChildren> = React.memo(() => (
    <div className="typing-spinner">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
    </div>
));
TypingSpinner.displayName = "TypingSpinner";
